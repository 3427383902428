import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import axios from 'axios';
import { JOBS_PUBLIC_ENDPOINT } from 'src/constants';
import { getAuthenticatedUser, getTokenFromAuthenticatedUser } from 'src/features/login';
import { isPandoraErrorResponse } from 'src/types/pandora';
import { getMandatoryEnvVar } from 'src/utils/env';
import { ajvErrorFormatter } from 'src/utils/types';
import { captureSentryError } from 'src/features/login/utils/captureSentryError';
import { getInvoicesResponseSchema, Invoice } from './getInvoices.types';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

export const getInvoices = async (): Promise<Invoice[] | Error> => {
    const user = await getAuthenticatedUser();
    const token = getTokenFromAuthenticatedUser(user);

    try {
        const { data } = await axios({
            url: `${API_BASE_URL}/${JOBS_PUBLIC_ENDPOINT}/v1/engineer/invoices`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });

        const ajv = new Ajv();
        addFormats(ajv); // add formats for date-time format
        const validate = ajv.compile(getInvoicesResponseSchema);

        const valid = validate(data);
        if (valid) {
            if (isPandoraErrorResponse(data)) {
                return new Error(`Error message from api: ${data.message}`);
            }
            return data.invoices;
        }
        return new Error(
            `Failed to validate getInvoices response, ${ajvErrorFormatter(validate.errors)}`
        );
    } catch (error: any) {
        const err = new Error('Unable to get invoices');
        captureSentryError(err, {
            error,
        });
        return err;
    }
};
