import { useMemo, useState } from 'react';
import { JobDetails } from 'src/types/Job';
import Dropdown, { Option as DropdownOption } from 'src/components/Dropdown';
import { calculateActualHoursLimit } from 'src/utils/jobs';
import Button from 'src/components/Button';
import style from './index.module.scss';
import { Option } from '../../../types/Question';
import { FormState } from '../../../types/Form';

export interface Props {
    title: string;
    options: Option[];
    formState: Partial<FormState>;
    jobDetails: JobDetails;
    onSubmit: (value: number, nextQuestion?: string) => void;
}

const TIME_INCREMENT_IN_HOURS = 0.5;
const placeholder: DropdownOption = { copy: 'Select time', value: 0 };

const generateHourAsText = (numberofHours: number) =>
    `${numberofHours} Hour${numberofHours === 1 ? '' : 's'}`;

const calculateDropdownOptions = (authorisedHours: number) => {
    const numberOfIncrements = authorisedHours / TIME_INCREMENT_IN_HOURS;

    const dropdownOptions: DropdownOption[] = [placeholder];

    for (let index = 1; index <= numberOfIncrements; index += 1) {
        const timeIncrement = index * TIME_INCREMENT_IN_HOURS;

        dropdownOptions.push({
            copy: generateHourAsText(timeIncrement),
            value: timeIncrement,
        });
    }

    return dropdownOptions;
};

const ActualHoursQuestion = ({
    title,
    options,
    formState,
    jobDetails: { sk_estimated_additional_hours_authorised },
    onSubmit,
}: Props) => {
    const [selectedValue, setSelectedValue] = useState(+placeholder.value);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const durationLimit = useMemo(
        () =>
            calculateActualHoursLimit(
                sk_estimated_additional_hours_authorised,
                formState.estimatedHoursNeeded,
                formState.partsSource === 'canFitPartsNow'
            ),
        [
            formState.estimatedHoursNeeded,
            formState.partsSource,
            sk_estimated_additional_hours_authorised,
        ]
    );

    const isValidForm = () => {
        const newErrors: { [key: string]: string } = {};

        if (selectedValue === placeholder.value) {
            newErrors.actualHours = 'Please select a valid option.';
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const validationError = (
        <div className={style.validationError} role="alert">
            {Object.entries(errors).map(([key, error]) => (
                // eslint-disable-next-line react/no-danger
                <p key={`each-error-${key}`} dangerouslySetInnerHTML={{ __html: error }} />
            ))}
        </div>
    );

    return (
        <>
            <span className={style.title}>{title}</span>
            <Dropdown
                currentValue={selectedValue}
                options={calculateDropdownOptions(durationLimit)}
                onChange={(e) => setSelectedValue(+e.target.value)}
                customStyle={style.dropdown}
            />

            <div className={style.confirmationText}>This will be reflected in your invoice</div>

            {errors && validationError}

            {options.map(({ value, nextQuestion, nextQuestionSelector }) => {
                return (
                    <Button
                        customStyle={style.button}
                        key={value}
                        type="button"
                        onClick={() => {
                            const hasErrors = !isValidForm();

                            if (!hasErrors) {
                                onSubmit(
                                    selectedValue,
                                    nextQuestionSelector
                                        ? nextQuestionSelector(selectedValue, formState)
                                        : nextQuestion
                                );
                            }
                        }}
                    >
                        {value}
                    </Button>
                );
            })}
        </>
    );
};

export default ActualHoursQuestion;
