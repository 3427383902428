import { useState } from 'react';
import { FormState } from 'src/features/closeJob/types/Form';
import Button from 'src/components/Button';
import { Config, Option } from '../../../types/Question';
import style from './index.module.scss';

export interface Props {
    title: string;
    options: Option[];
    onSubmit: (value: string, nextQuestion?: string) => void;
    formState: Partial<FormState>;
    config: Config;
}

const RadioQuestion = ({ title, options, onSubmit, formState, config }: Props) => {
    const radioOptions = config?.options || [];
    const [selectedOption, setSelectedOption] = useState<string>('');
    const [errors, setErrors] = useState(false);
    const isValidForm = () => !!selectedOption;

    const onChange = (e) => {
        const currentValue = e.target.value;

        if (currentValue) {
            setErrors(false);
        }

        setSelectedOption(currentValue);
    };

    const validationError = (
        <div className={style.validationError} role="alert">
            Field is required
        </div>
    );

    return (
        <>
            <span className={style.title}>{title}</span>
            <div className={style.inputAndError}>
                <div className={style.radioContainer}>
                    {radioOptions.map((option, index) => (
                        <div className={style.radioOption} key={option.value}>
                            <input
                                type="radio"
                                name="radioq"
                                className={`${style.textAreaSingle}`}
                                onChange={onChange}
                                id={`option-${index}`}
                                value={option.value}
                                // hidden
                                checked={selectedOption.includes(option.value)}
                            />
                            <label htmlFor={`option-${index}`}>
                                <span>{option.copy || option.value}</span>
                            </label>
                        </div>
                    ))}
                </div>
                {errors ? validationError : <></>}
            </div>

            {options.map(({ value, nextQuestion, nextQuestionSelector }) => {
                return (
                    <Button
                        customStyle={style.button}
                        key={value}
                        type="button"
                        onClick={() => {
                            const hasErrors = !isValidForm();

                            if (hasErrors) {
                                setErrors(true);
                            } else {
                                onSubmit(
                                    selectedOption,
                                    nextQuestionSelector
                                        ? nextQuestionSelector(selectedOption, formState)
                                        : nextQuestion
                                );
                            }
                        }}
                    >
                        {value}
                    </Button>
                );
            })}
        </>
    );
};

export default RadioQuestion;
