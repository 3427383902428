import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import Layout from 'src/components/Layout';
import { mixpanelTrackPage } from 'src/utils/mixpanel';
import Button from 'src/components/Button';
import AgendaDay from '../AgendaDay';
import AgendaPrevious from '../AgendaPrevious';
import AgendaUpcoming from '../AgendaUpcoming';
import { newVirtualPageView } from '../../../../utils/analytics';
import style from './index.module.scss';

const JobAgendas = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const tabSearchParam = searchParams && searchParams.get('tab');
    const [agenda, setAgenda] = useState(tabSearchParam || 'todayAgenda');
    const location = useLocation();
    const pageCount = 0;

    const onTabChange = (tab: string) => {
        setSearchParams({ tab });
        setAgenda(tab);
    };

    useEffect(() => {
        newVirtualPageView(tabSearchParam, location.pathname);
        mixpanelTrackPage();
    }, [pageCount, tabSearchParam, location]);

    return (
        <Layout>
            <div className={style.jobAgendas}>
                <nav className={style.navContainer}>
                    <Button
                        customStyle={`${style.agendaButton} ${
                            agenda === 'previousAgenda' ? style.selected : style.notSelected
                        }`}
                        type="button"
                        onClick={() => onTabChange('previousAgenda')}
                    >
                        Previous
                    </Button>
                    <Button
                        customStyle={`${style.agendaButton} ${
                            agenda === 'todayAgenda' ? style.selected : style.notSelected
                        }`}
                        type="button"
                        onClick={() => onTabChange('todayAgenda')}
                    >
                        Today
                    </Button>
                    <Button
                        customStyle={`${style.agendaButton} ${
                            agenda === 'upcomingAgenda' ? style.selected : style.notSelected
                        }`}
                        type="button"
                        onClick={() => onTabChange('upcomingAgenda')}
                    >
                        Upcoming
                    </Button>
                </nav>

                {agenda === 'previousAgenda' && <AgendaPrevious />}
                {agenda === 'todayAgenda' && <AgendaDay />}
                {agenda === 'upcomingAgenda' && <AgendaUpcoming />}
            </div>
        </Layout>
    );
};

export default JobAgendas;
