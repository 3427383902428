import { useInfiniteQuery } from 'react-query';
import { getJobAgenda } from '../api/getJobAgenda';
import { JobAgendaSuccessResponse } from '../api/getJobAgenda.types';

export const useInfiniteGetJobAgenda = (
    queryKey: string,
    from: string,
    limit: number,
    to?: string,
    status?: string
) =>
    useInfiniteQuery<JobAgendaSuccessResponse, Error>(
        [queryKey],
        ({ pageParam = 0 }) => getJobAgenda(from, to, status, limit, pageParam),
        {
            getNextPageParam: (lastPage) => lastPage.nextCursor,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        }
    );
