export const addInitialZero = (number) => {
    if (number < 10) {
        return `0${number}`;
    }

    return `${number}`;
};

export const dateToValue = (date) => {
    const dateDay = addInitialZero(date.getDate());
    const dateMonth = addInitialZero(date.getMonth() + 1);
    const dateYear = date.getFullYear();

    return `${dateYear}-${dateMonth}-${dateDay}`;
};
