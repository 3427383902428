import { ACCOUNT_ENDPOINT } from 'src/constants';
import { getMandatoryEnvVar } from 'src/utils/env';
import { post } from 'src/utils/http';

export type NoEmailSignUp = {
    code: string;
    postcode: string;
    email: string;
    surname: string;
};

export type NoEmailSignUpResponse = {
    success: boolean;
};

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

export const noEmailSignUp = async (formData: NoEmailSignUp): Promise<NoEmailSignUpResponse> => {
    const url = `${API_BASE_URL}/${ACCOUNT_ENDPOINT}/authentication-code/sign-up`;

    const response = await post<NoEmailSignUp, NoEmailSignUpResponse>({
        url,
        body: formData,
    });

    return response;
};
