/* eslint-disable react/prop-types */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CalendarDate from 'src/components/Calendar/DateRadio';
import { JOB_URGENCY } from 'src/constants';
import { Tick } from '../images/index';
import { Routes } from '../types/Routes';
import { newAnalyticsEvent, newVirtualPageView } from '../utils/analytics';
import { getDate, getWeekDay } from '../utils/dates';
import { getUserModeId } from '../utils/userMode';
import Button from './Button';
import ConfirmationTime from './ConfirmationTime';
import Layout from './Layout';

interface Props {
    value: any;
}

const determineConfirmationText = (value, jobUrgency) => {
    const urgentText =
        'Our team is reviewing your request and assigning an engineer. We’ll send you an email and text message as soon as your appointment is confirmed.';
    const normalText =
        'Our team is reviewing your request and will confirm your appointment in the next 48 hours by email.';
    const smartCoverText = (
        <>
            <p>
                We have successfully passed your claim to the insurer and are waiting for a
                decision. You will receive an email with the insurer’s decision very soon.
                <br />
                <br />
                In the unlikely event it takes more than 60 hours to restore safety in your property
                after our Engineer’s first visit and diagnosis, you may qualify for alternative
                accommodation or a heating allowance.
                <br />
                For more details on the eligibility and how to claim, click&nbsp;
                <a
                    href="https://www.hometree.co.uk/home-emergency-faqs/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    here
                </a>
            </p>
        </>
    );

    if (value.packageDetails?.requiresInsurerApproval === true) {
        return smartCoverText;
    }

    if ([JOB_URGENCY.URGENT, JOB_URGENCY.CRITICAL].includes(jobUrgency)) {
        return urgentText;
    }

    return normalText;
};

const Confirmation = ({ value }: Props) => {
    const navigate = useNavigate();

    const { skeduloJobDetails, paramUserMode } = value;

    useEffect(() => {
        if (!skeduloJobDetails) {
            navigate(Routes.AUTH);
            return;
        }
        newVirtualPageView('Job confirmation', '/vpv/jobconfirmation');
        newAnalyticsEvent({
            category: 'Job confirmation',
            action: 'Landed',
            label: skeduloJobDetails.jobs?.edges[0]?.node?.Name || 'Job not found',
            value: getUserModeId(paramUserMode),
        });
    }, [navigate, paramUserMode, skeduloJobDetails]);

    if (!skeduloJobDetails) {
        return null;
    }

    const { dayOneDate, dayOneTime, dayTwoDate, dayTwoTime, dayThreeDate, dayThreeTime } = value;

    const openSurvey = () => {
        newAnalyticsEvent({
            category: 'Job confirmation',
            action: 'Clicked on survey',
            label: skeduloJobDetails.jobs?.edges[0]?.node?.Name || 'Job not found',
            value: getUserModeId(paramUserMode),
        });
        window.open(
            'https://docs.google.com/forms/d/e/1FAIpQLSekUzlp8NCwkUxZIjo-w3nA-ehAOQq12PA0oyw31rymR7S_Gw/viewform?usp=sf_link',
            '_blank'
        );
    };

    let refNumber;
    try {
        refNumber = skeduloJobDetails.jobs?.edges[0]?.node?.Name;
    } catch (error) {
        refNumber = '';
    }

    const header = 'Request received';
    const refNumberLine = `Your reference number: ${refNumber}`;
    const surveyText = 'We’d love to get your feedback on how we can improve the booking tool';
    const surveyButtonText = 'Super quick survey';

    const datesArray = [new Date(dayOneDate), new Date(dayTwoDate), new Date(dayThreeDate)];

    const timesArray = [dayOneTime, dayTwoTime, dayThreeTime];

    const jobUrgency = value.jobUrgency || value.urgency;

    return (
        <Layout>
            <div className="container confirmation">
                <div className="confirmation-header">
                    <Tick />
                    <h2>{header}</h2>
                    {refNumber ? (
                        <p>
                            <strong>{refNumberLine}</strong>
                        </p>
                    ) : (
                        ''
                    )}
                    <p>{determineConfirmationText(value, jobUrgency)}</p>
                </div>
                <ul className="confirmation-list">
                    {datesArray.map((date, index) => {
                        const elKey = `${date}-${timesArray[index][0]}`;
                        return (
                            <li key={elKey} className="confirmation-list-el">
                                <h4 className="confirmation-list-el-date">{getDate(date)}</h4>
                                <CalendarDate
                                    dateWeek={getWeekDay(date)}
                                    dateNum={date.getDate()}
                                    isChecked
                                    readOnly
                                />
                                <div className="confirmation-list-el-time">
                                    <section>
                                        <ConfirmationTime time={timesArray[index]} />
                                    </section>
                                </div>
                            </li>
                        );
                    })}
                </ul>
                <div className="confirmation-survey">
                    <p>{surveyText}</p>
                    <Button onClick={openSurvey}>{surveyButtonText}</Button>
                </div>
            </div>
        </Layout>
    );
};

export default Confirmation;
