import { decodeString } from './decodeString';

export const getNavigationUrl = (address_line_1: string, postcode: string) => {
    const ua = navigator.userAgent;
    const isSafari = /Safari/.test(ua) && !/Chrome/.test(ua);
    const navAddress = isSafari
        ? `https://maps.apple.com/?q=${decodeString(`${address_line_1},${postcode}`)
              .replaceAll('&', '')
              .replaceAll(' ', '+')}`
        : `geo:?q=${decodeString(`${address_line_1} ${postcode}`).replaceAll('&', '')}`;

    return navAddress;
};
