import { useState } from 'react';
import Input from 'src/components/Input';
import Dropdown, { Option as DropdownOption } from 'src/components/Dropdown';
import Button from 'src/components/Button';
import { Option } from '../../../types/Question';
import { FormState, EstimatedCostOfPartsValue } from '../../../types/Form';
import style from './index.module.scss';

export interface Props {
    title: string;
    options: Option[];
    onSubmit: (value: EstimatedCostOfPartsValue, nextQuestion?: string) => void;
    formState: Partial<FormState>;
    defaultValue?: EstimatedCostOfPartsValue;
}

const placeholder: DropdownOption = { copy: 'Select time', value: 0 };

const EstimatedCostOfPartsQuestion = ({
    title,
    options,
    onSubmit,
    formState,
    defaultValue,
}: Props) => {
    const [estimatedCostOfParts, setEstimatedCostOfParts] = useState<EstimatedCostOfPartsValue>({
        partsDescription: defaultValue?.partsDescription || '',
        estimatedPartsCost: defaultValue?.estimatedPartsCost || 0,
        estimatedHoursNeeded: defaultValue?.estimatedHoursNeeded || 0,
    });
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const isValidForm = () => {
        const newErrors: { [key: string]: string } = {};

        if (!estimatedCostOfParts.partsDescription) {
            newErrors.partsDescription = 'Parts needed is a required field.';
        }
        if (
            estimatedCostOfParts.partsDescription &&
            estimatedCostOfParts.partsDescription.length > 255
        ) {
            newErrors.partsDescription = 'Parts needed must be less than 255 characters.';
        }

        if (
            !estimatedCostOfParts.estimatedPartsCost ||
            estimatedCostOfParts.estimatedPartsCost === 0
        ) {
            newErrors.estimatedPartsCost = 'Parts cost is a required field.';
        }

        if (
            estimatedCostOfParts.estimatedPartsCost &&
            estimatedCostOfParts.estimatedPartsCost.toString().length > 255
        ) {
            newErrors.estimatedPartsCost = 'Parts cost must be less than 255 characters.';
        }

        if (Number.isNaN(Number(estimatedCostOfParts.estimatedPartsCost))) {
            newErrors.estimatedPartsCost = 'Parts cost must be a number.';
        }

        if (estimatedCostOfParts.estimatedHoursNeeded === placeholder.value) {
            newErrors.estimatedHoursNeeded = 'Time needed is a required field.';
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const onChange = (key) => (e) => {
        const currentValue = e.target.value;
        const currentFieldName = e.target.name;
        if (currentValue) {
            setErrors((prev) => {
                const newErrors = { ...prev };
                delete newErrors[currentFieldName];
                return newErrors;
            });
        }

        if (key === 'estimatedPartsCost') {
            setEstimatedCostOfParts({
                ...estimatedCostOfParts,
                [key]: currentValue.substring(1),
            });
        } else if (key === 'estimatedHoursNeeded') {
            setEstimatedCostOfParts({
                ...estimatedCostOfParts,
                [key]: +currentValue,
            });
        } else {
            setEstimatedCostOfParts({ ...estimatedCostOfParts, [key]: currentValue });
        }
    };

    const validationError = (
        <div className={style.validationError} role="alert">
            <ul>
                {Object.entries(errors).map(([key, error]) => (
                    <li key={`each-error-${key}`}>{error}</li>
                ))}
            </ul>
        </div>
    );

    return (
        <>
            <span className={style.title}>{title}</span>
            <div className={style.inputAndError}>
                <div className={`${style.formControl} ${style.partsNeeded}`}>
                    <label>
                        <span className={style.formHeader}>Parts needed</span>
                        <textarea
                            name="partsDescription"
                            value={estimatedCostOfParts.partsDescription || ''}
                            rows={4}
                            cols={20}
                            className={style.textArea}
                            onChange={onChange('partsDescription')}
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            autoFocus
                            aria-label={title}
                        />
                    </label>
                </div>
                <div className={style.authRequest}>
                    <div className={style.formControl}>
                        <label htmlFor="estimatedPartsCostInput">
                            <span>Parts cost</span>
                            <Input
                                inputType="currency"
                                id="estimatedPartsCostInput"
                                name="estimatedPartsCost"
                                value={(estimatedCostOfParts.estimatedPartsCost || '').toString()}
                                onChange={onChange('estimatedPartsCost')}
                            />
                        </label>
                    </div>
                    <div className={style.formControl}>
                        <label htmlFor="estimatedHoursNeededInput">
                            <span>Time needed</span>
                            <Dropdown
                                id="estimatedHoursNeededInput"
                                options={[
                                    placeholder,
                                    { value: 0.5, copy: '0.5 Hour' },
                                    { value: 1, copy: '1 Hour' },
                                    { value: 1.5, copy: '1.5 Hours' },
                                    { value: 2, copy: '2 Hours' },
                                    { value: 2.5, copy: '2.5 Hours' },
                                    { value: 3, copy: '3 Hours' },
                                    { value: 3.5, copy: '3.5 Hours' },
                                    { value: 4, copy: '4 Hours' },
                                    { value: 4.5, copy: '4.5 Hours' },
                                    { value: 5, copy: '5 Hours' },
                                ]}
                                currentValue={estimatedCostOfParts.estimatedHoursNeeded || ''}
                                onChange={onChange('estimatedHoursNeeded')}
                                name="estimatedHoursNeeded"
                            />
                        </label>
                    </div>
                </div>
                <div className={style.subText}>
                    If approved, this is the max Hometree will cover to fix - any extras need
                    further authorisation
                </div>
                {!!Object.keys(errors).length && validationError}
            </div>

            {options.map(({ value, nextQuestion, nextQuestionSelector }) => {
                return (
                    <Button
                        customStyle={style.button}
                        key={value}
                        type="button"
                        onClick={() => {
                            // if optional don't setErr
                            // if mand, check, and do setErr

                            const hasErrors = !isValidForm();
                            if (!hasErrors) {
                                const submittedValue = {
                                    ...estimatedCostOfParts,
                                    estimatedPartsCost: estimatedCostOfParts.estimatedPartsCost
                                        ? Math.ceil(
                                              +estimatedCostOfParts.estimatedPartsCost * 100
                                          ) / 100
                                        : 0,
                                };
                                onSubmit(
                                    submittedValue,
                                    nextQuestionSelector
                                        ? nextQuestionSelector(submittedValue, formState)
                                        : nextQuestion
                                );
                            }
                        }}
                    >
                        {value}
                    </Button>
                );
            })}
        </>
    );
};

export default EstimatedCostOfPartsQuestion;
