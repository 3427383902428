import toast, { ToastOptions } from 'react-hot-toast';
import format from 'date-fns/format';

import { Download } from 'src/images';
import Button from 'src/components/Button';
import { getInvoicePdfUrl } from '../../api/getInvoicePdfUrl';
import { Invoice } from '../../api/getInvoices.types';
import styles from './index.module.scss';

interface Props {
    invoices: Invoice[];
}

const toastConfig: ToastOptions = {
    style: {
        borderRadius: '10px',
        background: '#3f4955',
        color: '#fff',
    },
    position: 'top-right',
};

const InvoiceList = ({ invoices }: Props) => {
    const handlePdfUrlDownload = async (s3Key: string) => {
        const url = await getInvoicePdfUrl(s3Key);
        if (url instanceof Error) {
            return toast.error(
                `We were unable to download your invoice at the moment. Please try again later or contact us.`,
                toastConfig
            );
        }
        return setTimeout(() => {
            window.open(url.url, '_blank');
        });
    };

    const formatter = Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' });
    return (
        <div className={styles.container}>
            <div className={styles.gridHeaderWrapper}>
                <div>
                    <p>Date issued</p>
                </div>
                <div>
                    <p>Invoice ID</p>
                </div>
                <div>
                    <p>Total amount</p>
                </div>
                <div>{/* <p>empty</p> */}</div>
            </div>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            {invoices.map((invoice) => (
                <div className={styles.gridRowWrapper} key={invoice.invoiceNumber}>
                    <div className={styles.gridItem}>
                        <p>{format(new Date(invoice.billingInvoiceDate), 'd MMM yy')}</p>
                    </div>
                    <div className={styles.gridItem}>
                        <p>{invoice.invoiceNumber}</p>
                    </div>
                    <div className={styles.gridItem}>
                        <p>{formatter.format(invoice.totalNetPence / 100)}</p>
                    </div>
                    {invoice.s3Key ? (
                        <div className={styles.gridItem}>
                            <Button
                                onClick={() => handlePdfUrlDownload(invoice.s3Key!)} // ts should be able to infer this...
                                type="submit"
                                customStyle={styles.downloadButton}
                            >
                                <Download />
                            </Button>
                        </div>
                    ) : (
                        <div className={styles.gridItem} />
                    )}
                </div>
            ))}
        </div>
    );
};

export default InvoiceList;
