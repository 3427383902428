import { Amplify, Auth } from 'aws-amplify';

const {
    REACT_APP_COGNITO_REGION,
    REACT_APP_COGNITO_USER_POOL_ID,
    REACT_APP_COGNITO_APP_CLIENT_ID,
} = process.env;

Amplify.configure({
    Auth: {
        region: REACT_APP_COGNITO_REGION,
        userPoolId: REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: REACT_APP_COGNITO_APP_CLIENT_ID,
    },
});

Auth.configure({
    authenticationFlowType: 'CUSTOM_AUTH',
});

export { Auth };
