import { JSONSchemaType } from 'ajv';

export interface PandoraErrorResponse {
    message: string;
}

export const pandoraErrorResponseSchema: JSONSchemaType<PandoraErrorResponse> = {
    type: 'object',
    properties: {
        message: { type: 'string' },
    },
    required: ['message'],
};

export const isPandoraErrorResponse = (input: any): input is PandoraErrorResponse => {
    return (input as PandoraErrorResponse).message !== undefined;
};
