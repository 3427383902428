import { SkeduloJob } from 'src/types/Skedulo';
import { jobTypeMap } from '../data/jobTypeMap';

export const hasOpenJobSameType = (jobs: SkeduloJob[], answerValue: string) => {
    const isAppliances = answerValue === 'appliances';
    const openJobSameType = jobs
        .filter(({ node }) => node.JobStatus !== 'Complete' && node.JobStatus !== 'Cancelled')
        .filter(({ node }) =>
            !isAppliances
                ? node.Type === jobTypeMap[answerValue]
                : jobTypeMap[answerValue].includes(node.Type)
        );

    return openJobSameType.length > 0;
};
