import { SetStateAction, useState } from 'react';
import { JobUrgency } from 'src/types/JobUrgency';
import { determineTimeSlotsToShow } from 'src/utils/dateForm';
import { FormState } from '../../types/Form';
import { Config } from '../../types/Question';
import DateForm from './DateForm';
import ToggleForm from './ToggleForm';

type Props = {
    title: string;
    formState: Partial<FormState>;
    setState: (name: string, value: string | number | boolean | string[]) => void;
    config: Config;
    errors?: Record<string, string>;
};

const NUM_TO_LIT_STRING = {
    1: 'One',
    2: 'Two',
    3: 'Three',
};

interface ValidationDateInput {
    dayOneDate?: string;
    dayOneTime?: string[];
    dayTwoDate?: string;
    dayTwoTime?: string[];
    dayThreeDate?: string;
    dayThreeTime?: string[];
}

export const validateCalendarInputs = (dateTimes: ValidationDateInput) => {
    const errors = {};

    [dateTimes.dayOneDate, dateTimes.dayTwoDate, dateTimes.dayThreeDate].forEach((date, index) => {
        if (!date) {
            errors[`day${NUM_TO_LIT_STRING[index + 1]}Date`] = 'Please select a date';
        }
    });

    [dateTimes.dayOneTime, dateTimes.dayTwoTime, dateTimes.dayThreeTime].forEach((time, index) => {
        if (!time || !time.length) {
            errors[`day${NUM_TO_LIT_STRING[index + 1]}Time`] = 'Please select a time';
        }
    });

    return errors;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Calendar = ({ title, formState, setState, config, errors }: Props) => {
    const [dayOneDate, setDayOneDate] = useState('');
    const [dayTwoDate, setDayTwoDate] = useState('');
    const [dayThreeDate, setDayThreeDate] = useState('');
    const [dayOneTime, setDayOneTime] = useState<string[]>([]);
    const [dayTwoTime, setDayTwoTime] = useState<string[]>([]);
    const [dayThreeTime, setDayThreeTime] = useState<string[]>([]);

    const onDateChange = (setDate: React.Dispatch<SetStateAction<string>>) => (e) => {
        const { value, name } = e.target;

        setDate(value);

        setState(name, value);
    };

    const onTimeChange =
        (setTime: React.Dispatch<SetStateAction<string[]>>, time: string[]) => (e) => {
            const { checked, value, name } = e.target;
            let newTime = [...time];

            if (checked) {
                newTime.push(value);
            } else {
                newTime = newTime.filter((eTime) => eTime !== value);
            }

            setState(name, newTime);
            setTime(newTime);
        };

    return (
        <>
            <div id="day1" className="claimform-dateblock">
                <div className="claimform-inputblock">
                    <h2>{title}</h2>
                    <p>
                        The below dates are the earliest engineers are likely to accommodate but we
                        will endeavour to find an earlier slot if one is available.{' '}
                        <b className="claimform-info">
                            We are unable to offer earlier appointments if you call us.
                        </b>
                    </p>
                </div>

                {[
                    {
                        indexLit: 'One',
                        date: dayOneDate,
                        setDate: setDayOneDate,
                        time: dayOneTime,
                        setTime: setDayOneTime,
                    },
                    {
                        indexLit: 'Two',
                        date: dayTwoDate,
                        setDate: setDayTwoDate,
                        time: dayTwoTime,
                        setTime: setDayTwoTime,
                    },
                    {
                        indexLit: 'Three',
                        date: dayThreeDate,
                        setDate: setDayThreeDate,
                        time: dayThreeTime,
                        setTime: setDayThreeTime,
                    },
                ].map((eachDateObj, index) => {
                    return (
                        <div
                            className="claimform-dateblock-box"
                            key={`date-time-form-${eachDateObj.indexLit}`}
                        >
                            <DateForm
                                name={`day${eachDateObj.indexLit}Date`}
                                title={`Preferred day ${index + 1}`}
                                subtitle="What day are you available?"
                                onChange={onDateChange(eachDateObj.setDate)}
                                alreadySelectedDates={[
                                    ...(index + 1 === 1 ? [] : ([new Date(dayOneDate)] as Date[])),
                                    ...(index + 1 === 2 ? [] : ([new Date(dayTwoDate)] as Date[])),
                                    ...(index + 1 === 3
                                        ? []
                                        : ([new Date(dayThreeDate)] as Date[])),
                                ]}
                                selectedDate={eachDateObj.date}
                                urgency={formState.urgency || JobUrgency.NORMAL}
                                errors={{ ...errors }}
                            />

                            <ToggleForm
                                name={`day${eachDateObj.indexLit}Time`}
                                title="What time slot is best?"
                                subtitle="Select as many as you like."
                                onChange={onTimeChange(eachDateObj.setTime, eachDateObj.time)}
                                timeSlotsToShow={determineTimeSlotsToShow(
                                    formState.urgency || null,
                                    eachDateObj.date === '' ? null : new Date(eachDateObj.date)
                                )}
                                selectedTimes={eachDateObj.time}
                                selectedDate={eachDateObj.date}
                                errors={{ ...errors }}
                            />
                        </div>
                    );
                })}
            </div>
        </>
    );
};
