import { JSONSchemaType } from 'ajv';

export interface FileUploadResponse {
    signedPutObjectUrl: string;
    s3Url: string;
}

export const fileUploadResponseSchema: JSONSchemaType<FileUploadResponse> = {
    type: 'object',
    properties: {
        signedPutObjectUrl: {
            type: 'string',
        },
        s3Url: {
            type: 'string',
        },
    },
    required: ['signedPutObjectUrl', 's3Url'],
};
