import { Link } from 'react-router-dom';
import styles from './index.module.scss';

interface Props {
    children: JSX.Element;
    text: string;
    link?: string;
    selected: boolean;
    disabled?: boolean;
    target?: string;
    absolute?: boolean;
}

const NavbarIcon = ({ children, text, link, selected, disabled, target, absolute }: Props) => {
    return absolute ? (
        <a className={`${styles.navbarIcon}`} href={link} target={target || '_self'} rel="noopener">
            {children}
            {text}
        </a>
    ) : (
        <Link
            className={`${styles.navbarIcon} ${selected ? styles.selected : styles.notSelected} ${
                disabled ? styles.disabledLink : ''
            }`}
            to={link || '#'}
            target={target || '_self'}
            rel="noopener"
        >
            {children}
            {text}
        </Link>
    );
};

export default NavbarIcon;
