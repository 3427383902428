import { useState } from 'react';
import Button from 'src/components/Button';
import { FormState } from 'src/features/closeJob/types/Form';
import { Option } from '../../../types/Question';
import style from './index.module.scss';

export interface Props {
    title: string;
    extraCopy?: string;
    options: Option[];
    onSubmit: (value: string, nextQuestion?: string) => void;
    formState: Partial<FormState>;
    fieldLength?: number;
    isSingleLine?: boolean;
    isOptional?: boolean;
    defaultValue?: string;
}

const TextQuestion = ({
    title,
    extraCopy,
    options,
    onSubmit,
    formState,
    fieldLength,
    isSingleLine,
    isOptional = false,
    defaultValue,
}: Props) => {
    const [text, setText] = useState(defaultValue || '');
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const isValidForm = () => {
        const newErrors: { [key: string]: string } = {};
        if (!isOptional && !text) {
            newErrors.text = 'Field is required';
        } else if (fieldLength && text.length > fieldLength) {
            newErrors.text = `Field must be less than ${fieldLength + 1} characters`;
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const onChange = (e) => {
        const currentValue = e.target.value;
        const currentFieldName = e.target.name;

        if (currentValue) {
            setErrors((prev) => {
                const newErrors = { ...prev };
                delete newErrors[currentFieldName];
                return newErrors;
            });
        }
        setText(currentValue);
    };

    const textRegion = isSingleLine ? (
        <input
            type="text"
            name="text"
            value={text}
            className={`${style.textAreaSingle} ${
                Object.keys(errors).length ? style.errorBorder : ''
            }`}
            onChange={onChange}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            aria-label={title}
        />
    ) : (
        <textarea
            name="text"
            value={text}
            rows={4}
            cols={20}
            className={`${style.textArea} ${Object.keys(errors).length ? style.errorBorder : ''}`}
            onChange={onChange}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            aria-label={title}
        />
    );

    const validationError = (
        <div className={style.validationError} role="alert">
            {Object.entries(errors).map(([key, error]) => (
                <div key={`each-error-${key}`}>{error}</div>
            ))}
        </div>
    );

    return (
        <>
            <h4 className={style.title}>{title}</h4>
            {extraCopy && <div className={style.extraCopy}>{extraCopy}</div>}
            <div className={style.inputAndError}>
                {textRegion}
                {!!Object.keys(errors).length && validationError}
            </div>

            {options.map(({ value, nextQuestion, nextQuestionSelector }) => {
                return (
                    <Button
                        customStyle={style.button}
                        key={value}
                        type="button"
                        onClick={() => {
                            // if optional don't setErr
                            // if mand, check, and do setErr

                            const hasErrors = !isValidForm();

                            if (!hasErrors) {
                                onSubmit(
                                    text,
                                    nextQuestionSelector
                                        ? nextQuestionSelector(text, formState)
                                        : nextQuestion
                                );
                            }
                        }}
                    >
                        {value}
                    </Button>
                );
            })}
        </>
    );
};

export default TextQuestion;
