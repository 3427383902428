import { JSONSchemaType } from 'ajv';
import { JobDetails, jobDetailsSchema } from 'src/types/Job';

export type JobAgendaSuccessResponse = {
    jobs: JobDetails[];
    nextCursor?: number;
};

export type JobAgendaErrorResponse = { message: string };

export type JobAgendaResponse = JobAgendaSuccessResponse | JobAgendaErrorResponse;

export const jobAgendaSuccessResponseSchema: JSONSchemaType<JobAgendaSuccessResponse> = {
    type: 'object',
    properties: {
        jobs: { type: 'array', items: jobDetailsSchema },
        nextCursor: { type: 'number', nullable: true },
    },
    required: ['jobs'],
};

export const jobAgendaErrorResponseSchema: JSONSchemaType<JobAgendaErrorResponse> = {
    type: 'object',
    properties: {
        message: { type: 'string' },
    },
    required: ['message'],
};

export const jobAgendaResponseSchema: JSONSchemaType<JobAgendaResponse> = {
    type: 'object',
    anyOf: [jobAgendaSuccessResponseSchema, jobAgendaErrorResponseSchema],
};

export const isJobAgendaSuccessResponse = (input: any): input is JobAgendaSuccessResponse => {
    return !!(input as JobAgendaSuccessResponse)[0].id;
};

export const isJobAgendaErrorResponse = (input: any): input is JobAgendaErrorResponse => {
    return !!(input as JobAgendaErrorResponse).message;
};
