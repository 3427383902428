import { CustomerData } from 'src/types/CustomerData';
import { QuestionStage, QuestionType } from '../types/Question';
import { FormState } from '../types/Form';

export const doorsQuestions = (_customerData: CustomerData): QuestionStage[] => {
    return [
        {
            handle: 'doorsGroup1',
            questions: [
                {
                    name: 'doorsIssueArea',
                    description: 'Where is the issue?',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            { copy: 'Main residence', value: 'main residence' },
                            { copy: 'Outbuilding', value: 'outbuilding' },
                            { copy: 'Garage', value: 'garage' },
                        ],
                    },
                },
            ],
            onSubmit: (values) => {
                const formState = {
                    doorsIssueArea: values.doorsIssueArea,
                } as Partial<FormState>;

                if (values.doorsIssueArea === 'outbuilding') {
                    formState.rejectProduct = 'outbuilding doors';
                }

                return formState;
            },
            options: [
                {
                    copy: 'Next',
                    nextQuestion: (values) => {
                        if (values.doorsIssueArea === 'main residence') {
                            return 'doorsMainResidence';
                        }

                        if (values.doorsIssueArea === 'outbuilding') {
                            return 'optionNotCovered';
                        }

                        if (values.doorsIssueArea === 'garage') {
                            return 'doorsGarage';
                        }

                        return 'optionNotCovered';
                    },
                },
            ],
        },
        {
            handle: 'doorsMainResidence',
            questions: [
                {
                    name: 'doorsTypeOf',
                    description: 'What type of door has an issue?',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            { copy: 'Internal door', value: 'internal' },
                            { copy: 'External door', value: 'external' },
                        ],
                    },
                },
                {
                    name: 'doorsSecureBuilding',
                    description: 'Is your building secure?',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            { copy: 'Yes', value: 'Yes' },
                            { copy: 'No', value: 'No' },
                        ],
                    },
                },
            ],
            onSubmit: (values) => {
                const formState = {
                    doorsTypeOf: values.doorsTypeOf,
                    doorsSecureBuilding: values.doorsSecureBuilding,
                } as Partial<FormState>;

                if (values.doorsTypeOf === 'internal') {
                    formState.rejectProduct = 'internal doors';
                }

                return formState;
            },
            options: [
                {
                    copy: 'Next',
                    nextQuestion: (values) => {
                        if (values.doorsTypeOf === 'internal') {
                            return 'optionNotCovered';
                        }

                        return 'vulnerability';
                    },
                },
            ],
        },
        {
            handle: 'doorsGarage',
            questions: [
                {
                    name: 'doorsElectric',
                    description: 'Is your garage door powered by an electric unit?',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            { copy: 'Yes', value: 'Yes' },
                            { copy: 'No', value: 'No' },
                        ],
                    },
                },
                {
                    name: 'doorsSecureBuilding',
                    description: 'Is your building secure?',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            { copy: 'Yes', value: 'Yes' },
                            { copy: 'No', value: 'No' },
                        ],
                    },
                },
            ],
            onSubmit: (values) => {
                const formState = {
                    doorsElectric: values.doorsElectric,
                    doorsSecureBuilding: values.doorsSecureBuilding,
                } as Partial<FormState>;

                if (values.doorsElectric === 'Yes') {
                    formState.rejectProduct = 'electric powered doors';
                }

                return formState;
            },
            options: [
                {
                    copy: 'Next',
                    nextQuestion: (values) => {
                        if (values.doorsElectric === 'Yes') {
                            return 'optionNotCovered';
                        }

                        return 'vulnerability';
                    },
                },
            ],
        },
    ];
};
