import Ajv, { JSONSchemaType } from 'ajv';
import addFormats from 'ajv-formats';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { captureSentryError } from 'src/features/login/utils/captureSentryError';
import { errorToJson } from './error';
import { ajvErrorFormatter } from './types';

const defaultHeaders = {
    'Content-Type': 'application/json',
};

export enum HttpMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
}

interface BaseHttpParams<T> {
    url: string;
    headers?: Record<string, string>;
    params?: Record<string, string>;
    responseValidationSchema?: JSONSchemaType<T>;
    callbacks?: Record<string, Function>;
}

interface BuildHttpRequestParams<T> {
    url: string;
    method: HttpMethod;
    params?: Record<string, string>;
    headers?: Record<string, string>;
    body: T;
    callbacks?: Record<string, Function>;
}

interface GetHttpParams<Res> extends BaseHttpParams<Res> {}

interface PutHttpParams<Req, Res> extends BaseHttpParams<Res> {
    body: Req;
}

interface PostHttpParams<Req, Res> extends BaseHttpParams<Res> {
    body: Req;
}

interface ValidationError {
    valid: false;
    errors: string[];
}

interface ValidationResult<T> {
    valid: true;
    result: T;
}

export const buildHttpRequest = <RequestBody>({
    url,
    method,
    body,
    params = {},
    headers = {},
    callbacks = {},
}: BuildHttpRequestParams<RequestBody>): AxiosRequestConfig<RequestBody> => ({
    url,
    method,
    headers: {
        ...defaultHeaders,
        ...headers,
    },
    params: {
        ...params,
    },
    ...(body && { data: body }),
    ...callbacks,
});

export const validateResponse = <Response>(
    response: unknown,
    schema: JSONSchemaType<Response>
): ValidationResult<Response> | ValidationError => {
    const ajv = new Ajv({ coerceTypes: true, allErrors: true });
    addFormats(ajv);
    const validate = ajv.compile<Response>(schema);

    const valid = validate(response);
    if (!valid) {
        return {
            valid: false,
            errors: ajvErrorFormatter(validate.errors),
        };
    }

    return {
        valid: true,
        result: { ...response },
    };
};

export const httpRequest = async <RequestBody, Response>(
    request: AxiosRequestConfig<RequestBody>,
    validationSchema?: JSONSchemaType<Response>
): Promise<Response> => {
    let result: unknown;
    try {
        const { data } = await axios(request);
        result = data;
    } catch (error) {
        const httpError = new Error('HTTP request failed');
        captureSentryError(httpError, {
            url: request.url || '',
            axiosError: JSON.stringify((error as AxiosError).toJSON()),
            error: JSON.stringify(errorToJson(error)),
        });
        throw httpError;
    }

    if (validationSchema) {
        const validationResult = validateResponse<Response>(result, validationSchema);
        if (!validationResult.valid) {
            const validationError = new Error('Response validation failed');
            captureSentryError(validationError, {
                url: request.url || '',
                validationErrors: JSON.stringify(validationResult.errors),
            });

            throw validationError;
        }

        return validationResult.result;
    }

    // When there is no validation schema so just cast as given type and hope
    return result as Response;
};

export const get = async <Response>({
    url,
    params,
    headers,
    callbacks,
    responseValidationSchema,
}: GetHttpParams<Response>): Promise<Response> => {
    const request = buildHttpRequest<undefined>({
        url,
        params,
        method: HttpMethod.GET,
        headers,
        body: undefined,
        callbacks,
    });

    const response = await httpRequest<undefined, Response>(request, responseValidationSchema);
    return response;
};

export const put = async <RequestBody, Response>({
    url,
    params,
    headers,
    body,
    callbacks,
    responseValidationSchema,
}: PutHttpParams<RequestBody, Response>): Promise<Response> => {
    const request = buildHttpRequest<RequestBody>({
        url,
        params,
        method: HttpMethod.PUT,
        headers,
        callbacks,
        body,
    });

    const response = await httpRequest<RequestBody, Response>(request, responseValidationSchema);
    return response;
};

export const post = async <RequestBody, Response>({
    url,
    params,
    headers,
    body,
    callbacks,
    responseValidationSchema,
}: PostHttpParams<RequestBody, Response>): Promise<Response> => {
    const request = buildHttpRequest<RequestBody>({
        url,
        params,
        method: HttpMethod.POST,
        headers,
        callbacks,
        body,
    });

    const response = await httpRequest<RequestBody, Response>(request, responseValidationSchema);
    return response;
};
