import { JOBS_PUBLIC_ENDPOINT } from 'src/constants';
import { getAuthenticatedUser, getTokenFromAuthenticatedUser } from 'src/features/login';
import { captureSentryError } from 'src/features/login/utils/captureSentryError';
import { getMandatoryEnvVar } from 'src/utils/env';
import { get } from 'src/utils/http';
import {
    isJobAgendaErrorResponse,
    jobAgendaResponseSchema,
    JobAgendaSuccessResponse,
} from './getJobAgenda.types';

export const getJobAgenda = async (
    from: string,
    to?: string,
    status?: string,
    limit?: number,
    cursor?: number
): Promise<JobAgendaSuccessResponse> => {
    const user = await getAuthenticatedUser();
    const token = getTokenFromAuthenticatedUser(user);
    // getMandatoryEnvVar must live at this level in order for tests to successfully overwrite the process.env variable
    const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

    const searchParams: Record<string, string> = {
        dateFrom: from,
    };
    if (to) searchParams.dateTo = to;
    if (status) searchParams.status = status;
    if (typeof limit !== 'undefined') searchParams.limit = limit.toString();
    if (typeof cursor !== 'undefined') searchParams.cursor = cursor.toString();

    try {
        const response = await get({
            url: `${API_BASE_URL}/${JOBS_PUBLIC_ENDPOINT}/v1/engineer-jobs/agenda`,
            params: searchParams,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            responseValidationSchema: jobAgendaResponseSchema,
        });

        if (isJobAgendaErrorResponse(response)) {
            throw new Error(`Error message from api: ${response.message}`);
        }
        return response;
    } catch (error) {
        await captureSentryError(error, { feature: 'getJobAgenda' });
        throw new Error(`Failed to get job agenda: ${error.message}`);
    }
};
