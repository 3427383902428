import { Part } from 'src/features/closeJob/types/Form';

export interface CanSendToManufacturerInput {
    partsRequested: Part[];
    boilerMake: string;
    issueArea: string;
    totalCostOfFix: number;
    maxTotalCostFix: number;
}

export const canSendToManufacturer = (value: CanSendToManufacturerInput): boolean => {
    const normalizeBoilerMake = value.boilerMake.toLowerCase().trim();
    const normalizedIssueArea = value.issueArea.toLowerCase().trim();
    if (normalizedIssueArea !== 'boiler') {
        return false;
    }

    if (value.totalCostOfFix < value.maxTotalCostFix) {
        return false;
    }

    if (
        [
            'worcester bosch',
            'worcester-bosch',
            'ideal',
            'baxi',
            'remeha',
            'glow worm',
            'glow-worm',
            'main',
            'potterton',
            'vaillant',
        ].includes(normalizeBoilerMake)
    ) {
        return true;
    }

    return false;
};
