import { format } from 'date-fns';
import CalendarDate from 'src/components/Calendar/DateRadio';

import { getWeekDay } from 'src/utils/dates';
import style from './index.module.scss';

export interface Props {
    start: string;
    end: string;
    isChecked?: boolean;
}

const calculateDaySlot = (start: Date): string => {
    switch (true) {
        case start.getHours() >= 16:
            return 'Evening';
        case start.getHours() >= 12:
            return 'Afternoon';
        default:
            return 'Morning';
    }
};

const Calendar = ({ start, end, isChecked }: Props) => {
    const startDatetime = new Date(start);
    const endDatetime = new Date(end);

    const startTime = format(startDatetime, 'h:mmaa');
    const endTime = format(endDatetime, 'h:mmaa');
    const date = format(startDatetime, 'MMMM yyyy').split(' ');
    const daySlot = calculateDaySlot(startDatetime);

    return (
        <div className={style.info} data-testid="calendar">
            <div className={style.date}>
                <div data-testid="year">
                    {date[0]} <span className={style.year}>{date[1]}</span>
                </div>
            </div>
            <div className={style.calendar}>
                <div className={style.container}>
                    <CalendarDate
                        dateWeek={getWeekDay(startDatetime)}
                        dateNum={format(startDatetime, 'dd')}
                        name="alternativeDate"
                        value={`${start}-${end}`}
                        isChecked={isChecked}
                        readOnly
                    />
                </div>
                <div>
                    <div className={`${style.timeOfDay} ${style[daySlot]}`}>{daySlot}</div>
                    <div
                        className={style.timeSlot}
                        data-testid="timeslot"
                    >{`${startTime} - ${endTime}`}</div>
                </div>
            </div>
        </div>
    );
};

export default Calendar;
