import React from 'react';

import style from './index.module.scss';

interface Props {
    mode?: 'wide';
    children: React.ReactNode;
}

const Layout = ({ mode, children }: Props) => {
    return (
        <section className={style.main} data-mode={mode}>
            {children}
        </section>
    );
};

export default Layout;
