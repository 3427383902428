interface Props {
    message: string;
    style?: string;
    className?: string;
}

const ErrorBar = ({ message, style, className }: Props) => {
    return (
        <div className={`error-bar ${style} ${className}`}>
            <div
                role="alert"
                className="error-bar-message error"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: message }}
            />
        </div>
    );
};

export default ErrorBar;
