import { ChangeEvent, FocusEvent } from 'react';
import style from './index.module.scss';

export interface Props {
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
    inputType: keyof typeof config;
    placeholder?: string;
    id?: string;
    name?: string;
    disabled?: boolean;
    autoFocus?: boolean;
    ariaLabel?: string;
    'data-testid'?: string;
}

enum InputMode {
    TEXT = 'text',
    NUMERIC = 'numeric',
    EMAIL = 'email',
    SEARCH = 'search',
    TEL = 'tel',
    URL = 'url',
    NONE = 'none',
    DECIMAL = 'decimal',
}

const config = {
    currency: {
        type: 'text',
        inputMode: InputMode.DECIMAL,
        prefix: '£',
        pattern: '[0-9]*',
    },
    text: {
        type: 'text',
        inputMode: InputMode.TEXT,
        prefix: '',
        pattern: '',
    },
    number: {
        type: 'text',
        inputMode: InputMode.DECIMAL,
        prefix: '',
        pattern: '[0-9]*',
    },
    email: {
        type: 'text',
        inputMode: InputMode.EMAIL,
        prefix: '',
        pattern: '',
    },
};

const Input = ({
    value,
    onChange,
    inputType,
    placeholder,
    disabled = false,
    id = '',
    name = '',
    onFocus,
    autoFocus = false,
    ariaLabel,
    'data-testid': dataTestId,
}: Props) => {
    const { type, inputMode, prefix, pattern } = config[inputType];

    return (
        <input
            className={style.input}
            name={name}
            type={type}
            id={id}
            inputMode={inputMode}
            placeholder={placeholder}
            value={`${prefix || ''}${value}`}
            onChange={onChange}
            disabled={disabled}
            pattern={pattern}
            onFocus={onFocus}
            data-testid={dataTestId}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={autoFocus}
            aria-label={ariaLabel}
        />
    );
};

export default Input;
