import { Part } from 'src/features/closeJob/types/Form';
import { formatPounds } from 'src/utils/prices';
import PartForm from './PartForm';

interface Props {
    setShowModal: (boolean) => void;
    setParts: (object) => void;
    parts: Part[];
    isActualCost?: boolean;
    initialPartData?: Part;
}

const PartAddModal = ({ setShowModal, setParts, parts, isActualCost, initialPartData }: Props) => {
    const onSubmit = (submittedPart: Part, totalCost: string) => {
        const getPartIfExist = parts.find((part) => part.name === submittedPart.name);
        setParts((prevState) => {
            const removePartFromStateIfExist = getPartIfExist
                ? prevState.filter((prevStatePart) => prevStatePart.name !== getPartIfExist?.name)
                : prevState;
            return [
                ...removePartFromStateIfExist,
                {
                    ...(isActualCost && {
                        actualCost: +totalCost,
                    }),
                    ...(!isActualCost && {
                        estimatedCost: +totalCost,
                    }),
                    name: submittedPart.name,
                    quantity: submittedPart.quantity,
                    actuallyUsed: isActualCost ? true : null,
                    estimated_hours_primary: submittedPart.estimated_hours_primary,
                    estimated_hours_secondary: submittedPart.estimated_hours_secondary,
                    max_tolerance: submittedPart.max_tolerance,
                },
            ];
        });
    };

    const renderButtonText = (totalCost: number) =>
        totalCost ? `Add for £${formatPounds(totalCost)}` : 'Add';

    return (
        <PartForm
            setShowModal={setShowModal}
            parts={parts}
            isActualCost={isActualCost}
            initialPartData={initialPartData}
            onSubmit={onSubmit}
            renderButtonText={renderButtonText}
        />
    );
};

export default PartAddModal;
