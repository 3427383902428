import { useState, useMemo, useEffect } from 'react';
import { useGetJobParts } from 'src/features/closeJob/hooks/useGetJobParts';
import { Part } from 'src/features/closeJob/types/Form';
import { XYellow } from 'src/images';
import Input from 'src/components/Input';
import { formatPounds } from 'src/utils/prices';
import Button from 'src/components/Button';
import SearchableDropdown from './SearchableDropdown';
import style from './PartNeededModal.module.scss';

interface Props {
    setShowModal: (boolean) => void;
    parts: Part[];
    isActualCost?: boolean;
    initialPartData?: Part;
    onSubmit: (part: Part, cost: string) => void;
    renderButtonText?: (totalCost: number) => string;
    title?: string;
}

const PartForm = ({
    setShowModal,
    parts,
    isActualCost = false,
    initialPartData,
    onSubmit,
    renderButtonText,
    title,
}: Props) => {
    const [cost, setCost] = useState('0');
    const [partDescription, setPartDescription] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const totalPartCost = useMemo(() => formatPounds(Number(cost) * quantity), [cost, quantity]);

    const { isError, isLoading, data } = useGetJobParts();

    const totalCost = Number(cost) * quantity;

    useEffect(() => {
        if (initialPartData) {
            setCost(
                (
                    (initialPartData.actualCost || initialPartData.estimatedCost || 0) /
                    initialPartData.quantity
                ).toString()
            );
            setPartDescription(initialPartData.name);
            setQuantity(initialPartData.quantity);
        }
    }, [initialPartData]);

    const isFormValid = cost && !Number.isNaN(Number(cost)) && partDescription && quantity;

    const onClick = () => {
        const newErrors: { [key: string]: string } = {};
        if (Number.isNaN(Number(cost))) {
            newErrors.estimatedPartsCost = 'Total cost must be a number.';
            return setErrors(newErrors);
        }

        const part = !(data instanceof Error)
            ? data?.find((jobPart) => jobPart.name === partDescription)
            : undefined;

        onSubmit(
            {
                name: partDescription,
                quantity,
                estimated_hours_primary: Number(part?.estimated_hours_primary) || 0,
                estimated_hours_secondary: Number(part?.estimated_hours_secondary) || 0,
                max_tolerance: Number(part?.max_tolerance) || 0,
            },
            totalPartCost
        );

        return setShowModal(false);
    };

    let dropdownContent;
    if (isLoading) {
        dropdownContent = <p className={style.paragraph}>Loading...</p>;
    } else if (isError || data instanceof Error) {
        dropdownContent = <p className={style.paragraph}>Something went wrong getting parts.</p>;
    } else if (!data || !data.length) {
        dropdownContent = <p className={style.paragraph}>We are unable to find any parts</p>;
    } else {
        const displayedParts = data
            .filter(
                ({ name }) =>
                    !parts.some((part) => {
                        if (isActualCost) {
                            return part.actuallyUsed && name === part.name;
                        }
                        return name === part.name;
                    })
            )
            .map(({ name }) => ({ value: name, copy: name }));

        dropdownContent = (
            <SearchableDropdown
                value={partDescription}
                onChange={setPartDescription}
                onClear={() => setPartDescription('')}
                options={displayedParts}
                placeholder="Search parts"
            />
        );
    }

    const validationError = (
        <div className={style.validationError} role="alert">
            <ul>
                {Object.entries(errors).map(([key, error]) => (
                    <li key={`each-error-${key}`}>{error}</li>
                ))}
            </ul>
        </div>
    );

    return (
        <div className={style.container}>
            <div className={style.heading}>
                <header className={style.title}>{title || 'New part to fit'}</header>
                <Button
                    type="button"
                    onClick={() => setShowModal(false)}
                    customStyle={style.closeButton}
                >
                    <XYellow />
                </Button>
            </div>
            <label htmlFor="partToFit">
                <p className={style.paragraph}>
                    {isActualCost
                        ? 'Which part did you fit today?'
                        : 'Which part are you going to fit?'}
                </p>
                {dropdownContent}
            </label>
            <label htmlFor="costOfPart">
                <p className={style.paragraph}>Gross Part Cost</p>
                <Input
                    inputType="currency"
                    id="costOfPart"
                    name="costOfPart"
                    value={(cost || '').toString()}
                    onChange={(e) => setCost(e.target.value.substring(1))}
                    onFocus={() => setCost('')}
                />
            </label>
            <label htmlFor="quantity">
                <p className={style.paragraph}>Quantity</p>
                <Input
                    inputType="number"
                    id="quantity"
                    name="quantity"
                    value={(quantity || '').toString()}
                    onChange={(e) => setQuantity(+e.target.value)}
                />
            </label>
            {!!Object.keys(errors).length && validationError}
            <Button
                type="button"
                onClick={onClick}
                isDisabled={!isFormValid}
                customStyle={style.addButton}
            >
                {renderButtonText
                    ? renderButtonText(totalCost)
                    : `Add for £${formatPounds(totalCost)}`}
            </Button>
        </div>
    );
};

export default PartForm;
