import { Link } from 'react-router-dom';
import { Routes } from 'src/types/Routes';
import { Attention, ExpandArrow } from 'src/images';
import shortenPostcode from 'src/utils/shortenPostcode';

import { decodeString } from 'src/utils/decodeString';
import { JobOfferRow } from '../../api/getJobOffers.types';
import style from './index.module.scss';

export interface Props {
    jobOffer: JobOfferRow;
}

const JobOfferCard = ({ jobOffer }: Props) => (
    <Link
        data-job-offer-id={jobOffer.job_offer_id}
        to={Routes.JOB_OFFER_DETAILS.replace(':jobOfferId', jobOffer.resource_job_offer_id)}
        className={style.cardWrapper}
    >
        <div className={style.offerCardContainer}>
            <div>
                <div className={style.postcodeDisplay}>
                    <p>
                        <span>Postcode</span> <span>{shortenPostcode(jobOffer.postcode)}</span>
                    </p>
                    <Link
                        to={Routes.JOB_OFFER_DETAILS.replace(
                            ':jobOfferId',
                            jobOffer.resource_job_offer_id
                        )}
                    >
                        <ExpandArrow />
                    </Link>
                </div>
                <p className={style.summary}>{decodeString(jobOffer.summary)}</p>
                {jobOffer.urgency && (
                    <div className={style.tag}>
                        <span>
                            <Attention role="img" title="Warning icon" />
                        </span>
                        <span className={style.urgencyText}>{jobOffer.urgency}</span>
                    </div>
                )}
            </div>
        </div>
    </Link>
);

export default JobOfferCard;
