import { useQuery } from 'react-query';
import { AmplifyUser, AuthenticatedUser } from '../types/AuthenticatedUser';
import { Auth } from '../features/login/api/amplify';

const NOT_AUTHENTICATED_ERROR = 'The user is not authenticated';

const getAuthenticatedUser = async (): Promise<AuthenticatedUser> => {
    try {
        // AWS type definition is wrong so have to define our own here
        const data = (await Auth.currentAuthenticatedUser()) as AmplifyUser;
        return {
            authenticatedUser: {
                username: data.username,
                attributes: data.attributes,
            },
            isAuthenticated: true,
        };
    } catch (err: unknown) {
        // This isn't really an error as we use this method
        // to determine if the user is logged in or not
        if (err === NOT_AUTHENTICATED_ERROR) {
            return {
                isAuthenticated: false,
            };
        }

        throw err;
    }
};

export const useAuthenticatedUser = () => {
    const { isLoading, isError, error, data, refetch } = useQuery(['user'], getAuthenticatedUser, {
        retry: false,
    });

    return {
        isLoading,
        isError,
        error: error as Error,
        data,
        refetch,
    };
};
