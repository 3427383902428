import { useMemo } from 'react';
import { formatPounds, getNetCost } from 'src/utils/prices';
import { Part } from '../../../types/Form';
import style from './PartsAdded.module.scss';
import PartInformation from './PartInformation';

interface Props {
    parts: Part[];
    setParts: (parts: Part[]) => void;
    isActualCost?: boolean;
    isVatRegistered?: boolean;
    setErrors?: (errors: { [key: string]: string }) => void;
    onEdit: (part: Part) => () => void;
}

const PartsAdded = ({
    parts,
    setParts,
    isActualCost = false,
    isVatRegistered = false,
    setErrors,
    onEdit,
}: Props) => {
    const totalGrossCost = useMemo(
        () =>
            parts.reduce((accumulator, item) => {
                if (isActualCost) {
                    return accumulator + (item.actualCost || 0);
                }
                return accumulator + (item.estimatedCost || 0);
            }, 0),
        [parts, isActualCost]
    );

    const totalNetCost = useMemo(
        () => (isVatRegistered ? getNetCost(totalGrossCost) : totalGrossCost),
        [totalGrossCost, isVatRegistered]
    );

    const handleRemove = (name: string) => () => {
        if (setErrors) {
            setErrors({});
        }
        if (isActualCost) {
            setParts(
                parts.map((part) => {
                    const clonePart = part;
                    if (part.name === name) {
                        clonePart.actuallyUsed = false;
                        clonePart.actualCost = null;
                    }
                    return clonePart;
                })
            );
        } else {
            setParts(parts.filter((part) => part.name !== name));
        }
    };

    const shouldDisplay = (actuallyUsed: boolean | null | undefined) =>
        typeof actuallyUsed === 'object' || (typeof actuallyUsed === 'boolean' && actuallyUsed);

    return (
        <>
            <div className={style.partsAddedContainer}>
                {parts.map(({ name, estimatedCost, quantity, actualCost, actuallyUsed }) => {
                    if (shouldDisplay(actuallyUsed)) {
                        return (
                            <PartInformation
                                key={`${name}`}
                                name={name}
                                quantity={quantity}
                                estimatedCost={estimatedCost}
                                actualCost={actualCost}
                                onRemove={handleRemove(name)}
                                onEdit={onEdit({ name, quantity, estimatedCost })}
                                isVatRegistered={isVatRegistered}
                            />
                        );
                    }
                    return null;
                })}
            </div>
            <div className={style.partsSummaryContainer}>
                <div className={style.partsGrid}>
                    <div className={style.partsGridLabel}>Net part cost:</div>
                    <div className={style.partsGridValue}>£{formatPounds(totalNetCost)}</div>
                    <div className={style.line} />
                    <div className={style.partsGridLabel}>Gross part cost:</div>
                    <div className={style.partsGridValue}>
                        <span>£{formatPounds(totalGrossCost)}</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PartsAdded;
