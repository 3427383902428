import Ajv from 'ajv';
import { QueryFunction } from 'react-query';
import { JOBS_PUBLIC_ENDPOINT } from 'src/constants';
import { JobDetails } from 'src/types/Job';
import { getMandatoryEnvVar } from 'src/utils/env';
import {
    getAuthenticatedUser,
    getTokenFromAuthenticatedUser,
} from '../../login/utils/authenticatedUser';
import { isJobDetailsErrorResponse, jobDetailsResponseSchema } from './getJobDetails.types';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

export type JobDetailsQueryKey = [string, string];

export const getJobDetails: QueryFunction<JobDetails, JobDetailsQueryKey> = async ({
    queryKey,
}) => {
    const user = await getAuthenticatedUser();
    const token = getTokenFromAuthenticatedUser(user);

    const [, jobId] = queryKey;

    if (!jobId) {
        throw new Error(
            'No job id provided. Does the implementation of getJobDetails have the correct queryKeys?'
        );
    }

    try {
        // eslint-disable-next-line no-console
        console.log(`Getting details for job ${jobId}`);

        const response = await fetch(
            `${API_BASE_URL}/${JOBS_PUBLIC_ENDPOINT}/v1/engineer-jobs/${jobId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const jobDetailsResponse = await response.json();
        const ajv = new Ajv({ coerceTypes: true });
        const validator = ajv.compile(jobDetailsResponseSchema);
        const valid = validator(jobDetailsResponse);
        if (!valid) {
            throw new Error(
                `Error in validating jobDetailsResponse: ${JSON.stringify(
                    validator.errors?.[0].params
                )}`
            );
        }
        if (isJobDetailsErrorResponse(jobDetailsResponse)) {
            throw new Error(jobDetailsResponse.message);
        }
        return jobDetailsResponse;
    } catch (err) {
        throw new Error(`Error in job details: ${err}`);
    }
};
