export enum Routes {
    LANDING = '/',
    AUTH = '/auth',
    CLAIM_CONFIRMATION = '/claim/confirmation',
    CLAIM_NEW = '/claim/new',
    DASHBOARD = '/dashboard',
    JOB = '/job',
    CUSTOMER_ACCOUNT = '/customer/account',
    ENGINEER_ACCOUNT = '/engineer/account',
    ENGINEER_ACCOUNT_PROFILE = '/engineer/account/profile',
    ENGINEER_ACCOUNT_INVOICES = '/engineer/account/invoices',
    JOB_OFFERS = '/job/offers',
    JOB_OFFER_DETAILS = '/job/offers/:jobOfferId',
    JOB_AGENDA = '/job/agenda',
    JOB_DETAILS = '/job/:jobId',
    PROPERTIES = '/properties',
    ENGINEER_NOT_IMPLEMENTED = '/engineer/coming-soon',
    CLOSE_JOB = '/job/:jobId/close',
    CANCEL_JOB = '/job/:jobId/cancel',
    RESCHEDULE_JOB = '/job/:jobId/reschedule',
    LOCATION_DEMO = '/location/470f27ab-c75c-4a9a-a563-33a410fef845',
    SELECT_PROPERTY = '/select-property',
    CLAIM_NEW_IN_PROGRESS = '/claim/new/in-progress',
    TERMS_AND_CONDITIONS = '/terms-conditions',
    EMAIL_SIGN_UP = '/email-sign-up',
    ANYTHING_ELSE = '*',
}
