import Button from 'src/components/Button';
import { ReactComponent as Back } from 'src/images/chevron-left.svg';
import { JobDetails } from 'src/types/Job';
import { UnreachableError } from 'src/utils/unreachableError';
import {
    ActualCostOfPartsValue,
    ApplianceDetailsValue,
    BoilerDetailsValue,
    BoilerGaugesValue,
    EstimatedCostOfPartsValue,
    FormState,
    Part,
} from '../../types/Form';
import { OnSubmitValue, Question, QuestionType } from '../../types/Question';

import FileUpload from '../../../../components/FileUpload';
import Authorised from '../Handoff/Authorised';
import Complete from '../Handoff/Complete';
import CostAuthorisation from '../Handoff/CostAuthorisation';
import FollowOnScheduled from '../Handoff/FollowOnScheduled';
import InvestigationRequired from '../Handoff/InvestigationRequired';
import ActualCostOfPartsQuestion from './ActualCostOfPartsQuestion';
import ActualHoursQuestion from './ActualHoursQuestion';
import BoilerDetailsQuestions from './BoilerDetailsQuestions';
import BoilerGaugesQuestion from './BoilerGaugesQuestion';
import CheckboxQuestion from './CheckboxQuestion';
import ConfirmEstimatedCostOfPartsQuestion from './ConfirmEstimatedCostOfPartsQuestion';
import ConfirmPartsNeeded from './ConfirmPartsNeeded';
import CurrencyQuestion from './CurrencyQuestion';
import DropdownQuestion from './DropdownQuestion';
import EstimatedCostOfPartsQuestion from './EstimatedCostOfPartsQuestion';
import FollowOnQuestions from './FollowOnQuestions';
import NewActualCostOfPartsQuestion from './NewActualCostOfPartsQuestion';
import PartsNeeded from './PartsNeeded';
import RadioQuestion from './RadioQuestion';
import SelectQuestion from './SelectQuestion';
import TextQuestion from './TextQuestion';
import style from './index.module.scss';
import ApplianceDetailsQuestions from './ApplianceDetailsQuestion';

interface CurrentQuestionProps {
    currentQuestion: Question;
    formState: Partial<FormState>;
    jobDetails: JobDetails;
    onSubmit: (value: Partial<FormState>, nextQuestion: string) => void;
    onBack: () => void;
    showBackButton?: boolean;
}
const CurrentQuestion = ({
    currentQuestion,
    formState,
    onSubmit,
    jobDetails,
    onBack,
    showBackButton,
}: CurrentQuestionProps) => {
    const {
        question,
        body,
        type,
        options = [],
        onSubmit: onS,
        config = {},
        isOptional,
        defaultValue,
    } = currentQuestion;

    const handleOnSubmit = (value: OnSubmitValue, nextQuestion: string) => {
        onSubmit(onS(value, formState), nextQuestion);
    };

    const renderQuestionComponent = () => {
        switch (type) {
            case QuestionType.SELECT:
                return (
                    <SelectQuestion
                        title={question}
                        body={body}
                        options={options}
                        onSubmit={handleOnSubmit}
                        config={config}
                        formState={formState}
                    />
                );
            case QuestionType.TEXT:
                return (
                    <TextQuestion
                        title={question}
                        extraCopy={config.completeCopy}
                        options={options}
                        onSubmit={handleOnSubmit}
                        defaultValue={String(defaultValue || '')}
                        formState={formState}
                        fieldLength={config.text?.fieldLength}
                        isOptional={isOptional ?? false}
                    />
                );
            case QuestionType.SINGLE_TEXT:
                return (
                    <TextQuestion
                        title={question}
                        options={options}
                        onSubmit={handleOnSubmit}
                        defaultValue={String(defaultValue || '')}
                        formState={formState}
                        fieldLength={config.text?.fieldLength}
                        isSingleLine
                        isOptional={isOptional ?? false}
                    />
                );
            case QuestionType.CURRENCY:
                return (
                    <CurrencyQuestion
                        title={question}
                        options={options}
                        onSubmit={handleOnSubmit}
                    />
                );
            case QuestionType.DROPDOWN:
                return (
                    <DropdownQuestion
                        title={question}
                        config={config}
                        options={options}
                        formState={formState}
                        onSubmit={handleOnSubmit}
                        isOptional={isOptional}
                        defaultValue={String(defaultValue)}
                    />
                );
            case QuestionType.FILE_UPLOAD:
                return (
                    <FileUpload
                        title={question}
                        options={options}
                        onSubmit={handleOnSubmit}
                        isCloseJob
                    />
                );
            case QuestionType.ESTIMATED_COST_OF_PARTS:
                return (
                    <EstimatedCostOfPartsQuestion
                        title={question}
                        options={options}
                        onSubmit={handleOnSubmit}
                        formState={formState}
                        defaultValue={{
                            partsDescription:
                                (defaultValue as EstimatedCostOfPartsValue)?.partsDescription || '',
                            estimatedPartsCost:
                                (defaultValue as EstimatedCostOfPartsValue)?.estimatedPartsCost ||
                                0,
                            estimatedHoursNeeded:
                                (defaultValue as EstimatedCostOfPartsValue)?.estimatedHoursNeeded ||
                                0,
                        }}
                    />
                );
            case QuestionType.ACTUAL_COST_OF_PARTS:
                return (
                    <ActualCostOfPartsQuestion
                        title={question}
                        options={options}
                        onSubmit={handleOnSubmit}
                        formState={formState}
                        jobDetails={jobDetails}
                        defaultValue={{
                            actualPartsDescription:
                                (defaultValue as ActualCostOfPartsValue)?.actualPartsDescription ||
                                '',

                            actualGrossPartsCost:
                                (defaultValue as ActualCostOfPartsValue)?.actualGrossPartsCost ||
                                '0',
                            actualNetPartsCost:
                                (defaultValue as ActualCostOfPartsValue)?.actualNetPartsCost || 0,
                        }}
                    />
                );
            case QuestionType.ACTUAL_HOURS:
                return (
                    <ActualHoursQuestion
                        title={question}
                        options={options}
                        jobDetails={jobDetails}
                        formState={formState}
                        onSubmit={handleOnSubmit}
                    />
                );
            case QuestionType.BOILER_DETAILS:
                return (
                    <BoilerDetailsQuestions
                        title={question}
                        options={options}
                        onSubmit={handleOnSubmit}
                        formState={formState}
                        defaultValue={{
                            boilerMake: (defaultValue as BoilerDetailsValue)?.boilerMake || '',
                            boilerModel: (defaultValue as BoilerDetailsValue)?.boilerModel || '',
                            boilerGcNumber:
                                (defaultValue as BoilerDetailsValue)?.boilerGcNumber || '',
                        }}
                    />
                );
            case QuestionType.FOLLOW_ON_DETAILS:
                return (
                    <FollowOnQuestions
                        title={question}
                        options={options}
                        onSubmit={handleOnSubmit}
                        formState={formState}
                        jobDetails={jobDetails}
                    />
                );
            case QuestionType.BOILER_GAUGES:
                return (
                    <BoilerGaugesQuestion
                        title={question}
                        options={options}
                        onSubmit={handleOnSubmit}
                        formState={formState}
                        defaultValue={{
                            gasRateKw: (defaultValue as BoilerGaugesValue)?.gasRateKw || undefined,
                            burnerPressureMb:
                                (defaultValue as BoilerGaugesValue)?.burnerPressureMb || undefined,
                        }}
                    />
                );
            case QuestionType.COMPLETE:
                return <Complete title={question} extraCopy={config.completeCopy} />;
            case QuestionType.FOLLOW_ON_REQUIRED:
                return (
                    <FollowOnScheduled
                        title={question}
                        extraCopy={config.completeCopy}
                        formState={formState}
                    />
                );
            case QuestionType.CHECKBOX:
                return (
                    <CheckboxQuestion
                        title={question}
                        options={options}
                        config={config}
                        onSubmit={handleOnSubmit}
                        formState={formState}
                        defaultValue={defaultValue as string[] | undefined}
                    />
                );
            case QuestionType.RADIO:
                return (
                    <RadioQuestion
                        title={question}
                        options={options}
                        config={config}
                        onSubmit={handleOnSubmit}
                        formState={formState}
                    />
                );
            case QuestionType.AUTHORISED:
                return (
                    <Authorised
                        title={question}
                        extraCopy={config.completeCopy}
                        options={options}
                        onSubmit={handleOnSubmit}
                    />
                );
            case QuestionType.COST_AUTHORISATION:
                return (
                    <CostAuthorisation
                        title={question}
                        options={options}
                        onSubmit={handleOnSubmit}
                        jobDetails={jobDetails}
                        formState={formState}
                    />
                );
            case QuestionType.CONFIRM_ESTIMATED_COST_OF_PARTS:
                return (
                    <ConfirmEstimatedCostOfPartsQuestion
                        title={question}
                        options={options}
                        onSubmit={onSubmit}
                        formState={formState}
                        jobDetails={jobDetails}
                    />
                );
            case QuestionType.INVESTIGATION_REQUIRED:
                return <InvestigationRequired title={question} extraCopy={config.completeCopy} />;
            case QuestionType.PARTS_NEEDED:
                return (
                    <PartsNeeded
                        title={question}
                        options={options}
                        onSubmit={handleOnSubmit}
                        formState={formState}
                        jobDetails={jobDetails}
                    />
                );
            case QuestionType.NEW_ACTUAL_COST_OF_PARTS:
                return (
                    <NewActualCostOfPartsQuestion
                        title={question}
                        options={options}
                        onSubmit={handleOnSubmit}
                        formState={formState}
                        jobDetails={jobDetails}
                        defaultValue={defaultValue as Part[]}
                    />
                );
            case QuestionType.NEW_CONFIRM_ESTIMATED_COST_OF_PARTS:
                return (
                    <ConfirmPartsNeeded
                        title={question}
                        options={options}
                        onSubmit={onSubmit}
                        formState={formState}
                        jobDetails={jobDetails}
                    />
                );
            case QuestionType.APPLIANCE_DETAILS:
                return (
                    <ApplianceDetailsQuestions
                        title={question}
                        options={options}
                        onSubmit={handleOnSubmit}
                        formState={formState}
                        defaultValue={{
                            applianceMake:
                                (defaultValue as ApplianceDetailsValue)?.applianceMake || '',
                            applianceModel:
                                (defaultValue as ApplianceDetailsValue)?.applianceModel || '',
                            applianceSerialNumber:
                                (defaultValue as ApplianceDetailsValue)?.applianceSerialNumber ||
                                '',
                        }}
                    />
                );
            default:
                return UnreachableError(type);
        }
    };

    return (
        <>
            <div key={question} className={style.container}>
                <section>
                    {showBackButton && (
                        <Button
                            ariaLabel="back-button"
                            type="button"
                            onClick={onBack}
                            customStyle={style.backButton}
                        >
                            <Back />
                        </Button>
                    )}
                </section>
                {renderQuestionComponent()}
            </div>
        </>
    );
};

export default CurrentQuestion;
