import { ErrorObject } from 'ajv';

export const ajvErrorFormatter = (er: ErrorObject[] | null | undefined): string[] => {
    if (!er) return [];

    const a = er.map((item) => {
        return `\n${item.schemaPath} - ${item.message}`;
    });
    return a;
};
