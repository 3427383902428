import { useNavigate } from 'react-router-dom';
import { JobDetails } from 'src/types/Job';
import isSameDay from 'date-fns/isSameDay';
// import { Routes as RoutePath } from '../../../../types/Routes';
import { ExpandArrow } from 'src/images';
import Button from 'src/components/Button';
import style from './index.module.scss';
import DateHeader from '../../DateHeader';
import { Routes as RoutePath } from '../../../../../types/Routes';

type Props = {
    jobs: JobDetails[];
};

const calculateDateHeader = (currentJob: JobDetails, previousJob: JobDetails) => {
    if (!currentJob.start || !previousJob.start) {
        return <></>;
    }

    const currentJobDate = new Date(currentJob.start);
    const previousJobDate = new Date(previousJob.start);

    if (isSameDay(currentJobDate, previousJobDate)) {
        return <></>;
    }

    return (
        <div className={style.dateHeader}>
            <DateHeader key={currentJobDate.toUTCString()} date={currentJobDate} />
        </div>
    );
};

const JobList = ({ jobs }: Props) => {
    const navigate = useNavigate();

    const jobCards = jobs.map((job, i, arr) => {
        let dateHeader = <></>;

        if (i === 0 && job.start && job.sk_estimated_start) {
            const newDate = new Date(job.start || job.sk_estimated_start);
            dateHeader = (
                <div className={style.dateHeader}>
                    <DateHeader key={newDate.toUTCString()} date={newDate} />
                </div>
            );
        }

        if (i > 0) {
            dateHeader = calculateDateHeader(job, arr[i - 1]);
        }

        return (
            <>
                {dateHeader}

                <Button
                    type="button"
                    ariaLabel={`View job details for job in ${job.postcode}`}
                    customStyle={style.jobAgendaRow}
                    onClick={() => navigate(RoutePath.JOB_DETAILS.replace(':jobId', job.sk_job_id))}
                >
                    <span>{job.postcode}</span>
                    <div>
                        <span>{job.sk_job_name}</span>
                        <ExpandArrow />
                    </div>
                </Button>
            </>
        );
    });

    return (
        <section className={style.jobAgenda}>
            <div className={style.jobAgendaList} data-testid="job-list">
                {jobCards.length > 0 ? (
                    jobCards
                ) : (
                    <>
                        <span className={style.noJobs}>No previous jobs</span>
                    </>
                )}
            </div>
        </section>
    );
};

export default JobList;
