import { appliancesTypes } from 'src/types/Appliances';

export const jobTypeMap = {
    boiler: 'Gas Job',
    heating: 'Gas Job',
    electric: 'Electric',
    plumbing: 'Plumbing',
    drainage: 'Drains',
    windows: 'Windows',
    locks: 'Locks',
    pests: 'Pests',
    doors: 'Doors',
    appliances: [
        'Dishwasher',
        'Hoover',
        'American Style Fridge Freezer',
        'Blender',
        'Coffee Machine',
        'Cooker',
        'Extractor Fan',
        'Freezer',
        'Fridge',
        'Fridge Freezer',
        'Gadget',
        'Hob',
        'Microwave',
        'Oven',
        'Tumble Dryer',
        'TV',
        'Washer Dryer',
        'Washing Machine',
        'Wine Cooler',
        'Multiappliances',
        'Steam Iron',
        'Tumble Dryer Electric',
        'Hob Electric',
        'Oven Electric',
        'Cooker Electric',
        'Tumble Dryer Gas',
        'Hob Gas',
        'Oven Gas',
        'Cooker Gas',
        'Cooker Dual',
        'Satellite Box',
        'Vacuum Cleaner',
        'Extractor Hood',
    ],
    ...appliancesTypes,
};
