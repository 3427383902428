import { useState } from 'react';
import toast from 'react-hot-toast';
import Button from 'src/components/Button';
import Dropdown from 'src/components/Dropdown';
import FileUpload from 'src/components/FileUpload';
import { JobDetails as JobDetailsResponse } from 'src/types/Job';
import { abbreviateJobSummary } from 'src/utils/jobs';
import toastConfig from 'src/utils/toastConfig';
import { cancelJob } from '../../api/cancelJob';
import style from './index.module.scss';

const cancelReasonOptions = [
    { value: '', copy: 'Select reason for cancelling', disabled: true },
    { value: 'Cancelled by customer', copy: 'Cancelled by customer' },
    { value: 'Customer not home', copy: 'Customer not home' },
    { value: 'Engineer illness', copy: 'Engineer illness' },
    { value: 'Personal emergency', copy: 'Personal emergency' },
    { value: 'Other', copy: 'Other' },
];

interface Props {
    job: JobDetailsResponse;
    setShowCancelForm: (show: boolean) => void;
    setIsJobCardHiding: (show: boolean) => void;
}

const CancelForm = ({ job, setShowCancelForm, setIsJobCardHiding }: Props) => {
    const [cancelData, setCancelData] = useState({
        reason: cancelReasonOptions[0].value,
        reasonOther: null,
        files: [],
    });
    const [cancelFileUrls, setCancelFileUrls] = useState([]);
    const [isCancelling, setIsCancelling] = useState(false);
    const [errors, setErrors] = useState<Record<string, string | null>>({});

    const validateForm = () => {
        const newErrors: Record<string, string> = {};

        if (!cancelData.reason) {
            newErrors.reason = 'Please select a reason';
        }

        if (cancelData.reason === 'Other' && !cancelData.reasonOther) {
            newErrors.reasonOther = 'Please enter a specific reason for cancelling.';
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const onChange = (name) => (event) => {
        if (cancelData.reason !== 'Other') {
            setErrors({ ...errors, reason: null, reasonOther: null });
        }

        setCancelData({ ...cancelData, [name]: event.target.value });
    };

    const onCancelJobButtonClick = async (e) => {
        try {
            setIsCancelling(true);

            if (!validateForm()) {
                setIsCancelling(false);
                return;
            }
            const data = { ...cancelData, files: cancelFileUrls };
            await cancelJob(job.sk_job_id, data);

            toast.success(
                `Job "${abbreviateJobSummary(job.summary)}" has been cancelled`,
                toastConfig
            );

            setIsJobCardHiding(true);
            setIsCancelling(false);
        } catch (error) {
            toast.error(
                `We were unable to cancel your job. Please try again later or contact us.`,
                toastConfig
            );
        }

        e.target.innerHTML = 'Confirm';
    };

    const isCustomerNotHomeReason = cancelData.reason === 'Customer not home';

    return (
        <div className={`${style.container} ${style.cancelContainer}`}>
            <h3>Select reason for cancellation</h3>
            <Dropdown
                options={cancelReasonOptions}
                currentValue={cancelData.reason}
                onChange={onChange('reason')}
            />
            {errors.reason && <div className="fnolform-error">{errors.reason}</div>}
            {isCustomerNotHomeReason && (
                <FileUpload
                    title="Add picture of visit:"
                    jobId={job.sk_job_id}
                    setParentComponentFileUrls={setCancelFileUrls}
                />
            )}

            {cancelData.reason === 'Other' && (
                <div className={style.otherReason}>
                    <label htmlFor="other-reason">Give more details</label>
                    <textarea
                        id="other-reason"
                        name="cancellingReasonOther"
                        value={cancelData.reasonOther || ''}
                        rows={4}
                        cols={20}
                        className={style.textArea}
                        onChange={onChange('reasonOther')}
                    />

                    {errors.reasonOther && (
                        <div className="fnolform-error">{errors.reasonOther}</div>
                    )}
                </div>
            )}

            <h5>Are you sure you want to cancel?</h5>
            <div className={style.actionsContainer}>
                <Button
                    onClick={() => {
                        setShowCancelForm(false);
                    }}
                    variant="secondary"
                >
                    Back
                </Button>

                <Button
                    onClick={onCancelJobButtonClick}
                    isDisabled={
                        isCancelling || (isCustomerNotHomeReason && cancelFileUrls.length === 0)
                    }
                >
                    {isCancelling ? 'Cancelling...' : 'Confirm'}
                </Button>
            </div>
        </div>
    );
};

export default CancelForm;
