import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import { JOBS_PUBLIC_ENDPOINT } from 'src/constants';
import { getAuthenticatedUser, getTokenFromAuthenticatedUser } from 'src/features/login';
import { captureSentryError } from 'src/features/login/utils/captureSentryError';
import { getMandatoryEnvVar } from 'src/utils/env';
import {
    isJobOffersErrorResponse,
    JobOffersResponseSchema,
    JobOffersSuccessResponse,
} from './getJobOffers.types';

export const getJobOffers = async (): Promise<JobOffersSuccessResponse> => {
    const user = await getAuthenticatedUser();
    const token = getTokenFromAuthenticatedUser(user);
    // getMandatoryEnvVar must live at this level in order for tests to successfully overwrite the process.env variable
    const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    };

    try {
        const response = await fetch(
            `${API_BASE_URL}/${JOBS_PUBLIC_ENDPOINT}/v1/job-offers`,
            requestOptions
        );
        const data = await response.json();

        if (!response.ok) {
            const error = (data && data.message) || response.status;
            throw new Error(error);
        }

        const ajv = new Ajv();
        addFormats(ajv); // add formats for date-time format
        const validate = ajv.compile(JobOffersResponseSchema);

        const valid = validate(data);
        if (valid) {
            if (isJobOffersErrorResponse(data)) {
                throw new Error(`Error message from api: ${data.message}`);
            }
            return data;
        }

        throw new Error(
            `Failed to validate job offers response. Error in ${JSON.stringify(
                validate.errors?.[0].schemaPath
            )} - ${JSON.stringify(validate.errors?.[0].params)}`
        );
    } catch (error) {
        await captureSentryError(error, { feature: 'getJobOffers' });
        throw new Error(`Failed to get job offers: ${error.message}`);
    }
};
