import { ChangeEvent } from 'react';
import style from './index.module.scss';

export interface Props {
    value: string;
    onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    onFocus?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    placeholder?: string;
    size?: keyof typeof sizes;
    customStyle?: string;
    name?: string;
    id?: string;
    autoFocus?: boolean;
}

// Not sure how useful giving preset sizes is vs exposing rows
// and cols as props for the component
const sizes = {
    small: { rows: 4, cols: 10 },
    medium: { rows: 6, cols: 20 },
    large: { rows: 8, cols: 30 },
};

const TextArea = ({
    value,
    name,
    onChange,
    onFocus,
    id,
    placeholder = '',
    size = 'small',
    customStyle = '',
    autoFocus = false,
}: Props) => {
    const { rows, cols } = sizes[size];

    return (
        <textarea
            className={`${style.textArea} ${customStyle}`}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            rows={rows}
            cols={cols}
            name={name}
            id={id}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={autoFocus}
            onFocus={onFocus}
        />
    );
};

export default TextArea;
