import { ErrorMessage } from 'src/utils/copy';
import { format, sub } from 'date-fns';
import style from './index.module.scss';
import { useGetJobAgenda } from '../../hooks/useGetJobAgenda';
import Loader from '../../../../components/Loader';
import ErrorBar from '../../../../components/ErrorBar';
import JobList from '../JobList';

const calculateDateRange = () => {
    const today = new Date();

    const upperLimit = format(today, 'yyyy-MM-dd');
    const lowerLimit = format(sub(today, { days: 30 }), 'yyyy-MM-dd');

    return { upperLimit, lowerLimit };
};

const AgendaDay = () => {
    const { upperLimit, lowerLimit } = calculateDateRange();
    const { isError, isLoading, data } = useGetJobAgenda('jobAgendaDay', lowerLimit, upperLimit);

    return (
        <section className={style.jobAgenda}>
            {isLoading && <Loader />}

            {isError && <ErrorBar message={ErrorMessage.ENGINEER} />}

            {!isLoading && !isError && data && <JobList jobs={data?.jobs} />}
        </section>
    );
};

export default AgendaDay;
