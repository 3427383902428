import { JOBS_PUBLIC_ENDPOINT } from 'src/constants';
import { getMandatoryEnvVar } from 'src/utils/env';
import {
    getAuthenticatedUser,
    getTokenFromAuthenticatedUser,
} from '../../login/utils/authenticatedUser';
import { FollowOnRequestBody } from '../types/FollowOnRequest';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

export const postFollowOn = async (data: FollowOnRequestBody) => {
    const user = await getAuthenticatedUser();
    const token = getTokenFromAuthenticatedUser(user);

    try {
        const response = await fetch(
            `${API_BASE_URL}/${JOBS_PUBLIC_ENDPOINT}/v1/jobs/newFollowOn`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(data),
            }
        );

        // eslint-disable-next-line @typescript-eslint/return-await
        return response.json();
    } catch (err) {
        throw new Error(`Failed to create follow on job - ${err.message}`);
    }
};
