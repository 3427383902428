import { useQuery, UseQueryOptions, QueryKey } from 'react-query';
import { JobDetails } from 'src/types/Job';
import { getJobDetails } from '../api/getJobDetails';

export const useJobDetails = (
    jobId?: string,
    options?: UseQueryOptions<JobDetails, Error, JobDetails, QueryKey>
) => {
    return useQuery<JobDetails, Error>(['jobDetails', jobId], getJobDetails, options);
};
