const ConfirmationTime = ({ time }) =>
    time.sort().map((timeString) => {
        switch (timeString) {
            case '08:00:00':
                return (
                    <div key={`morning-${timeString}`}>
                        <div className="sun halfsun" />
                        <p>
                            Morning
                            <br />
                            08:00 - 12:00
                        </p>
                    </div>
                );
            case '12:00:00':
                return (
                    <div key={`midday-${timeString}`}>
                        <div className="sun fullsun" />
                        <p>
                            Midday
                            <br />
                            12:00 - 16:00
                        </p>
                    </div>
                );
            case '16:00:00':
                return (
                    <div key={`evening-${timeString}`}>
                        <div className="sun halfsun" />
                        <p>
                            Evening
                            <br />
                            16:00 - 20:00
                        </p>
                    </div>
                );
            default:
                break;
        }

        return false;
    });

export default ConfirmationTime;
