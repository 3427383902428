import { useMemo, useState } from 'react';
import Dropdown, { Option as DropdownOption } from 'src/components/Dropdown';
import { EstimatedCostOfPartsValue, FormState, Part } from 'src/features/closeJob/types/Form';
import { JobDetails } from 'src/types/Job';
import Button from 'src/components/Button';
import { Option } from '../../../types/Question';

import style from './index.module.scss';
import PartsAdded from '../PartsNeeded/PartsAdded';
import PartEditModal from '../PartsNeeded/PartEditModal';
import PartAddModal from '../PartsNeeded/PartAddModal';

export interface Props {
    title: string;
    options: Option[];
    onSubmit: (value: EstimatedCostOfPartsValue, nextQuestion?: string) => void;
    formState: Partial<FormState>;
    jobDetails: JobDetails;
}

const placeholder: DropdownOption = { copy: '', value: 0 };

const ConfirmPartsNeeded = ({ title, options, onSubmit, formState, jobDetails }: Props) => {
    // Always get the engineer to reselect hours needed
    const [estimatedHoursNeeded, setEstimatedHoursNeeded] = useState(0);
    const [partsRequested, setPartsRequested] = useState<Part[]>(formState.partsRequested || []);
    const [showModal, setShowModal] = useState(false);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editPart, setEditPart] = useState<Part | undefined>(undefined);

    const isVatRegistered = useMemo(() => !!jobDetails.engineer_vat_number, [jobDetails]);

    const onEdit = (part: Part) => () => {
        setEditPart({ ...part });
        setShowEditModal(true);
    };

    if (showEditModal) {
        return (
            <PartEditModal
                setShowModal={setShowEditModal}
                setParts={setPartsRequested}
                parts={partsRequested}
                initialPartData={editPart}
            />
        );
    }

    if (showModal) {
        return (
            <PartAddModal
                setShowModal={setShowModal}
                setParts={setPartsRequested}
                parts={partsRequested}
            />
        );
    }

    return (
        <>
            <span className={style.title}>{title}</span>
            {partsRequested && partsRequested.length > 0 && (
                <PartsAdded
                    parts={partsRequested}
                    setParts={setPartsRequested}
                    isVatRegistered={isVatRegistered}
                    onEdit={onEdit}
                />
            )}
            <Button type="button" customStyle={style.addPart} onClick={() => setShowModal(true)}>
                <p>+ Add part to fit</p>
            </Button>
            <label htmlFor="estimatedHoursNeededInput" className={style.hoursNeededLabel}>
                <span>Time needed for follow on</span>
                <Dropdown
                    id="estimatedHoursNeededInput"
                    options={[
                        placeholder,
                        { value: 0.5, copy: '0.5 Hour' },
                        { value: 1, copy: '1 Hour' },
                        { value: 1.5, copy: '1.5 Hours' },
                        { value: 2, copy: '2 Hours' },
                        { value: 2.5, copy: '2.5 Hours' },
                        { value: 3, copy: '3 Hours' },
                        { value: 3.5, copy: '3.5 Hours' },
                        { value: 4, copy: '4 Hours' },
                        { value: 4.5, copy: '4.5 Hours' },
                        { value: 5, copy: '5 Hours' },
                    ]}
                    currentValue={estimatedHoursNeeded || ''}
                    onChange={(e) => setEstimatedHoursNeeded(+e.target.value)}
                    name="estimatedHoursNeeded"
                />
            </label>
            <div className={style.checkboxContainer}>
                <input
                    className={style.checkbox}
                    type="checkbox"
                    id="confirm-authorisation"
                    checked={isCheckboxChecked}
                    onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                />
                <label htmlFor="confirm-authorisation">
                    I confirm I have received authorisation for the above costs
                </label>
            </div>
            {options.map(({ value, nextQuestion, nextQuestionSelector }) => {
                return (
                    <Button
                        isDisabled={
                            !partsRequested ||
                            partsRequested.length === 0 ||
                            !estimatedHoursNeeded ||
                            !isCheckboxChecked
                        }
                        customStyle={style.mainButton}
                        key={value}
                        type="button"
                        onClick={() => {
                            const totalCost = partsRequested.reduce(
                                (accumulator, item) => accumulator + (item.estimatedCost || 0),
                                0
                            );
                            const submittedValue = {
                                estimatedHoursNeeded,
                                estimatedPartsCost: Math.ceil(totalCost * 100) / 100,
                                partsDescription: partsRequested
                                    .map(({ name, quantity }) => `${quantity}x ${name}`)
                                    .join(', '),
                                partsRequested,
                            };
                            onSubmit(
                                submittedValue,
                                nextQuestionSelector
                                    ? nextQuestionSelector(submittedValue, formState)
                                    : nextQuestion
                            );
                        }}
                    >
                        {value}
                    </Button>
                );
            })}
        </>
    );
};

export default ConfirmPartsNeeded;
