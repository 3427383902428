import { INTEGRATION_ENDPOINT } from 'src/constants';
import { getAuthenticatedUser, getTokenFromAuthenticatedUser } from 'src/features/login';
import { getMandatoryEnvVar } from 'src/utils/env';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

export const getSkeduloJobs = async (customerFacingId) => {
    try {
        const user = await getAuthenticatedUser();
        const token = getTokenFromAuthenticatedUser(user);
        const response = await fetch(
            `${API_BASE_URL}/${INTEGRATION_ENDPOINT}/skedulo/${customerFacingId}/jobs`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return await response.json();
    } catch (err) {
        const errorMessage = `Failed to get skedulo jobs - ${err.message}`;
        // eslint-disable-next-line no-console
        console.error(errorMessage);
        throw new Error(errorMessage);
    }
};
