import { useNavigate, useLocation } from 'react-router-dom';
import { JobDetails } from 'src/types/Job';
import isSameDay from 'date-fns/isSameDay';
import JobCard from '../JobCard';
import { Routes as RoutePath } from '../../../../types/Routes';
import style from './index.module.scss';
import { startJob } from '../../api/startJob';
import { startTravel } from '../../api/startTravel';
import DateHeader from '../DateHeader';

type Props = {
    jobs: JobDetails[];
};

const calculateDateHeader = (currentJob: JobDetails, previousJob: JobDetails) => {
    if (!currentJob.start || !previousJob.start) {
        return <></>;
    }

    const currentJobDate = new Date(currentJob.start);
    const previousJobDate = new Date(previousJob.start);

    if (isSameDay(currentJobDate, previousJobDate)) {
        return <></>;
    }

    return <DateHeader key={currentJobDate.toUTCString()} date={currentJobDate} />;
};

const JobList = ({ jobs }: Props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const jobCards = jobs
        .filter((job) => job.sk_job_id !== location.state?.hiddenJob)
        .map((job, i, arr) => {
            let dateHeader = <></>;

            if (i === 0 && job.start) {
                const newDate = new Date(job.start);
                dateHeader = <DateHeader key={newDate.toUTCString()} date={newDate} />;
            }

            if (i > 0) {
                dateHeader = calculateDateHeader(job, arr[i - 1]);
            }

            return (
                <div key={job.sk_job_id}>
                    {dateHeader}
                    <JobCard
                        job={job}
                        onStartTravel={async () => {
                            await startTravel(job.sk_job_id);
                        }}
                        onStart={async () => {
                            await startJob(job.sk_job_id);
                        }}
                        onClose={() =>
                            navigate(RoutePath.CLOSE_JOB.replace(':jobId', job.sk_job_id))
                        }
                        onReschedule={() =>
                            navigate(RoutePath.RESCHEDULE_JOB.replace(':jobId', job.sk_job_id))
                        }
                    />
                </div>
            );
        });

    return (
        <section className={style.jobAgenda}>
            <div className={style.jobAgendaList} data-testid="job-list">
                {jobCards.length > 0 ? (
                    jobCards
                ) : (
                    <>
                        <span className={style.noJobs}>No jobs in agenda</span>
                    </>
                )}
            </div>
        </section>
    );
};

export default JobList;
