export enum ApplianceCategory {
    COOKING = 'Cooking',
    LAUNDRY = 'Laundry',
    REFRIGERATION = 'Refrigeration',
    OTHERS = 'Others',
}

export interface Appliances {
    id: number;
    contractId: number;
    pricePerMonthGbx: number;
    type: string;
    category: ApplianceCategory;
    make: string | null;
    purchasePriceGbx: number | null;
    purchaseRetailer: string;
    purchaseDate: string;
    modelNumber: string;
    dateCreated: string;
    dateModified: string;
    author: string;
}

export const appliancesTypes = {
    Dishwasher: 'Dishwasher',
    Hoover: 'Hoover',
    'American Style Fridge Freezer': 'American Style Fridge Freezer',
    Blender: 'Blender',
    'Coffee Machine': 'Coffee Machine',
    Cooker: 'Cooker',
    'Extractor Fan': 'Extractor Fan',
    Freezer: 'Freezer',
    Fridge: 'Fridge',
    'Fridge Freezer': 'Fridge Freezer',
    Gadget: 'Gadget',
    Hob: 'Hob',
    Microwave: 'Microwave',
    TV: 'TV',
    'Washer Dryer': 'Washer Dryer',
    'Washing Machine': 'Washing Machine',
    'Wine Cooler': 'Wine Cooler',
    Multiappliances: 'Multiappliances',
    'Steam Iron': 'Steam Iron',
    'Tumble Dryer Electric': 'Tumble Dryer Electric',
    'Hob Electric': 'Hob Electric',
    'Oven Electric': 'Oven Electric',
    'Cooker Electric': 'Cooker Electric',
    'Tumble Dryer Gas': 'Tumble Dryer Gas',
    'Hob Gas': 'Hob Gas',
    'Oven Gas': 'Oven Gas',
    'Cooker Gas': 'Cooker Gas',
    'Cooker Dual': 'Cooker Dual',
    'Satellite Box': 'Satellite Box',
    'Vacuum Cleaner': 'Vacuum Cleaner',
    'Extractor Hood': 'Extractor Hood',
};
