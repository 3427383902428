import style from './index.module.scss';

const Carousel = ({ children }) => {
    return (
        <div className={style.carouselContainer}>
            <div className={style.carouselContent}>{children}</div>
        </div>
    );
};

export default Carousel;
