import { useEffect, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';
import { captureSentryError, captureSentryInfo } from 'src/features/login/utils/captureSentryError';
import { getMandatoryEnvVar } from 'src/utils/env';
import { errorToJson } from 'src/utils/error';

import imageCompression from 'browser-image-compression';
import { ALLOWED_JOB_SHEET_IMAGE_FILE_TYPES, JOBS_PUBLIC_ENDPOINT } from 'src/constants';
import { generateAuthHeader } from 'src/utils/auth';
import { post, put } from 'src/utils/http';
import { OnSubmitValue, Option } from '../../features/closeJob/types/Question';
import { AddPhotos, ErrorState, UploadSuccessful, UploadingFiles } from './Components';
import { FileUploadResponse, fileUploadResponseSchema } from './fileUpload.types';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

interface FileUploadProps {
    title: string;
    options?: Option[];
    onSubmit?: (value?: OnSubmitValue, nextQuestion?: string) => void;
    jobId?: string;
    setParentComponentFileUrls?: any;
    isCloseJob?: boolean;
}

const FileUpload = ({
    title,
    options,
    onSubmit,
    jobId,
    setParentComponentFileUrls,
    isCloseJob = false,
}: FileUploadProps) => {
    const { jobId: paramsJobId } = useParams();

    const [files, setFiles] = useState<File[]>([]);
    const [isUploadingFiles, setIsUploadingFiles] = useState<boolean>(false);
    const [uploadSuccessful, setUploadSuccessful] = useState(false);
    const [uploadingText, setUploadingText] = useState<string>('');
    const [progress, setProgress] = useState(0);
    const [fileUrls, setFileUrls] = useState<string[]>([]);
    const [isError, setIsError] = useState(false);
    const [filesWithError, setFilesWithError] = useState<File[]>([]);

    const uploadJobId = paramsJobId || jobId;

    useEffect(() => {
        let timer1;
        let timer2;
        if (isUploadingFiles) {
            document.documentElement.style?.setProperty(
                '--progress-width',
                `${(progress / files.length) * 100}%`
            );
            timer1 = setTimeout(() => {
                setUploadingText(
                    "Sit tight, your images are uploading. If it's taking a while, try moving to a location with better internet."
                );
                captureSentryInfo('Images are taking 30 secs to upload', {
                    jobId: uploadJobId || 'no jobId available',
                });
            }, 30000);
            timer2 = setTimeout(() => {
                setUploadingText(
                    "We're still uploading which is taking quite a while but we are almost there! This is due to a slow internet connection."
                );
                captureSentryInfo('Images are taking 120 secs to upload', {
                    jobId: uploadJobId || 'no jobId available',
                });
            }, 120000);
        }
        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
        };
    }, [isUploadingFiles, files, progress, uploadJobId]);

    const fileInputEl = useRef<HTMLInputElement>(null);

    const uploadFile = async (fileToUpload: File): Promise<string | null> => {
        const uploadUrl = `${API_BASE_URL}/${JOBS_PUBLIC_ENDPOINT}/v1/engineer-jobs/${uploadJobId}/photos`;
        const startProgress = 0.3;

        setProgress(startProgress);

        let response: FileUploadResponse;

        try {
            response = await post<string, FileUploadResponse>({
                url: uploadUrl,
                headers: {
                    ...(await generateAuthHeader()),
                },
                body: JSON.stringify({ filename: fileToUpload.name }),
                responseValidationSchema: fileUploadResponseSchema,
            });
        } catch (error) {
            const newError = new Error('Error generating signed URL for file');
            setIsError(true);
            await captureSentryError(newError, {
                message: `Error generating signed URL for file`,
                jobId: uploadJobId || 'job Id not found',
                errorStringified: JSON.stringify(errorToJson(error)),
            });
            return null;
        }

        try {
            await put<File, void>({
                url: response.signedPutObjectUrl,
                headers: { 'Content-Type': fileToUpload.type },
                body: fileToUpload,
                callbacks: {
                    onUploadProgress: ({ loaded, total }) => {
                        if (loaded === total) {
                            setProgress((prev) => prev + (1 - startProgress));
                        }
                    },
                },
            });

            return response.s3Url;
        } catch (error) {
            const newError = new Error('Error uploading file');
            setIsError(true);
            await captureSentryError(newError, {
                message: `Error uploading file to S3 for job`,
                jobId: uploadJobId || 'Job Id not found',
                errorStringified: JSON.stringify(errorToJson(error)),
            });
            return null;
        }
    };

    const handleFileUpload = async () => {
        setIsUploadingFiles(true);

        const uploadedFileUrls: string[] = [];
        captureSentryInfo('Handling file upload', {
            jobId: uploadJobId || 'no jobId available',
            files: JSON.stringify(files) || 'no files selected',
            numberOfFiles: files.length.toString(),
        });
        if (files) {
            const isValidTypes = files.filter(
                ({ type }) => !ALLOWED_JOB_SHEET_IMAGE_FILE_TYPES.includes(type)
            );
            if (isValidTypes.length > 0) {
                setIsUploadingFiles(false);
                setFilesWithError(files);
            } else {
                captureSentryInfo('Uploading files', {
                    jobId: uploadJobId || 'no jobId available',
                    files: JSON.stringify(files),
                    numberOfFiles: files.length.toString(),
                });
                await Promise.all(
                    files.map(async (file) => {
                        try {
                            const compressedFile =
                                file.type !== 'application/pdf' && file.type !== 'image/heic'
                                    ? await imageCompression(file, {
                                          maxSizeMB: 0.5,
                                          maxWidthOrHeight: 1000,
                                      })
                                    : file;
                            captureSentryInfo('File processing during upload', {
                                jobId: uploadJobId || 'no jobId available',
                                fileName: file.name,
                                fileType: file.type,
                            });
                            const url = await uploadFile(compressedFile);

                            if (url) {
                                uploadedFileUrls.push(url);
                            }
                        } catch (error) {
                            captureSentryInfo('Error during file compression or upload', {
                                jobId: uploadJobId || 'no jobId available',
                                errorMessage: error.message,
                                fileName: file.name,
                                fileType: file.type,
                            });
                        }
                    })
                );

                setFileUrls(uploadedFileUrls);
                setIsUploadingFiles(false);
                if (setParentComponentFileUrls) {
                    setParentComponentFileUrls(uploadedFileUrls);
                }
                setUploadSuccessful(true);
            }
        }
    };

    const handleOnChooseFileClick = () => {
        if (fileInputEl !== null && fileInputEl.current !== null) {
            fileInputEl.current.value = '';
            fileInputEl.current.click();
        }
    };

    const handleFileInputChange = (e) => {
        if (e.target.files.length > 0) {
            const existingFiles = files || [];

            // Add (nth) to file name if file already exists.
            const newFiles: File[] = Array.from(e.target.files).map((file: File) => {
                const existingFile = existingFiles.find((f) => f.name === file.name);
                if (existingFile) {
                    const fileExtension = file.name.split('.').pop();
                    const fileName = file.name.replace(`.${fileExtension}`, '');
                    const newFileName = `${fileName}(${
                        existingFiles.filter((f) => f.name.includes(fileName)).length + 1
                    }).${fileExtension}`;
                    return new File([file], newFileName, { type: file.type });
                }
                return file;
            });
            setFiles([...existingFiles, ...newFiles]);
        }
    };

    const handleOnRemoveFileClick = (fileToRemove) => {
        const newFiles = files.filter((file) => file !== fileToRemove);
        setFiles(newFiles);
        setFilesWithError(
            newFiles.filter(({ type }) => !ALLOWED_JOB_SHEET_IMAGE_FILE_TYPES.includes(type))
        );
    };

    if (isError) {
        return <ErrorState files={files} handleFileUpload={handleFileUpload} />;
    }

    if (uploadSuccessful) {
        return (
            <UploadSuccessful
                fileUrls={fileUrls}
                files={files}
                options={options}
                onSubmit={onSubmit}
            />
        );
    }

    if (isUploadingFiles) {
        return <UploadingFiles uploadingText={uploadingText} progress={progress} files={files} />;
    }

    return (
        <AddPhotos
            title={title}
            fileInputEl={fileInputEl}
            handleFileInputChange={handleFileInputChange}
            handleOnChooseFileClick={handleOnChooseFileClick}
            files={files}
            isUploadingFiles={isUploadingFiles}
            handleFileUpload={handleFileUpload}
            handleOnRemoveFileClick={handleOnRemoveFileClick}
            isCloseJob={isCloseJob}
            isFileTypeError={filesWithError.length > 0}
        />
    );
};

export default FileUpload;
