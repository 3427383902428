import { ChangeEvent } from 'react';
import style from './index.module.scss';

export interface Option {
    value: string | number;
    copy: string;
    disabled?: boolean;
}

export interface Props {
    options: Option[];
    currentValue: string | number;
    onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
    id?: string;
    customStyle?: string;
    name?: string;
}

const Dropdown = ({ currentValue, onChange, options, id = '', customStyle = '', name }: Props) => {
    return (
        <select
            className={`${style.select} ${customStyle}`}
            id={id}
            value={currentValue}
            onChange={onChange}
            name={name}
        >
            {options.map(({ value, copy, disabled }) => {
                return (
                    <option key={value} value={value} disabled={disabled || false}>
                        {copy}
                    </option>
                );
            })}
        </select>
    );
};

export default Dropdown;
