type Props = {
    name: string;
    value: string;
    label: string[];
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    isChecked: boolean;
    disabled?: boolean;
};

const Checkbox = ({ name, value, label, onChange, isChecked, disabled }: Props) => {
    const inputId = `${name}-${value}`;
    const labelText = () => (
        <p className="toggleform-text">
            {label.map((labelValue) => (
                <span key={labelValue}>{labelValue}</span>
            ))}
        </p>
    );

    return (
        <div className="toggleform">
            <input
                type="checkbox"
                name={name}
                value={value}
                id={inputId}
                data-testid={inputId}
                onChange={onChange}
                checked={isChecked}
                disabled={disabled}
            />
            <label htmlFor={inputId} data-testid={`label-${inputId}`}>
                {labelText()}
                <div className="toggleform-box">
                    <div className="toggleform-box-interior" />
                </div>
            </label>
        </div>
    );
};

export default Checkbox;
