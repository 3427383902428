import { CustomerData } from 'src/types/CustomerData';
import { FormState } from '../types/Form';
import { QuestionStage, QuestionType } from '../types/Question';

export const appliancesQuestions = (_customerData: CustomerData): QuestionStage[] => {
    return [
        {
            handle: 'appliancesGroup1',
            questions: [
                {
                    name: 'jobType',
                    title: 'Your appliance cover',
                    description: 'Please select the faulty appliance',
                    type: QuestionType.CATEGORIES,
                    isRequired: true,
                    data: _customerData,
                },
            ],
            onSubmit: (values) => {
                const formState = {
                    jobType: values.jobType,
                    description: values.description,
                } as Partial<FormState>;

                return formState;
            },
            options: [
                {
                    copy: 'Back',
                    nextQuestion: 'problemAreaNew',
                    variant: 'outlined',
                },
                {
                    copy: 'Next',
                    isDisabled: (values) => !values.jobType || !values.description,
                    nextQuestion: 'appliancesGroup2',
                },
            ],
        },
        {
            handle: 'appliancesGroup2',
            questions: [
                {
                    name: 'applianceMaker',
                    title: 'Repair Details',
                    description: 'What is the make of your appliance?',
                    type: QuestionType.DROPDOWN_SEARCH,
                    isRequired: true,
                    config: {
                        placeholder: 'Search',
                    },
                },
                {
                    name: 'applianceModel',
                    description: 'What is the model of your appliance?',
                    type: QuestionType.INPUT_TEXT_NEW,
                    isRequired: true,
                    config: {
                        placeholder: 'Enter information here',
                    },
                },
                {
                    name: 'applianceFaultCode',
                    description: 'Is there any fault code on the appliance?',
                    type: QuestionType.INPUT_TEXT_NEW,
                    isRequired: false,
                    config: {
                        placeholder: 'Enter information here',
                    },
                },
                {
                    name: 'applianceSerialNumber',
                    description: 'What is the serial number of the appliance?',
                    type: QuestionType.INPUT_TEXT_NEW,
                    isRequired: false,
                    config: {
                        placeholder: 'Enter information here',
                    },
                },
                {
                    name: 'applianceAge',
                    description: 'How old is your appliance?',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            {
                                value: '1-6',
                                copy: '1-6 years old',
                            },
                            {
                                value: '7-10',
                                copy: '7-10 years old',
                            },
                            {
                                value: '10+',
                                copy: '10+ years old',
                            },
                        ],
                    },
                },
            ],
            onSubmit: (values) =>
                ({
                    applianceMaker: values.applianceMaker,
                    applianceModel: values.applianceModel,
                    applianceFaultCode: values.applianceFaultCode || '',
                    applianceSerialNumber: values.applianceSerialNumber || '',
                    applianceAge: values.applianceAge,
                } as Partial<FormState>),
            options: [
                {
                    copy: 'Next',
                    nextQuestion: 'vulnerability',
                    isDisabled: (values) =>
                        !values.applianceMaker || !values.applianceModel || !values.applianceAge,
                },
            ],
        },
    ];
};
