import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Layout from 'src/components/Layout';
import Loader from 'src/components/Loader';
import { ExpandArrow } from 'src/images';
import { Routes } from 'src/types/Routes';
import { newVirtualPageView } from 'src/utils/analytics';
import { mixpanelTrackPage } from 'src/utils/mixpanel';
import { useGetInvoices } from '../../hooks/useGetInvoices';
import InvoiceList from '../InvoiceList';
import styles from './index.module.scss';

const Invoices = () => {
    const pageCount = 0;
    const location = useLocation();
    const { isError, error, isLoading, data } = useGetInvoices();

    useEffect(() => {
        newVirtualPageView('engineer-account-invoices', location.pathname);
        mixpanelTrackPage();
    }, [pageCount, location]);

    if (isError || data instanceof Error) {
        // eslint-disable-next-line no-console
        console.error(error || (data instanceof Error && data.message) || 'Could not get data');
    }

    let content;
    if (isLoading) {
        content = <Loader />;
    } else if (isError || data instanceof Error) {
        content = <p>Something went wrong with this request.</p>;
    } else if (!data || !data.length) {
        content = <p>You don&apos;t have any invoices currently.</p>;
    } else {
        content = <InvoiceList invoices={data} />;
    }

    return (
        <Layout>
            <article className={styles.container}>
                <div className={styles.backButton}>
                    <Link to={Routes.ENGINEER_ACCOUNT}>
                        <ExpandArrow />
                    </Link>
                </div>
                <section className={styles.account_header}>
                    <h3>Invoices</h3>
                    {content}
                </section>
            </article>
        </Layout>
    );
};

export default Invoices;
