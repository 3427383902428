import { Shield } from 'src/images';
import { useNavigate, useParams } from 'react-router-dom';
import { Routes } from 'src/types/Routes';
import { useQueryClient } from 'react-query';
import Button from 'src/components/Button';
import style from './index.module.scss';

interface Props {
    title?: string;
    extraCopy?: string;
}
const InvestigationRequired = ({
    title = 'Hometree needs to review claim',
    extraCopy = undefined,
}: Props) => {
    const navigate = useNavigate();
    const { jobId } = useParams();
    const queryClient = useQueryClient();
    return (
        <>
            <Shield className={style.shield} />
            <h2 className={style.title}>{title}</h2>
            {extraCopy && (
                // eslint-disable-next-line react/no-danger
                <div className={style.extraCopy} dangerouslySetInnerHTML={{ __html: extraCopy }} />
            )}
            <Button
                onClick={() => {
                    queryClient.invalidateQueries(['jobDetails']);
                    navigate(Routes.JOB_AGENDA, { state: { hiddenJob: jobId } });
                }}
            >
                Back to agenda
            </Button>
        </>
    );
};

export default InvestigationRequired;
