import { JSONSchemaType } from 'ajv';
import { JobType } from 'src/types/Job';

enum Urgency {
    NORMAL = 'Normal',
    URGENT = 'Urgent',
    CRITICAL = 'Critical',
}

enum IsVulnerable {
    YES = 'Yes',
    NO = 'No',
}
export interface JobOfferRow {
    first_name: string;
    last_name: string;
    phone1_prefix?: string;
    phone1?: string;
    phone2_prefix?: string;
    phone2?: string;
    address_line_1?: string;
    address_line_2?: string;
    address_line_3?: string;
    postcode?: string;
    town?: string;
    engineer_uid: string;
    engineer_id: number;
    claim_status_id: number;
    claim_status_name: string;
    id: number;
    job_offer_id: string;
    resource_job_offer_id: string;
    job_offer_status: string;
    time_notified?: string | null;
    time_responded?: string | null;
    sk_job_id: string;
    summary: string;
    job_name: string;
    estimated_start: string;
    estimated_end: string;
    job_type: JobType | null;
    urgency?: Urgency | null;
    vulnerable_customer?: IsVulnerable | null;
    customer_availability?: string;
}

export type JobOffersSuccessResponse = { jobOffers: JobOfferRow[] };

export type JobOffersErrorResponse = { message: string };

export type JobOffersResponse = JobOffersSuccessResponse | JobOffersErrorResponse;

export const jobOfferSchema: JSONSchemaType<JobOfferRow> = {
    type: 'object',
    properties: {
        first_name: { type: 'string' },
        last_name: { type: 'string' },
        phone1_prefix: { type: 'string', nullable: true },
        phone1: { type: 'string', nullable: true },
        phone2_prefix: { type: 'string', nullable: true },
        phone2: { type: 'string', nullable: true },
        address_line_1: { type: 'string', nullable: true },
        address_line_2: { type: 'string', nullable: true },
        address_line_3: { type: 'string', nullable: true },
        postcode: { type: 'string', nullable: true },
        town: { type: 'string', nullable: true },
        engineer_id: { type: 'number' },
        engineer_uid: { type: 'string' },
        claim_status_id: { type: 'number' },
        claim_status_name: { type: 'string' },
        id: { type: 'number' },
        job_offer_id: { type: 'string' },
        resource_job_offer_id: { type: 'string' },
        job_offer_status: { type: 'string' },
        time_notified: { type: 'string', format: 'date-time', nullable: true },
        time_responded: { type: 'string', format: 'date-time', nullable: true },
        sk_job_id: { type: 'string' },
        summary: { type: 'string' },
        job_name: { type: 'string' },
        estimated_start: { type: 'string', format: 'date-time' },
        estimated_end: { type: 'string', format: 'date-time' },
        job_type: {
            type: 'string',
            enum: [...Object.values(JobType), null] as readonly JobType[],
        },
        vulnerable_customer: {
            type: 'string',
            enum: [...Object.values(IsVulnerable), null] as readonly IsVulnerable[],
            nullable: true,
        },
        urgency: {
            type: 'string',
            enum: [...Object.values(Urgency), null] as readonly Urgency[],

            nullable: true,
        },
        customer_availability: {
            type: 'string',
            nullable: true,
        },
    },
    required: [
        'first_name',
        'last_name',
        'engineer_uid',
        'engineer_id',
        'claim_status_id',
        'claim_status_name',
        'id',
        'job_offer_id',
        'resource_job_offer_id',
        'job_offer_status',
        'sk_job_id',
        'summary',
        'job_name',
        'estimated_start',
        'estimated_end',
        'job_type',
    ],
};

export const JobOffersSuccessResponseSchema: JSONSchemaType<JobOffersSuccessResponse> = {
    type: 'object',
    properties: {
        jobOffers: { type: 'array', items: jobOfferSchema },
    },
    required: ['jobOffers'],
};

export const JobOffersErrorResponseSchema: JSONSchemaType<JobOffersErrorResponse> = {
    type: 'object',
    properties: {
        message: { type: 'string' },
    },
    required: ['message'],
};

export const JobOffersResponseSchema: JSONSchemaType<JobOffersResponse> = {
    type: 'object',
    anyOf: [JobOffersSuccessResponseSchema, JobOffersErrorResponseSchema],
};

export const isJobOffersSuccessResponse = (input: any): input is JobOffersSuccessResponse => {
    return !!(input as JobOffersSuccessResponse)[0].id;
};

export const isJobOffersErrorResponse = (input: any): input is JobOffersErrorResponse => {
    return !!(input as JobOffersErrorResponse).message;
};
