import { JSONSchemaType } from 'ajv';
import { PandoraErrorResponse, pandoraErrorResponseSchema } from 'src/types/pandora';

export type PdfUrlResponse = PdfUrlSuccessResponse | PandoraErrorResponse;

export interface PdfUrlSuccessResponse {
    url: string;
}

export const getPdfUrlSuccessResponseSchema: JSONSchemaType<PdfUrlSuccessResponse> = {
    type: 'object',
    properties: {
        url: {
            type: 'string',
        },
    },
    required: ['url'],
};

export const getPdfUrlResponseSchema: JSONSchemaType<PdfUrlResponse> = {
    type: 'object',
    anyOf: [getPdfUrlSuccessResponseSchema, pandoraErrorResponseSchema],
};
