import { CheckboxTick } from 'src/images';
import styles from './index.module.scss';

interface Props {
    id: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean;
    name?: string;
    children: JSX.Element | string;
    classes?: string;
}

function Checkbox({ id, onChange, checked, name, classes, children }: Props) {
    return (
        <div className={styles.checkboxContainer}>
            <label>
                <div>
                    <input
                        type="checkbox"
                        name={name || ''}
                        onChange={onChange}
                        id={id}
                        checked={checked}
                        className={classes}
                    />
                    <span>
                        <CheckboxTick />
                    </span>
                </div>
                {children}
            </label>
        </div>
    );
}

export default Checkbox;
