// eslint-disable-next-line max-classes-per-file
import Ajv from 'ajv';
import { JOBS_PUBLIC_ENDPOINT } from 'src/constants';
import { isPandoraErrorResponse } from 'src/types/pandora';
import { getMandatoryEnvVar } from 'src/utils/env';
import { ajvErrorFormatter } from 'src/utils/types';
import { getJobResponseSchema, Job } from './getPandoraJob.types';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

export class AjvValidationError extends Error {
    constructor(message: string) {
        super(message);
        Object.setPrototypeOf(this, AjvValidationError.prototype);
    }
}

class PandoraApiError extends Error {
    constructor(message: string) {
        super(message);
        Object.setPrototypeOf(this, PandoraApiError.prototype);
    }
}

class FetchError extends Error {
    constructor(message: string) {
        super(message);
        Object.setPrototypeOf(this, FetchError.prototype);
    }
}

// TODO rename when Skedulo getjob call deprecated
export const getPandoraJob = async (
    token: string,
    jobId: string
): Promise<Job | AjvValidationError | PandoraApiError | FetchError> => {
    const url = `${API_BASE_URL}/${JOBS_PUBLIC_ENDPOINT}/v1/customer/jobs/${jobId}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });

        const data = await response.json();

        const ajv = new Ajv();
        const validate = ajv.compile(getJobResponseSchema);
        const valid = validate(data);

        if (!valid) {
            return new AjvValidationError(
                `Failed to validate getCustomerJob response. Error in ${ajvErrorFormatter(
                    validate.errors
                )}`
            );
        }

        if (isPandoraErrorResponse(data)) {
            return new PandoraApiError(`Error message from api: ${data.message}`);
        }

        return data;
    } catch (err) {
        const errorMessage = `Failed to get Pandora job - ${err.message}`;
        // eslint-disable-next-line no-console
        console.error(errorMessage, err);
        return new FetchError(errorMessage);
    }
};
