import { differenceInDays } from 'date-fns';

export const determineContributionAmount = ({
    isRenewal,
    contractStartDate,
    applianceAge = '',
}: {
    isRenewal?: boolean | null;
    contractStartDate: string;
    applianceAge?: string | null;
}): number => {
    const contractAge = differenceInDays(new Date(), new Date(contractStartDate));

    if (contractAge <= 45 && !isRenewal) return 25000;
    if (contractAge > 45 && applianceAge === '1-6') return 0;
    if (contractAge > 45 && (applianceAge === '7-10' || applianceAge === '10+')) return 4500;

    return 0;
};
