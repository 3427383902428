import { Button, Icon } from '@HometreeEngineering/component-library';
import { useCallback, useEffect, useState } from 'react';
import { CustomerData } from 'src/types/CustomerData';
import { FormState } from '../../types/Form';
import { mapIconsToAppliances } from '../../utils/mapIconsToAppliances';
import styles from './index.module.scss';

export interface Props {
    name: string;
    title: string;
    description: string;
    formState: Partial<FormState>;
    setState: (name: string, value: string | number | boolean) => void;
    error: string;
    data?: CustomerData;
}

const CategoriesQuestion = ({
    name,
    title,
    description,
    formState: _formState,
    setState,
    error,
    data,
}: Props) => {
    const [radioOptions, setRadioOptions] = useState<string[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
    const [selectedType, setSelectedType] = useState<string | null>(null);
    const [groupedData, setGroupedData] = useState<{ category: any; types: any }[]>();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const groupByCategory = useCallback((arr) => {
        const map = new Map();

        arr.forEach(({ category, type }) => {
            if (map.has(category)) {
                map.get(category).push(type);
            } else {
                map.set(category, [type]);
            }
        });
        return Array.from(map, ([category, types]) => ({ category, types }));
    }, []);

    const handleCategoryClick = (index: number) => {
        setRadioOptions(groupedData?.[index]?.types);
        setSelectedCategory(index);
    };

    useEffect(() => {
        if (data?.appliances) {
            const grouped = groupByCategory(data.appliances);

            if (selectedCategory === null) {
                setGroupedData(grouped);
                setSelectedCategory(0);
                setRadioOptions(grouped[0].types);
            }
        }
    }, [data?.appliances, selectedCategory, groupByCategory]);

    useEffect(() => {
        if (selectedCategory && groupedData) {
            setRadioOptions(groupedData[selectedCategory].types);
        }
    }, [selectedCategory, groupedData]);

    const onChange = (e) => {
        const currentValue = e.target.value;

        setState('description', currentValue);
    };

    const validationError = (
        <div className="fnolform-error" data-testid="validation-error" role="alert">
            {error}
        </div>
    );

    return (
        <>
            <legend className={styles.title}>{title}</legend>
            <legend className={styles.description}>{description}</legend>
            <div className={styles.categoryBtns}>
                {groupedData?.map((appliance, index) => (
                    <>
                        {' '}
                        <Button
                            onClick={() => handleCategoryClick(index)}
                            variant="secondary"
                            subVariant="change"
                            customStyle={`${styles.capitalised} ${
                                selectedCategory === index ? styles.selected : ''
                            }`}
                        >
                            {appliance.category}
                        </Button>
                    </>
                ))}
            </div>
            <div className={styles.inputAndError}>
                {radioOptions.map((option) => (
                    <div
                        className={`${styles.radioContainer} ${
                            selectedType ? styles.selected : ''
                        }`}
                    >
                        <div className={styles.radioOption} key={option}>
                            <Icon
                                icon={mapIconsToAppliances(option)}
                                size="l"
                                onClick={() => {
                                    setSelectedType(option);
                                    setState(name, option);
                                }}
                                variant="primary"
                                isSelected={selectedType === option || false}
                            />
                            <p className={styles.optionName}>{option}</p>
                        </div>
                    </div>
                ))}
            </div>
            {error && validationError}
            <div className={styles.descriptionContainer}>
                <p>
                    <strong>Please describe the issue</strong>
                </p>
                <p>Provide as much detail as possible</p>
                <textarea
                    className={styles.descriptionInput}
                    name="description"
                    rows={4}
                    onChange={onChange}
                    placeholder="Please detail when the issue began, where the problem is and if there are any accessibility factors we should be aware of..."
                />
            </div>
        </>
    );
};

export default CategoriesQuestion;
