import { USER_MODE_IDS } from '../constants';

export const getUserModeId = (userMode) => {
    if (!userMode || !USER_MODE_IDS[userMode]) return USER_MODE_IDS.customer;
    return USER_MODE_IDS[userMode];
};

export const getUserModeFromUrl = (locationSearch) => {
    const urlParams = new URLSearchParams(locationSearch);
    const userMode = urlParams.get('mode');

    if (!userMode) return 'customer';
    return userMode;
};

export const getClaimModeFromUrl = (locationSearch) => {
    const urlParams = new URLSearchParams(locationSearch);
    const userMode = urlParams.get('claim');

    if (!userMode) return '';
    return userMode;
};
