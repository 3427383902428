import { CustomerData } from 'src/types/CustomerData';
import { QuestionStage, QuestionType } from '../types/Question';
import { FormState } from '../types/Form';

export const locksQuestions = (_customerData: CustomerData): QuestionStage[] => {
    return [
        {
            handle: 'locksGroup1',
            questions: [
                {
                    name: 'affectedLockInMainHouse',
                    description:
                        'Is the affected lock part of the main house? (not a separate building or structure)',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            { copy: 'Yes', value: 'Yes' },
                            { copy: 'No', value: 'No' },
                        ],
                    },
                },
            ],
            onSubmit: (values) => {
                const formState = {
                    affectedLockInMainHouse: values.affectedLockInMainHouse,
                } as Partial<FormState>;

                if (values.affectedLockInMainHouse === 'No') {
                    formState.rejectProduct = 'a lock that is not part of the main house';
                }

                return formState;
            },
            options: [
                {
                    copy: 'Next',
                    nextQuestion: (values) => {
                        if (values.affectedLockInMainHouse === 'No') {
                            return 'optionNotCovered';
                        }

                        return 'locksGroup2';
                    },
                },
            ],
        },
        {
            handle: 'locksGroup2',
            questions: [
                {
                    name: 'lockedOutFromProperty',
                    description: 'Are you locked out from your property?',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            { copy: 'Yes', value: 'Yes' },
                            { copy: 'No', value: 'No' },
                        ],
                    },
                },
                {
                    name: 'lockOnExternalWindowOrDoor',
                    description: 'Is the lock on an external window/door?',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            { copy: 'Yes', value: 'Yes' },
                            { copy: 'No', value: 'No' },
                        ],
                    },
                },
            ],
            onSubmit: (values) => {
                const formState = {
                    lockedOutFromProperty: values.lockedOutFromProperty,
                    lockOnExternalWindowOrDoor: values.lockOnExternal,
                } as Partial<FormState>;

                if (
                    values.lockedOutFromProperty === 'No' &&
                    values.lockOnExternalWindowOrDoor === 'No'
                ) {
                    formState.rejectProduct = 'a lock that is not on an external window/door';
                }

                return formState;
            },
            options: [
                {
                    copy: 'Next',
                    nextQuestion: (values) => {
                        if (
                            values.lockedOutFromProperty === 'No' &&
                            values.lockOnExternalWindowOrDoor === 'No'
                        ) {
                            return 'optionNotCovered';
                        }

                        return 'vulnerability';
                    },
                },
            ],
        },
    ];
};
