import { HAS_SERVICE_ANSWER } from 'src/utils/jobs';
import { CustomerData } from 'src/types/CustomerData';
import { FormState } from '../types/Form';
import { QuestionType, QuestionStage } from '../types/Question';

export const drainsQuestions = (_customerData: CustomerData): QuestionStage[] => {
    return [
        {
            handle: 'drainageGroup1',
            questions: [
                {
                    name: 'drainsProblemType',
                    description: 'Do you have any of the following?',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            {
                                copy: 'Loss of all toilet and sink facilities',
                                value: 'Loss of all toilet and sink facilities',
                            },
                            {
                                copy: 'A serious water leak that requires me to turn off water supply',
                                value: 'A serious water leak that requires me to turn off water supply',
                            },
                            {
                                copy: HAS_SERVICE_ANSWER,
                                value: HAS_SERVICE_ANSWER,
                            },
                        ],
                    },
                },
                {
                    name: 'drainsProblemRelateTo',
                    description: 'Does your issue relate to:',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            {
                                copy: 'Blocked drainage, toilet, sink or shower',
                                value: 'Blocked drainage, toilet, sink or shower',
                            },
                            {
                                copy: 'Shared drainage systems',
                                value: 'Shared drainage systems',
                            },
                            {
                                copy: 'Cracked drain pipes',
                                value: 'Cracked drain pipes',
                            },
                            {
                                copy: 'Septic tanks, drainage pumps or cesspits',
                                value: 'Septic tanks, drainage pumps or cesspits',
                            },
                        ],
                    },
                },
            ],
            onSubmit: (values) => {
                const formState = {
                    drainsProblemRelateTo: values.drainsProblemRelateTo,
                } as Partial<FormState>;

                if (values.drainsProblemRelateTo === 'Shared drainage systems') {
                    formState.rejectProduct = 'shared drainage systems';
                }

                if (values.drainsProblemRelateTo === 'Cracked drain pipes') {
                    formState.rejectProduct = 'cracked drain pipes';
                }

                if (values.drainsProblemRelateTo === 'Septic tanks, drainage pumps or cesspits') {
                    formState.rejectProduct = 'Septic tanks, drainage pumps or cesspits';
                }

                formState.problemType = String(values.drainsProblemType);

                return formState;
            },
            options: [
                {
                    copy: 'Next',
                    nextQuestion: (values) => {
                        if (
                            values.drainsProblemType === HAS_SERVICE_ANSWER &&
                            _customerData.packageDetails &&
                            _customerData.packageDetails.onlyLossOfService === true &&
                            values.drainsProblemRelateTo ===
                                'Blocked drainage, toilet, sink or shower' &&
                            _customerData.packageDetails.requiresInsurerApproval === true
                        ) {
                            return 'lossOfServiceNotCoveredNoExtraCopy';
                        }
                        if (
                            values.drainsProblemType === HAS_SERVICE_ANSWER &&
                            _customerData.packageDetails &&
                            _customerData.packageDetails.onlyLossOfService === true &&
                            values.drainsProblemRelateTo ===
                                'Blocked drainage, toilet, sink or shower'
                        ) {
                            return 'lossOfServiceNotCovered';
                        }
                        if (
                            values.drainsProblemRelateTo ===
                            'Blocked drainage, toilet, sink or shower'
                        ) {
                            return 'vulnerability';
                        }

                        return 'optionNotCovered';
                    },
                },
            ],
        },
    ];
};
