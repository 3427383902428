import { JSONSchemaType } from 'ajv';
import { PandoraErrorResponse, pandoraErrorResponseSchema } from 'src/types/pandora';

export interface ApplianceMake {
    id: number;
    makeName: string;
    dateCreated: string;
    dateModified: string;
}

export interface GetAppliancesMakesSuccessResponse {
    applianceMakes: ApplianceMake[];
}

export type GetAppliancesMakesResponse = GetAppliancesMakesSuccessResponse | PandoraErrorResponse;

export const applianceMakesSchema: JSONSchemaType<ApplianceMake> = {
    type: 'object',
    properties: {
        id: { type: 'number' },
        makeName: { type: 'string' },
        dateCreated: { type: 'string' },
        dateModified: { type: 'string' },
    },
    required: ['id', 'makeName'],
};

export const getApplianceMakesSuccessResponseSchema: JSONSchemaType<GetAppliancesMakesSuccessResponse> =
    {
        type: 'object',
        properties: {
            applianceMakes: { type: 'array', items: applianceMakesSchema },
        },
        required: ['applianceMakes'],
    };

export const getAppliancesMakesResponseSchema: JSONSchemaType<GetAppliancesMakesResponse> = {
    type: 'object',
    anyOf: [getApplianceMakesSuccessResponseSchema, pandoraErrorResponseSchema],
    additionalProperties: true,
};
