import toast from 'react-hot-toast';
import toastConfig from 'src/utils/toastConfig';
import { ArrowRightTwo } from '../../images/index';
import Button from '../Button';
import style from './index.module.scss';

interface BannerProps {
    version?: string;
    title?: string;
    subtitle?: string | JSX.Element;
    body?: string;
    buttonText?: string;
    buttonLink?: string;
    bannerLink?: {
        text: string;
        textButton: string;
        link: string;
    };
    fullLink?: string;
    handleClick?: () => void;
}

const Banner = ({
    version,
    title,
    subtitle,
    buttonLink,
    buttonText,
    body,
    bannerLink,
    fullLink,
    handleClick,
}: BannerProps) => {
    return (
        <a
            className={style.banner}
            data-version={version}
            data-link={!!fullLink}
            href={fullLink}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
                if (handleClick) handleClick();
            }}
        >
            <div className={style.container}>
                {title ? <h4 className={style.title}>{title}</h4> : null}
                {subtitle ? <p className={style.subtitle}>{subtitle}</p> : null}
                {body ? <p className={style.body}>{body}</p> : null}
                {fullLink ? <ArrowRightTwo /> : null}
                {bannerLink ? (
                    <div className={style.bannerLink}>
                        <div>
                            <p>{bannerLink.text}</p>
                            <p>
                                {bannerLink.link.length > 35
                                    ? `${bannerLink.link.substring(0, 35)}...`
                                    : bannerLink.link}
                            </p>
                        </div>
                        <Button
                            variant="text"
                            onClick={() => {
                                const linkText =
                                    bannerLink.link.length > 35
                                        ? `${bannerLink.link.substring(0, 35)}...`
                                        : bannerLink.link;
                                if (
                                    navigator.share &&
                                    navigator.canShare({ text: bannerLink.link })
                                ) {
                                    navigator.share({
                                        title: 'Refer a friend',
                                        url: bannerLink.link,
                                    });
                                } else {
                                    navigator.clipboard.writeText(bannerLink.link);
                                    toast.success(`Link "${linkText}" copied to clipboard`, {
                                        ...toastConfig,
                                    });
                                }
                            }}
                        >
                            {bannerLink.textButton}
                        </Button>
                    </div>
                ) : null}
            </div>
            {buttonText && buttonLink ? (
                <Button
                    variant="primary"
                    onClick={() => {
                        window.open(buttonLink);
                    }}
                >
                    {buttonText}
                </Button>
            ) : null}
        </a>
    );
};

export default Banner;
