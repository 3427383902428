import { useEffect, useMemo, useState } from 'react';

import { JobDetails } from 'src/types/Job';
import Spinner from 'src/components/Spinner';
import { getMandatoryEnvVar } from 'src/utils/env';
import { FormState } from '../../types/Form';
import { Questions } from '../../types/Question';
import CurrentQuestion from '../Question';
import styles from './index.module.scss';

interface Props {
    questions: (formState: Partial<FormState>) => Questions;
    initialQuestionKey: string;
    endQuestionKeys: string[];
    formSubmit: (formState: Partial<FormState>) => void;
    isFormSubmitting: boolean;
    jobDetails: JobDetails;
}

interface FormHistory {
    formState: Partial<FormState>;
    currentQuestion: string;
}

const env = getMandatoryEnvVar('REACT_APP_ENVIRONMENT');

const envToThreshold = {
    local: 1,
    dev: 1,
    stage: 1,
    prod: 1,
};

const getUseStructuredParts = (threshold: number): boolean => Math.random() <= threshold;

const Form = ({
    questions,
    initialQuestionKey,
    endQuestionKeys,
    formSubmit,
    isFormSubmitting,
    jobDetails,
}: Props) => {
    const [formState, setFormState] = useState<Partial<FormState>>({});
    const [currentQuestionKey, setCurrentQuestionKey] = useState<string>(initialQuestionKey);
    const [formHistory, setFormHistory] = useState<FormHistory[]>([]);
    const formQuestions = useMemo(() => questions(formState), [formState, questions]);

    const updateLocalStorage = (
        updatedFormState: Partial<FormState>,
        nextQuestion: string,
        updatedFormHistory: FormHistory[]
    ) => {
        localStorage.setItem(jobDetails.sk_job_id, JSON.stringify(updatedFormState));
        localStorage.setItem(`${jobDetails.sk_job_id}+currentQuestion`, nextQuestion);
        localStorage.setItem(
            `${jobDetails.sk_job_id}+formHistory`,
            JSON.stringify(updatedFormHistory)
        );
    };

    const onQuestionSubmit = (value: Partial<FormState>, nextQuestion: string) => {
        const updatedFormState: Partial<FormState> = Object.assign(formState, value);
        setFormState(updatedFormState);

        const newFormHistory = [...formHistory];
        newFormHistory.push({
            formState: { ...updatedFormState },
            currentQuestion: currentQuestionKey,
        });
        setFormHistory(newFormHistory);
        updateLocalStorage({ ...updatedFormState }, nextQuestion, newFormHistory);

        if (endQuestionKeys.includes(nextQuestion)) {
            formSubmit(updatedFormState);
        }

        setCurrentQuestionKey(nextQuestion);
    };

    const onBack = () => {
        // Guard against missing formHistory - if this happens effectively do a restart
        if (!formHistory || !formHistory.length) {
            setCurrentQuestionKey(initialQuestionKey);
            setFormState({});
            setFormHistory([]);

            updateLocalStorage({}, initialQuestionKey, []);
            return;
        }
        const formHistoryCopy = [...formHistory];
        const latestFormHistoryEntry = formHistoryCopy.pop();

        if (latestFormHistoryEntry) {
            const { formState: previousFormState, currentQuestion: previousQuestionKey } =
                latestFormHistoryEntry;

            setCurrentQuestionKey(previousQuestionKey);
            setFormState(previousFormState);
            setFormHistory(formHistoryCopy);

            updateLocalStorage(previousFormState, previousQuestionKey, formHistoryCopy);
        }
    };

    useEffect(() => {
        const currentQuestion = localStorage.getItem(`${jobDetails.sk_job_id}+currentQuestion`);

        if (currentQuestion) {
            setCurrentQuestionKey(currentQuestion || initialQuestionKey);
            setFormState(JSON.parse(localStorage.getItem(jobDetails.sk_job_id) || '{}'));

            setFormHistory(
                JSON.parse(localStorage.getItem(`${jobDetails.sk_job_id}+formHistory`) || '{}')
            );
        } else {
            // If no existing journey, set useStructuredParts flag in formState
            setFormState({
                useStructuredParts: getUseStructuredParts(envToThreshold[env]),
            });
        }
    }, [jobDetails.sk_job_id, initialQuestionKey]);

    if (isFormSubmitting) {
        return (
            <div className={styles.loaderContainer}>
                <Spinner />
            </div>
        );
    }

    return (
        <CurrentQuestion
            currentQuestion={formQuestions[currentQuestionKey]}
            formState={formState}
            onSubmit={onQuestionSubmit}
            jobDetails={jobDetails}
            onBack={onBack}
            showBackButton={
                (currentQuestionKey !== initialQuestionKey &&
                    !endQuestionKeys.includes(currentQuestionKey) &&
                    currentQuestionKey !== 'costAuthorisation' &&
                    currentQuestionKey !== 'canLabourCostBeLowered' &&
                    currentQuestionKey !== 'partsNeeded') ||
                (currentQuestionKey === 'partsNeeded' && !formState.warningLabourCostShown)
            }
        />
    );
};

export default Form;
