import { JOBS_PUBLIC_ENDPOINT } from 'src/constants';
import { getAuthenticatedUser, getTokenFromAuthenticatedUser } from 'src/features/login';
import { getMandatoryEnvVar } from 'src/utils/env';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

export const startTravel = async (jobId: string): Promise<{}> => {
    const user = await getAuthenticatedUser();
    const token = getTokenFromAuthenticatedUser(user);

    const startTravelUrl = `${API_BASE_URL}/${JOBS_PUBLIC_ENDPOINT}/v1/engineer-jobs/${jobId}/start-travel`;

    const requestOptions = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    };

    try {
        const response = await fetch(startTravelUrl, requestOptions);
        const data = await response.json();

        if (!response.ok) {
            const error = (data && data.message) || response.status;
            throw new Error(error);
        }

        return data;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error({
            message: 'Failed to start travel for a Job',
            error,
            data: {
                jobId,
            },
        });
        throw new Error('Failed to start travel for a Job');
    }
};
