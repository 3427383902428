import { Navigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { ErrorMessage } from 'src/utils/copy';
import { useAuthenticatedUser } from '../../hooks/useAuthenticatedUser';
import { Role } from '../../types/Role';
import { Routes as RoutePath } from '../../types/Routes';
import { getUserRole } from '../../utils/getUserRole';
import ErrorBar from '../ErrorBar';
import Loader from '../Loader';

interface Props {
    children: JSX.Element;
    roles?: Role[];
}

const ProtectedRoute = ({ children, roles = [] }: Props) => {
    const { isLoading, isError, data } = useAuthenticatedUser();

    if (isLoading) {
        return <Loader />;
    }

    if (!data?.isAuthenticated || isError) {
        // store the url in local storage so we can redirect the user back to it after login
        localStorage.setItem('redirectUrl', window.location.pathname + window.location.search);
        return <Navigate to="/auth" replace />;
    }

    const userAttributes = data.authenticatedUser?.attributes;

    Sentry.configureScope((scope) => {
        if (!userAttributes) return;

        scope.setUser({
            id: userAttributes['custom:account_id'],
        });
    });

    // If authenticated, check role
    const role = getUserRole(data);
    if (!role) {
        // eslint-disable-next-line no-console
        console.error('Account does not have a custom role');
        return <ErrorBar message={ErrorMessage.CUSTOMER} className="no-border" />;
    }
    if (roles.length > 0 && !roles.includes(role)) {
        return <Navigate to={RoutePath.LANDING} replace />;
    }

    return children;
};

export default ProtectedRoute;
