import format from 'date-fns/format';
import { useMemo } from 'react';
import {
    StatusActionRequired,
    StatusCancelled,
    StatusComplete,
    StatusEnroute,
    StatusInProgress,
    StatusReady,
} from 'src/images';
import style from './index.module.scss';

interface Props {
    start: Date;
    end: Date;
    status: string;
    actionButtons?: React.ReactNode;
}

const calculateDaySlot = (start: Date): string => {
    switch (true) {
        case start.getHours() >= 16:
            return 'Evening';
        case start.getHours() >= 12:
            return 'Afternoon';
        default:
            return 'Morning';
    }
};

const calculateStatusIcon = (status: string) => {
    switch (status) {
        case 'Ready':
            return <StatusReady />;
        case 'En Route':
            return <StatusEnroute />;
        case 'In Progress':
            return <StatusInProgress />;
        case 'Completed':
        case 'Complete':
            return <StatusComplete />;
        case 'Cancelled':
            return <StatusCancelled />;
        case 'Action Required':
            return <StatusActionRequired />;
        default:
            // eslint-disable-next-line no-console
            console.error(
                JSON.stringify({
                    message: 'Unhandled job status for icon',
                    data: { status },
                    level: 'ERROR',
                })
            );
            return <></>;
    }
};

const JobCardMain = ({ start, end, status, actionButtons }: Props) => {
    const startTime = format(start, 'HH:mm');
    const endTime = format(end, 'HH:mm');
    const daySlot = useMemo(() => calculateDaySlot(start), [start]);

    const statusIcon = useMemo(() => calculateStatusIcon(status), [status]);

    return (
        <div className={style.container}>
            <div className={style.details}>
                <div className={style.subDetails}>
                    <span className={style.title}>{daySlot}</span>
                    <span className={style.time}>{`${startTime} - ${endTime}`}</span>
                </div>
                <div className={style.subDetails}>
                    <span className={style.title}>Status</span>
                    <div className={style.status}>
                        {statusIcon}
                        <span>{status}</span>
                    </div>
                </div>
            </div>
            <div className={style.actions}>{actionButtons}</div>
        </div>
    );
};

export default JobCardMain;
