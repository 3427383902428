import Ajv from 'ajv';
import axios from 'axios';
import { getAuthenticatedUser, getTokenFromAuthenticatedUser } from 'src/features/login';
import { isPandoraErrorResponse } from 'src/types/pandora';
import { getMandatoryEnvVar } from 'src/utils/env';
import { ajvErrorFormatter } from 'src/utils/types';
import { JOBS_PUBLIC_ENDPOINT } from 'src/constants';
import { captureSentryError } from 'src/features/login/utils/captureSentryError';
import { JobPart, getJobPartsResponseSchema } from '../types/JobParts';

export const getJobParts = async (): Promise<JobPart[] | Error> => {
    const user = await getAuthenticatedUser();
    const token = getTokenFromAuthenticatedUser(user);
    const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

    try {
        const { data } = await axios({
            url: `${API_BASE_URL}/${JOBS_PUBLIC_ENDPOINT}/v1/job-parts`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });

        const ajv = new Ajv();
        const validate = ajv.compile(getJobPartsResponseSchema);

        const valid = validate(data);
        if (valid) {
            if (isPandoraErrorResponse(data)) {
                return new Error(`Error message from api: ${data.message}`);
            }
            return data.jobParts;
        }
        return new Error(
            `Failed to validate getJobParts response, ${ajvErrorFormatter(validate.errors)}`
        );
    } catch (error: any) {
        await captureSentryError(error, {
            message: 'Error getting job parts',
        });

        return new Error('Unable to get job parts');
    }
};
