import differenceInDays from 'date-fns/differenceInDays';
import { SkeduloJob } from 'src/types/Skedulo';

export const NO_OF_DAYS_REATTEND_LIMIT = 30;

export const historicalJobsFilter = ({
    node: { JobStatus, ActualEnd, CreatedDate },
}: SkeduloJob) => {
    if (JobStatus === 'Complete') {
        const daysSinceCompleted = differenceInDays(new Date(), new Date(ActualEnd || ''));
        if (daysSinceCompleted > NO_OF_DAYS_REATTEND_LIMIT) {
            return false;
        }
    }

    if (JobStatus === 'Cancelled') {
        const daysSinceCreated = differenceInDays(new Date(), new Date(CreatedDate || ''));
        if (daysSinceCreated > NO_OF_DAYS_REATTEND_LIMIT) {
            return false;
        }
    }

    return true;
};
