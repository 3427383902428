import { CaptureConsole } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const getEnvironment = () => {
    switch (window.location.hostname) {
        case 'www.portal.hometree.co.uk':
            return 'production';
        case 'portal.hometree.co.uk':
            return 'production';
        case 'portal.stage.hometree.co.uk':
            return 'staging';
        case 'portal.dev.hometree.co.uk':
            return 'development';
        default:
            return null;
    }
};

const initializeSentry = () => {
    const env = getEnvironment();
    const environmentsWithSentry = ['development', 'staging', 'production'];

    if (!environmentsWithSentry.includes(env || '')) {
        return;
    }

    Sentry.init({
        dsn: 'https://99cc5c9e4d9045118aaeddbe2dbeb849@o223561.ingest.sentry.io/5736081',
        integrations: [
            new BrowserTracing(),
            new CaptureConsole({
                levels: ['error'],
            }),
        ],
        environment: env || undefined,
        tracesSampleRate: 1.0,
    });
};

export default initializeSentry;
