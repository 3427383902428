type Props = {
    name?: string;
    value?: string;
    disabled?: boolean;
    onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
    isChecked?: boolean;
    readOnly?: boolean;
    dateWeek: string;
    dateNum: number | string;
};

const Date = ({
    name,
    value,
    disabled,
    onChange,
    isChecked,
    readOnly,
    dateWeek,
    dateNum,
}: Props) => {
    const inputId = name && value ? `${name}-${value}` : undefined;
    return (
        <div className="dateradioform-input">
            <input
                type="radio"
                name={name}
                value={value}
                id={inputId}
                data-testid={inputId}
                disabled={disabled}
                onChange={onChange}
                checked={isChecked}
                readOnly={readOnly}
            />
            <label htmlFor={inputId} data-testid={inputId ? `label-${inputId}` : undefined}>
                <div className="dateradioform-input-box">
                    <span className="dateradioform-input-box-week">{dateWeek}</span>
                    <span className="dateradioform-input-box-day" data-testid="day-text">
                        {dateNum}
                    </span>
                </div>
            </label>
        </div>
    );
};

export default Date;
