/* eslint-disable no-console */
import { Part } from 'src/features/closeJob/types/Form';

interface TotalCostOfPartsInput {
    partsRequested: Part[];
    warningLabourCostShown: boolean | undefined;
}

export const maxLabourAuthorised = (value: TotalCostOfPartsInput) => {
    const hasMoreParts = value.partsRequested.length >= 2;
    const considerMaxTolerance = value.warningLabourCostShown;

    const maxLabourAuthorisedValue = value.partsRequested
        .sort((a, b) => {
            if (Number(b.estimated_hours_primary) !== Number(a.estimated_hours_primary)) {
                return Number(b.estimated_hours_primary) - Number(a.estimated_hours_primary);
            }
            return Number(a.estimated_hours_secondary) - Number(b.estimated_hours_secondary);
        })
        .reduce((acc, part, currentIndex) => {
            if (currentIndex === 0) {
                if (!hasMoreParts && considerMaxTolerance) {
                    return acc + Number(part.estimated_hours_primary) + Number(part.max_tolerance);
                }
                return acc + Number(part.estimated_hours_primary);
            }
            return acc + Number(part.estimated_hours_secondary);
        }, 0);
    return maxLabourAuthorisedValue;
};
