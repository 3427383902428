import { ChangeEventHandler } from 'react';
import styles from './index.module.scss';

interface Props {
    value: string;
    onChange: (value: string) => void;
    error: boolean;
}

const CodeInput = ({ value, onChange, error }: Props) => {
    const isFilled = value.length === 6;

    const handleOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const onChangeValue = e.target.value;

        if (/^([0-9]){0,6}$/.test(onChangeValue)) {
            onChange(onChangeValue);
        }
    };

    return (
        <div
            className={`${styles.codeInput} ${isFilled && styles.filled} ${error && styles.error}`}
        >
            <input
                id="codeInput"
                value={value}
                onChange={handleOnChange}
                type="text"
                maxLength={6}
                inputMode="numeric"
                pattern="[0-9]*"
                autoComplete="one-time-code"
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
            />
            <label htmlFor="codeInput">
                <div>{value[0]}</div>
                <div>{value[1]}</div>
                <div>{value[2]}</div>
                <div>{value[3]}</div>
                <div>{value[4]}</div>
                <div>{value[5]}</div>
            </label>
        </div>
    );
};

export default CodeInput;
