import { JobUrgency } from 'src/types/JobUrgency';
import { DAY_IN_MILLIS } from '../constants';

export const isWinterBankHoliday = (date: Date): boolean => {
    return (
        (date.getMonth() === 11 && date.getDate() === 25) ||
        (date.getMonth() === 0 && date.getDate() === 1)
    );
};

const isSunday = (date) => date.getDay() === 0;

const isDayBookableForCustomer = (date: Date, urgency: JobUrgency) => {
    if (urgency !== JobUrgency.NORMAL) return true;

    return !isSunday(date);
};

export const isDayBookable = (
    date: Date,
    urgency: JobUrgency,
    alreadySelectedDates: Date[] = []
) => {
    if (
        alreadySelectedDates.filter((ele) => ele.toDateString() === date.toDateString()).length > 0
    ) {
        return false;
    }

    if (isWinterBankHoliday(date)) {
        return false;
    }

    return isDayBookableForCustomer(date, urgency);
};

const morningSlot = {
    value: '08:00:00',
    label: ['Morning', '08:00 - 12:00'],
};

const afternoonSlot = {
    value: '12:00:00',
    label: ['Midday', '12:00 - 16:00'],
};

const eveningSlot = {
    value: '16:00:00',
    label: ['Evening', '16:00 - 20:00'],
};

const dateDiffInDays = (a: Date, b: Date) => {
    // Convert into London timezone
    const londonTz1 = new Date(a.toLocaleString('en-US', { timeZone: 'Europe/London' }));
    const londonTz2 = new Date(b.toLocaleString('en-US', { timeZone: 'Europe/London' }));
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(londonTz1.getFullYear(), londonTz1.getMonth(), londonTz1.getDate());
    const utc2 = Date.UTC(londonTz2.getFullYear(), londonTz2.getMonth(), londonTz2.getDate());

    return Math.floor((utc2 - utc1) / DAY_IN_MILLIS);
};

const datesAreOnSameDay = (a: Date, b: Date) => dateDiffInDays(a, b) === 0;

const startOfDayHour = 9;
const onePM = 13;
const twoPM = 14;
const endOfDayHour = 18;

export const allTimeSlots = [morningSlot, afternoonSlot, eveningSlot];

export const determineTimeSlotsToShow = (
    jobUrgency: JobUrgency | null,
    selectedDate: Date | null
) => {
    if (jobUrgency === JobUrgency.NORMAL || jobUrgency === null || selectedDate === null)
        return allTimeSlots;

    const currentDateTime = new Date();

    // Beyond
    if (dateDiffInDays(currentDateTime, selectedDate) > 1) {
        return allTimeSlots;
    }

    const currentHour = currentDateTime.getHours();

    // Today
    if (datesAreOnSameDay(currentDateTime, selectedDate)) {
        if (currentHour < startOfDayHour) return [afternoonSlot, eveningSlot];
        if (currentHour < onePM) return [eveningSlot];
    }

    // Tomorrow
    if (dateDiffInDays(currentDateTime, selectedDate) === 1) {
        if (currentHour < twoPM) return allTimeSlots;
        if (currentHour < endOfDayHour) return [afternoonSlot, eveningSlot];
        if (currentHour >= endOfDayHour) return [eveningSlot];
    }

    return [];
};
