import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/Button';
import ErrorBar from 'src/components/ErrorBar';
import Input from 'src/components/Input';
import Layout from 'src/components/Layout';
import Loader from 'src/components/Loader';
import { useAuthenticatedUser } from 'src/hooks/useAuthenticatedUser';
import { Routes } from 'src/types/Routes';
import BubbleHeading from '../login/components/bubbleHeading';
import { noEmailAuthentication } from './api/noEmailAuthentication';
import { noEmailSignUp } from './api/noEmailSignUp';
import styles from './index.module.scss';

const EmailSignUpRoute = () => {
    const { isLoading, data } = useAuthenticatedUser();
    const [uniqueCode, setUniqueCode] = useState('');
    const [postcode, setPostcode] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [confirmationEmail, setConfirmationEmail] = useState('');
    const [error, setError] = useState('');
    const [accountValidated, setAccountValidated] = useState(false);
    const [hasSignedUp, setHasSignedUp] = useState(false);
    const navigate = useNavigate();

    if (isLoading) {
        return <Loader />;
    }

    if (data?.isAuthenticated) {
        navigate(Routes.DASHBOARD);
    }

    const onChange = (key) => (e) => {
        const currentValue = e.target.value;

        if (key === 'uniqueCode') {
            setUniqueCode(currentValue);
        } else if (key === 'postcode') {
            setPostcode(currentValue);
        } else if (key === 'surname') {
            setSurname(currentValue);
        } else if (key === 'email') {
            setEmail(currentValue);
        } else if (key === 'confirmationEmail') {
            setConfirmationEmail(currentValue);
        }

        if (error) {
            setError('');
        }
    };

    const sendNoEmailAuthenticationRequest = async () => {
        setError('');

        const isAccountValid = await noEmailAuthentication({
            code: uniqueCode,
            postcode,
            surname,
        });

        if (!isAccountValid) {
            setError('The code, postcode and surname provided do not match. Please try again.');
        } else {
            setAccountValidated(true);
        }
    };

    const sendNoEmailSignUpRequest = async () => {
        setError('');

        if (email !== confirmationEmail) {
            setError('Email and confirmation email do not match. Please try again.');
            return;
        }

        try {
            await noEmailSignUp({
                code: uniqueCode,
                postcode,
                email,
                surname,
            });

            setHasSignedUp(true);
        } catch (e) {
            setError('Could not sign up to your email please contact us at.');
        }
    };

    return (
        <Layout>
            <form className={styles.emailSignUpSection}>
                {!hasSignedUp && !accountValidated && (
                    <>
                        <BubbleHeading
                            heading="Let’s find you!"
                            subheading="Just pop in your details"
                        />
                        <div className={styles.formControl}>
                            <label htmlFor="uniqueCode">
                                <p>Unique code</p>
                                <Input
                                    inputType="text"
                                    id="uniqueCode"
                                    name="uniqueCode"
                                    placeholder="Found in the letter you received"
                                    value={uniqueCode}
                                    onChange={onChange('uniqueCode')}
                                />
                            </label>
                        </div>
                        <div className={styles.formControl}>
                            <label htmlFor="postcode">
                                <p>Post code</p>
                                <Input
                                    inputType="text"
                                    id="postcode"
                                    name="postcode"
                                    placeholder="WC1 7TS"
                                    value={postcode}
                                    onChange={onChange('postcode')}
                                />
                            </label>
                        </div>
                        <div className={styles.formControl}>
                            <label htmlFor="surname">
                                <p>Surname</p>
                                <Input
                                    inputType="text"
                                    id="surname"
                                    name="surname"
                                    placeholder="Enter your surname"
                                    value={surname}
                                    onChange={onChange('surname')}
                                />
                            </label>
                        </div>

                        {error && <ErrorBar message={error} style={styles.authFormErrorBar} />}

                        <div className={styles.buttonContainer}>
                            <Button
                                id="validate-no-email-submit"
                                type="button"
                                // isDisabled={isSubmitting}
                                onClick={() => sendNoEmailAuthenticationRequest()}
                            >
                                Submit
                            </Button>
                        </div>
                    </>
                )}

                {!hasSignedUp && accountValidated && (
                    <>
                        <BubbleHeading
                            heading="Sign up via email today!"
                            subheading="Tell us your email below"
                        />
                        <div className={styles.formControl}>
                            <label htmlFor="email">
                                <p>Email address</p>
                                <Input
                                    inputType="email"
                                    id="email"
                                    name="email"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={onChange('email')}
                                />
                            </label>
                        </div>
                        <div className={styles.formControl}>
                            <label htmlFor="confirmationEmail">
                                <p>Confirm email address</p>
                                <Input
                                    inputType="email"
                                    id="confirmationEmail"
                                    name="confirmationEmail"
                                    placeholder="Confirm your email"
                                    value={confirmationEmail}
                                    onChange={onChange('confirmationEmail')}
                                />
                            </label>
                        </div>

                        {error && <ErrorBar message={error} style={styles.authFormErrorBar} />}

                        <div className={styles.buttonContainer}>
                            <Button
                                id="sign-up-submit"
                                type="button"
                                // isDisabled={isSubmitting}
                                onClick={() => sendNoEmailSignUpRequest()}
                            >
                                Submit
                            </Button>
                        </div>
                    </>
                )}

                {hasSignedUp && (
                    <>
                        <BubbleHeading heading="Thank you!" />
                        <div className={styles.link}>
                            <h3>Your email address is confirmed</h3>
                            <p>
                                We’re excited to let you know that you now have access to our
                                exclusive &nbsp;
                                <a
                                    href="https://portal.hometree.co.uk/auth"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    customer portal
                                </a>
                                &nbsp; and we will keep you informed on important updates related to
                                your policy via email.
                            </p>
                        </div>
                    </>
                )}
            </form>
        </Layout>
    );
};

export default EmailSignUpRoute;
