import Layout from 'src/components/Layout';
import { HOMETREE_PHONE_NUMBER } from '../../constants';
import { Attention } from '../../images/index';
import styles from './index.module.scss';

const NotFound = () => (
    <Layout>
        <section className={styles.notFoundPage}>
            <Attention />
            <h2>Page Not Found</h2>
            <p>
                We have been looking for this page, but we could not find it in the pile of stuff we
                have :( We are very sorry about that. Please go back to your initial page or give us
                a call on {}
                <a href={`tel:+44${HOMETREE_PHONE_NUMBER}`}>{HOMETREE_PHONE_NUMBER}</a>
                {} if you think this should be working.
            </p>
            <p>Thanks for understanding.</p>
        </section>
    </Layout>
);

export default NotFound;
