import { JSONSchemaType } from 'ajv';
import { PandoraErrorResponse, pandoraErrorResponseSchema } from 'src/types/pandora';

export type JobPartsResponse = JobPartsSuccessResponse | PandoraErrorResponse;

export interface JobPartsSuccessResponse {
    jobParts: JobPart[];
}

export interface JobPart {
    name: string;
    estimated_hours_primary: string;
    estimated_hours_secondary: string;
    max_tolerance: string;
}

export const jobPartSchema: JSONSchemaType<JobPart> = {
    type: 'object',
    properties: {
        name: { type: 'string' },
        estimated_hours_primary: { type: 'string' },
        estimated_hours_secondary: { type: 'string' },
        max_tolerance: { type: 'string' },
    },
    required: ['name'],
};

export const getJobPartSuccessResponseSchema: JSONSchemaType<JobPartsSuccessResponse> = {
    type: 'object',
    properties: {
        jobParts: {
            type: 'array',
            items: jobPartSchema,
        },
    },
    required: ['jobParts'],
};

export const getJobPartsResponseSchema: JSONSchemaType<JobPartsResponse> = {
    type: 'object',
    anyOf: [getJobPartSuccessResponseSchema, pandoraErrorResponseSchema],
};
