import format from 'date-fns/format';
import { ExpandArrow } from 'src/images';
import Button from 'src/components/Button';
import style from './index.module.scss';

interface DateHeaderProps {
    date: Date;
    onBackClick?: () => void;
}

const DateHeader = ({ date, onBackClick }: DateHeaderProps) => {
    return (
        <div className={style.dateHeader}>
            {onBackClick && (
                <Button type="button" onClick={onBackClick} customStyle={style.backBtn}>
                    <ExpandArrow />
                </Button>
            )}
            <div>
                <span>{format(date, 'dd LLLL')}</span>
                <span> </span>
                <span>{format(date, 'yyy')}</span>
            </div>
        </div>
    );
};

export default DateHeader;
