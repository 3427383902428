import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { useState } from 'react';
import toast, { ToastOptions } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'src/components/Button';
import Dropdown from 'src/components/Dropdown';
import ErrorBar from 'src/components/ErrorBar';
import FileUpload from 'src/components/FileUpload';
import Layout from 'src/components/Layout';
import Loader from 'src/components/Loader';
import DateForm from 'src/features/engineerDateForm';
import { useJobDetails } from 'src/features/jobDetails';
import { captureSentryError } from 'src/features/login/utils/captureSentryError';
import ToggleForm from 'src/features/newClaim/components/Calendar/ToggleForm';
import { Phone } from 'src/images';
import { JobUrgency } from 'src/types/JobUrgency';
import { allTimeSlots } from 'src/utils/dateForm';
import { decodeString } from 'src/utils/decodeString';
import { Routes as RoutePath } from '../../../types/Routes';
import { postRescheduleJob } from '../api/postRescheduleJob';
import DateHeader from './DateHeader';
import style from './index.module.scss';

const rescheduleReasons = [
    { value: '', copy: 'Select reason for rescheduling', disabled: true },
    { value: 'Parts not ready', copy: 'Parts not ready' },
    { value: 'Engineer illness', copy: 'Engineer illness' },
    { value: 'Customer not home', copy: 'Customer not home' },
    { value: 'Van breakdown', copy: 'Van breakdown' },
    { value: 'Work emergency', copy: 'Work emergency' },
    { value: 'Personal emergency', copy: 'Personal emergency' },
    { value: 'Other', copy: 'Other' },
];

const toastConfig: ToastOptions = {
    style: {
        borderRadius: '10px',
        background: '#3f4955',
        color: '#fff',
    },
    position: 'top-right',
};

const RescheduleJob = () => {
    const navigate = useNavigate();
    const { jobId } = useParams();
    const {
        isError: isJobDetailsError,
        isLoading,
        data: jobDetailsData,
        error: jobDetailsError,
    } = useJobDetails(jobId);
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [fileUrls, setFileUrls] = useState([]);
    const [rescheduleDetails, setRescheduleDetails] = useState({
        visitDate: '',
        timeSlot: '',
        rescheduleReason: '',
        rescheduleReasonOther: '',
    });

    const [rescheduleRequestError, setRescheduleRequestError] = useState('');
    const [isRescheduleRequestLoading, setRescheduleRequestLoading] = useState(false);

    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

    const today = new Date();

    const onChange = (key) => (e) => {
        const currentValue = e.target.value;
        const currentFieldName = e.target.name;

        if (currentValue) {
            setErrors((prev) => {
                const newErrors = { ...prev };
                delete newErrors[currentFieldName];

                if (currentFieldName === 'rescheduleReason' && currentValue !== 'Other') {
                    delete newErrors.rescheduleReasonOther;
                }

                return newErrors;
            });
        }

        setRescheduleDetails({
            ...rescheduleDetails,
            ...(currentFieldName === 'visitDate' && { timeSlot: '' }),
            ...(currentFieldName === 'rescheduleReason' && { rescheduleReasonOther: '' }),
            [key]: currentValue,
        });
    };

    const validateRescheduleDetails = () => {
        const newErrors: Record<string, string> = {};

        if (!rescheduleDetails.visitDate) {
            newErrors.visitDate = 'Please select a date';
        }

        if (!rescheduleDetails.timeSlot) {
            newErrors.timeSlot = 'Please select a time slot';
        }

        if (!rescheduleDetails.rescheduleReason) {
            newErrors.rescheduleReason = 'Please select a reason';
        }

        if (
            rescheduleDetails.rescheduleReason === 'Other' &&
            !rescheduleDetails.rescheduleReasonOther
        ) {
            newErrors.rescheduleReasonOther = 'Please enter a specific reason';
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const onRescheduleSubmit = async () => {
        if (!jobDetailsData) return;

        if (!validateRescheduleDetails()) return;

        try {
            setRescheduleRequestLoading(true);

            await postRescheduleJob({
                skPreviousJobUid: jobDetailsData.sk_job_id,
                skEngineerUid: jobDetailsData.sk_resource_id,
                visitDateTime: parseISO(
                    `${rescheduleDetails.visitDate} ${rescheduleDetails.timeSlot}`
                ),
                rescheduleReason: rescheduleDetails.rescheduleReason,
                rescheduleReasonOther: rescheduleDetails.rescheduleReasonOther,
                reschedulePhotos: fileUrls,
            });

            // Adding delay to allow for job status to sync from Skedulo.
            // TODO: Remove this delay once we don't have Skedulo
            await new Promise((resolve) => setTimeout(resolve, 2000));

            toast.success(
                `You're all booked in! We've rescheduled "${jobDetailsData.sk_job_name}" for ${
                    rescheduleDetails.visitDate
                } at ${rescheduleDetails.timeSlot.slice(0, -3)}`,
                toastConfig
            );

            navigate(RoutePath.JOB_AGENDA);
        } catch (error) {
            await captureSentryError(error, {
                sk_job_id: jobDetailsData.sk_job_id,
                message: 'Error rescheduling job',
            });
            setRescheduleRequestError(
                'There was an error rescheduling your job, please contact an administrator.'
            );
        }

        setRescheduleRequestLoading(false);
    };

    if (!jobId) {
        // eslint-disable-next-line no-console
        console.error('Error retrieving job details: No jobId in url');
        return <ErrorBar message="Error retrieving job details: No jobId in url" />;
    }

    if (isLoading || isRescheduleRequestLoading) {
        return <Loader />;
    }

    if (!jobDetailsData) {
        // eslint-disable-next-line no-console
        console.error(`Error retrieving job details for jobId: ${jobId}`);
        return <ErrorBar message="Error retrieving job details" />;
    }

    if (rescheduleRequestError) {
        return <ErrorBar message={rescheduleRequestError} />;
    }

    if (isJobDetailsError) {
        // eslint-disable-next-line no-console
        console.error('Error retrieving job details', jobDetailsError);
        return (
            <div>
                Error retrieving job details for job UID {jobId}: {jobDetailsError.message}
            </div>
        );
    }

    if (jobDetailsData.sk_job_status === 'Cancelled') {
        return (
            <ErrorBar message="This job has already been 'Cancelled' and cannot be rescheduled, please contact an administrator." />
        );
    }

    return (
        <Layout>
            {jobDetailsData.sk_start && (
                <DateHeader
                    date={parseISO(jobDetailsData.sk_start)}
                    onBackClick={() => navigate(RoutePath.JOB_AGENDA)}
                />
            )}

            <div className={style.container}>
                <h3>Reschedule your visit</h3>

                <div className={style.customerDetails}>
                    <h3>Customer Details</h3>
                    <p>
                        {jobDetailsData.first_name} {jobDetailsData.last_name}
                    </p>
                    <p>
                        {decodeString(
                            `${jobDetailsData.address_line_1}, ${jobDetailsData.town} ${jobDetailsData.postcode}`
                        )}
                    </p>

                    {jobDetailsData.phone1 && (
                        <div className={style.telephone}>
                            <a
                                href={`tel:+${jobDetailsData.phone1_prefix}${jobDetailsData.phone1}}`}
                            >
                                <Phone />
                                <span>
                                    +{jobDetailsData.phone1_prefix}&nbsp;{jobDetailsData.phone1}
                                </span>
                            </a>
                        </div>
                    )}

                    {jobDetailsData.phone2 && (
                        <div className={style.telephone}>
                            <a
                                href={`tel:+${jobDetailsData.phone2_prefix}${jobDetailsData.phone2}}`}
                            >
                                <Phone />
                                <span>
                                    +{jobDetailsData.phone2_prefix}&nbsp;{jobDetailsData.phone2}
                                </span>
                            </a>
                        </div>
                    )}
                </div>

                <div className={style.rescheduleReasonDropdown}>
                    <label htmlFor="reschedule-reason">What is the reason for rescheduling?</label>
                    <Dropdown
                        id="reschedule-reason"
                        name="rescheduleReason"
                        options={rescheduleReasons}
                        currentValue={rescheduleDetails.rescheduleReason}
                        onChange={onChange('rescheduleReason')}
                    />

                    {errors.rescheduleReason && (
                        <div className="fnolform-error">{errors.rescheduleReason}</div>
                    )}
                    {rescheduleDetails.rescheduleReason === 'Customer not home' && (
                        <FileUpload
                            title="Add picture of visit:"
                            jobId={jobDetailsData.sk_job_id}
                            setParentComponentFileUrls={setFileUrls}
                        />
                    )}

                    {rescheduleDetails.rescheduleReason === 'Other' && (
                        <div className={style.otherReason}>
                            <label htmlFor="other-reason">Give more details</label>
                            <textarea
                                id="other-reason"
                                name="rescheduleReasonOther"
                                value={rescheduleDetails.rescheduleReasonOther}
                                rows={4}
                                cols={20}
                                className={style.textArea}
                                onChange={onChange('rescheduleReasonOther')}
                            />

                            {errors.rescheduleReasonOther && (
                                <div className="fnolform-error">{errors.rescheduleReasonOther}</div>
                            )}
                        </div>
                    )}
                </div>

                <h4>Select the date and time you have confirmed with the customer</h4>
                <div className={style.dateFormWrapper}>
                    <DateForm
                        errors={errors}
                        name="visitDate"
                        subtitle="Available dates are highlighted"
                        onChange={onChange('visitDate')}
                        selectedDate={rescheduleDetails.visitDate}
                        title={
                            <>
                                <span>{format(today, 'MMMM')}</span> {format(today, 'yyyy')}
                            </>
                        }
                        urgency={JobUrgency.CRITICAL} // hardcode critical to allow engineer to book reschedule quickly
                    />
                </div>
                {rescheduleDetails.visitDate && (
                    <div className={style.timeSlotWrapper}>
                        <ToggleForm
                            title="What time slot is best?"
                            timeSlotsToShow={allTimeSlots}
                            name="timeSlot"
                            onChange={onChange('timeSlot')}
                            errors={errors}
                            selectedTimes={[rescheduleDetails.timeSlot]}
                        />
                    </div>
                )}

                <div>
                    <input
                        className={style.checkbox}
                        type="checkbox"
                        id="show-date-form"
                        checked={isCheckboxChecked}
                        onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                    />
                    <label htmlFor="show-date-form">
                        I confirm I have called the customer and they have agreed to the above date
                        & time.
                    </label>
                </div>

                <div className={style.buttonContainer}>
                    <Button
                        data-testid="reschedule-button"
                        isDisabled={
                            !isCheckboxChecked ||
                            isRescheduleRequestLoading ||
                            (rescheduleDetails.rescheduleReason === 'Customer not home' &&
                                fileUrls.length === 0)
                        }
                        onClick={onRescheduleSubmit}
                    >
                        Reschedule
                    </Button>
                </div>
            </div>
        </Layout>
    );
};

export default RescheduleJob;
