export enum JobUrgency {
    NORMAL = 'Normal',
    URGENT = 'Urgent',
    CRITICAL = 'Critical',
}

export enum Vulnerable {
    YES = 'Yes',
    NO = 'No',
}
