import Button from 'src/components/Button';
import style from './index.module.scss';

export interface Props {
    hide: () => void;
    children: any;
}
export const Modal: React.FC<Props> = ({ hide, children }) => {
    return (
        <div className={`${style.modal}`}>
            <section className={style.modalMain}>
                <Button type="button" onClick={hide} customStyle={style.closeButton}>
                    {/* TODO CSS this X */}X
                </Button>
                {children}
            </section>
        </div>
    );
};
