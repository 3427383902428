import { AbortReason, ClaimStatus, JobStatus } from '../api/getPandoraJob.types';

export const getCopyForJob = (
    skeduloJobStatus: JobStatus,
    pandoraClaimStatus: ClaimStatus,
    abortReason: AbortReason | null
) => {
    if (pandoraClaimStatus === ClaimStatus.SCHEDULE_INITIAL_OR_REATTEND) {
        return {
            summary: <p>Claim request received</p>,
            description: (
                <p>
                    If you&apos;ve raised a new claim for a repair we are in the process of
                    allocating an engineer. However, if this is a repair you had a visit for
                    recently, we are currently reviewing your claim and will be back in touch as
                    soon as possible.
                </p>
            ),
        };
    }
    if (pandoraClaimStatus === ClaimStatus.PENDING_INSURER_APPROVAL) {
        return {
            summary: <p>Awaiting Insurer Decision</p>,
            description: (
                <p>
                    We have successfully passed your claim to the insurer and are waiting for a
                    decision. We will be in touch shortly.
                </p>
            ),
        };
    }
    if (
        pandoraClaimStatus === ClaimStatus.INVESTIGATION_REQUIRED &&
        (abortReason === AbortReason.CANNOT_ATTEND_JOB ||
            abortReason === AbortReason.UNABLE_TO_COMPLETE_JOB_ONSITE)
    ) {
        return {
            summary: <p>Cancelled by engineer</p>,
            description: (
                <p>
                    Unfortunately the engineer has cancelled their visit but we are working hard to
                    find a replacement and will update you as soon as possible.
                </p>
            ),
        };
    }
    if (
        pandoraClaimStatus === ClaimStatus.INVESTIGATION_REQUIRED &&
        abortReason === AbortReason.CANCELLED_BY_CUSTOMER
    ) {
        return {
            summary: <p>Cancelled</p>,
            description: (
                <p>
                    We&apos;ve cancelled your engineer visit based on your request. If this
                    isn&apos;t correct, please log a new request for a repair.
                </p>
            ),
        };
    }
    if (
        pandoraClaimStatus === ClaimStatus.INVESTIGATION_REQUIRED &&
        abortReason === AbortReason.CUSTOMER_NO_SHOW
    ) {
        return {
            summary: <p>We missed you</p>,
            description: (
                <p>
                    Sorry we missed you! Our engineer came to your property but we weren&apos;t able
                    to find you. We&apos;ll be in touch shortly to rearrange their visit.
                </p>
            ),
        };
    }
    if (pandoraClaimStatus === ClaimStatus.ENGINEER_TO_SCHEDULE_FOLLOW_ON) {
        return {
            summary: <p>We&apos;re working on your claim</p>,
            description: (
                <p>
                    Your engineer is handling the next steps of your claim following your recent
                    visit. We&apos;ll be back in touch soon with an update.
                </p>
            ),
        };
    }
    if (pandoraClaimStatus === ClaimStatus.ORDER_PARTS) {
        return {
            summary: <p>Ordering parts</p>,
            description: (
                <p>
                    We are working on locating and sourcing the parts needed for your repair based
                    on the diagnosis from your last visit. We&apos;ll be back in touch to update
                    you, as soon as possible.
                </p>
            ),
        };
    }
    if (pandoraClaimStatus === ClaimStatus.HOMETREE_NOT_FIXING) {
        return {
            summary: <p>We&apos;re reviewing your claim</p>,
            description: (
                <p>
                    We are currently reviewing your claim and will be in touch to update you
                    shortly.
                </p>
            ),
        };
    }
    if (pandoraClaimStatus === ClaimStatus.SPECIAL_PARTS_REQUIRED) {
        return {
            summary: <p>Ordering parts</p>,
            description: (
                <p>
                    We have ordered the parts for your repair and are waiting for them to arrive as
                    they were not readily available from our supplier. We&apos;ll be back in touch
                    as soon as they arrive.
                </p>
            ),
        };
    }
    if (
        pandoraClaimStatus === ClaimStatus.CONFIRM_ENGINEER_AND_CUSTOMER_AVAILABILITY ||
        pandoraClaimStatus === ClaimStatus.ENGINEER_TO_ACCEPT_JOB
    ) {
        return {
            summary: <p>We&apos;re reviewing your claim</p>,
            description: (
                <p>
                    We are currently reviewing your claim and will be in touch to update you
                    shortly.
                </p>
            ),
        };
    }
    if (pandoraClaimStatus === ClaimStatus.SENT_TO_SME) {
        return {
            summary: <p>We&apos;re working on your claim</p>,
            description: (
                <p>
                    To get to you as soon as possible, we&apos;re working with a specialist partner
                    to complete your job. They&apos;ll be in touch with you directly to arrange a
                    visit. However, if your claim has been completed, we&apos;re just waiting to
                    receive their final report.
                </p>
            ),
        };
    }
    if (pandoraClaimStatus === ClaimStatus.UPCOMING) {
        return {
            summary: <p>Engineer visit booked</p>,
            description: (
                <p>
                    Good news - we&apos;ve booked your engineer visit. Check your email and text
                    messages for full details.
                </p>
            ),
        };
    }
    if (pandoraClaimStatus === ClaimStatus.INVESTIGATION_REQUIRED) {
        return {
            summary: <p>Reviewing your claim</p>,
            description: (
                <p>
                    Sit tight. We&apos;re currently reviewing your claim and will be in touch to
                    update you soon.
                </p>
            ),
        };
    }
    if (pandoraClaimStatus === ClaimStatus.MISSED || pandoraClaimStatus === ClaimStatus.LATE) {
        return {
            summary: <p>We&apos;re working on your claim</p>,
            description: (
                <>
                    <p>
                        If your engineer has already visited - we are waiting to receive their
                        report.
                    </p>
                    <p>
                        If your engineer has not arrived yet, we are contacting them to confirm
                        their arrival.
                    </p>
                </>
            ),
        };
    }
    if (
        pandoraClaimStatus === ClaimStatus.CHECK_IF_JOB_IS_COMPLETE &&
        skeduloJobStatus === JobStatus.EN_ROUTE
    ) {
        return {
            summary: <p>En route</p>,
            description: <p>Your engineer is on their way...</p>,
        };
    }
    if (
        pandoraClaimStatus === ClaimStatus.CHECK_IF_JOB_IS_COMPLETE &&
        skeduloJobStatus === JobStatus.IN_PROGRESS
    ) {
        return {
            summary: <p>In progress</p>,
            description: (
                <p>
                    Our records show our engineer is at your property. If they have left, we are now
                    working on the next steps of your claim and will be back in touch soon.
                </p>
            ),
        };
    }
    if (pandoraClaimStatus === ClaimStatus.CHECK_SME_HAS_COMPETED_JOB) {
        return {
            summary: <p>Working on your claim</p>,
            description: (
                <>
                    <p>
                        Our partner is currently working on your claim and will be in touch with you
                        directly.
                    </p>
                    <p>
                        If your claim has been resolved, we&apos;re waiting on the final report and
                        will be in touch soon.
                    </p>
                </>
            ),
        };
    }
    if (
        pandoraClaimStatus === ClaimStatus.CHECK_PMS_HAS_COMPLETED_CLAIM &&
        abortReason === AbortReason.SENT_TO_PMS
    ) {
        return {
            summary: <p>Working on your claim</p>,
            description: (
                <>
                    <p>
                        If your claim is still in progress, our partner is working on it and will be
                        in touch with you soon.
                    </p>
                    <p>
                        If your claim has been resolved, we&apos;re waiting on the final report from
                        our partner and will be back in touch shortly with the outcome.
                    </p>
                </>
            ),
        };
    }

    if (
        pandoraClaimStatus === ClaimStatus.CLOSED &&
        (abortReason === AbortReason.SENT_TO_YR || abortReason === AbortReason.SENT_TO_YR_DEV) // typo in SK dev
    ) {
        return {
            summary: <p>Working on your claim</p>,
            description: (
                <>
                    <p>
                        Our partner is currently working on your claim and will be in touch with you
                        directly.
                    </p>
                    <p>
                        If your claim has been resolved, we&apos;re waiting on the final report and
                        will be in touch soon.
                    </p>
                </>
            ),
        };
    }

    if (pandoraClaimStatus === ClaimStatus.CLOSED) {
        return {
            summary: <p>Completed claim</p>,
            description: (
                <>
                    <p>Your claim has been completed.</p>
                    <p>
                        If you are still having issues following your repair, or the problem has
                        occurred again please log a new request for a repair.
                    </p>
                </>
            ),
        };
    }

    // eslint-disable-next-line no-console
    console.error(
        JSON.stringify({
            message: 'Unhandled job and claim status combination',
            data: { claimStatus: pandoraClaimStatus, jobStatus: skeduloJobStatus, abortReason },
            level: 'ERROR',
        })
    );

    return {
        summary: <p>We&apos;re reviewing your claim</p>,
        description: (
            <p>We are currently reviewing your claim and will be in touch to update you shortly.</p>
        ),
    };
};
