import { Dispatch, SetStateAction } from 'react';
import { LoginError } from '../../types/LoginErrors';

const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const postcodeRegex =
    /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/;
export const formatPostcode = (postcode) => {
    const captialisedPostcode = postcode.replace(/\s/g, '').toUpperCase();
    return captialisedPostcode.replace(/.{3}$/, ' $&');
};

class Validator {
    static validateEmail(email) {
        if (email === '') return true;
        return emailRegex.test(email);
    }

    static validatePostcode(postcode) {
        if (postcode === '') return true;
        return postcodeRegex.test(postcode.trim());
    }
}

export const handlePostcodeValidation = (
    postcode: string,
    error: string,
    setError: Dispatch<SetStateAction<string>>
) => {
    const isPostcodeValid = Validator.validatePostcode(postcode);

    if (!isPostcodeValid) {
        setError(LoginError.INVALID_POSTCODE);
        return;
    }

    if (error === LoginError.INVALID_POSTCODE) {
        setError('');
    }
};

export const handleEmailValidation = (
    email: string,
    error: string,
    setError: Dispatch<SetStateAction<string>>
) => {
    const isEmailValid = Validator.validateEmail(email);

    if (!isEmailValid) {
        setError(LoginError.INVALID_EMAIL);
        return;
    }

    if (error === LoginError.INVALID_EMAIL) {
        setError('');
    }
};

export default Validator;
