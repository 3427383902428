import {
    getAuthenticatedUser,
    getTokenFromAuthenticatedUser,
    getAttributesFromAuthenticatedUser,
} from 'src/features/login';
import Ajv from 'ajv';
import { getMandatoryEnvVar } from 'src/utils/env';
import { ACCOUNT_ENDPOINT } from 'src/constants';
import { isPandoraErrorResponse } from 'src/types/pandora';
import { Account, getAccountResponseSchema } from './getAccount.types';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

export const getAccount = async (): Promise<Account> => {
    try {
        const user = await getAuthenticatedUser();
        const token = getTokenFromAuthenticatedUser(user);
        const attributes = getAttributesFromAuthenticatedUser(user);
        const accountId = attributes['custom:account_id'];

        const response = await fetch(`${API_BASE_URL}/${ACCOUNT_ENDPOINT}/accounts/${accountId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
        const accountResponse = await response.json();
        const ajv = new Ajv({ coerceTypes: true });
        const validator = ajv.compile(getAccountResponseSchema);
        const valid = validator(accountResponse);

        if (!valid) {
            throw new Error(
                `Error in validating accountResponse: Error in ${JSON.stringify(
                    validator.errors?.[0].schemaPath
                )} - ${JSON.stringify(validator.errors?.[0].params)}`
            );
        }

        if (isPandoraErrorResponse(accountResponse)) {
            throw new Error(accountResponse.message);
        }

        return accountResponse.data[0];
    } catch (err) {
        throw new Error(`Error in job details: ${err}`);
    }
};
