import { useState } from 'react';
import Button from 'src/components/Button';
import style from './index.module.scss';
import { Config, Option } from '../../../types/Question';
import { FormState } from '../../../types/Form';

export interface Props {
    title: string;
    config: Config;
    options: Option[];
    formState: Partial<FormState>;
    onSubmit: (value: string, nextQuestion?: string) => void;
    isOptional?: boolean;
    defaultValue?: string;
}

const DropdownQuestion = ({
    title,
    config,
    options,
    formState,
    onSubmit,
    isOptional,
    defaultValue,
}: Props) => {
    const dropdownOptions = config?.options || [];
    const validDefaultValue = dropdownOptions.find(
        (option) => option.value === defaultValue
    )?.value;
    const [selectedValue, setSelectedValue] = useState(validDefaultValue || '');
    const [otherValue, setOtherValue] = useState('');

    const onDropdownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = e.target;
        if (value) {
            setSelectedValue(value);
            setOtherValue('');
        }
    };

    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const isValidForm = () => {
        const newErrors: { [key: string]: string } = {};

        if (!selectedValue && !isOptional) {
            newErrors.noValue = 'Please select a valid option.';
        }

        if (selectedValue === 'Other' && !otherValue) {
            newErrors.noOtherValue = 'Please enter details.';
        }

        if (config.text?.fieldLength && otherValue.length > config.text.fieldLength) {
            newErrors.text = `Field must be less than ${config.text.fieldLength + 1} characters`;
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const validationError = (
        <div className={style.validationError} role="alert">
            {Object.entries(errors).map(([key, error]) => (
                // eslint-disable-next-line react/no-danger
                <p key={`each-error-${key}`} dangerouslySetInnerHTML={{ __html: error }} />
            ))}
        </div>
    );

    return (
        <>
            <span className={style.title}>{title}</span>
            <select
                className={style.select}
                defaultValue={selectedValue}
                onChange={onDropdownChange}
            >
                <option value="" disabled>
                    Please select your option
                </option>
                {dropdownOptions.map(({ value, copy }) => {
                    return (
                        <option key={value} value={value}>
                            {copy || value}
                        </option>
                    );
                })}
            </select>

            {selectedValue === 'Other' && (
                <>
                    <h3>{config.otherConfig?.question}</h3>
                    <textarea
                        name="text"
                        value={otherValue}
                        rows={4}
                        cols={20}
                        className={`${style.textArea} ${
                            Object.keys(errors).length ? style.errorBorder : ''
                        }`}
                        onChange={(e) => setOtherValue(e.target.value)}
                    />
                </>
            )}

            {errors && validationError}

            {options.map(({ value, nextQuestion, nextQuestionSelector }) => {
                return (
                    <Button
                        customStyle={style.button}
                        key={value}
                        type="button"
                        onClick={() => {
                            const hasErrors = !isValidForm();
                            if (!hasErrors) {
                                onSubmit(
                                    otherValue || selectedValue,
                                    nextQuestionSelector
                                        ? nextQuestionSelector(selectedValue, formState)
                                        : nextQuestion
                                );
                            }
                        }}
                    >
                        {value}
                    </Button>
                );
            })}
        </>
    );
};

export default DropdownQuestion;
