import Loader from 'src/components/Loader';
import styles from './index.module.scss';

const StageLoader = () => {
    return (
        <>
            <div className={styles.stageLoader}>
                <Loader />
            </div>
        </>
    );
};

export default StageLoader;
