/* eslint-disable no-console */
import { captureSentryInfo } from 'src/features/login/utils/captureSentryError';
import { FormState, Part } from 'src/features/closeJob/types/Form';
import { COST_OF_JOB_PER_HOUR, MAX_NUMBER_OF_PARTS, MAX_TOTAL_COST_FIX } from 'src/constants';
import { Account } from 'src/features/account/engineer/profile/api/getAccount.types';
import { isTooManyVisits } from './jobs';
import { canSendToManufacturer } from './canSendToManufacturer';

export interface DetermineFollowOnIsAuthorisedInput {
    numberOfParts: number;
    labourCost: number;
    totalCostOfFix: number;
    boilerMake: string | undefined;
    maxLabourCost: number;
    previousEngineerVisits: number;
    partsRequested: Part[];
    issueArea: string | undefined;
    manuallyAuthorised: string | null;
}

const generateSentryInfoLog = (
    value: DetermineFollowOnIsAuthorisedInput,
    unauthorisedReason?: FollowOnUnauthorisedReason
) => {
    return {
        params: {
            numberOfParts: value.numberOfParts,
            labourCost: value.labourCost,
            totalCostOfFix: value.totalCostOfFix,
            boilerMake: value.boilerMake || 'undefined',
            maxLabourCost: value.maxLabourCost,
            previousEngineerVisits: value.previousEngineerVisits,
            partsRequested: JSON.stringify(value.partsRequested),
            issueArea: value.issueArea || 'undefined',
            ...(unauthorisedReason && { unauthorisedReason }),
        },
    };
};

const calculateMinimumTotalCost = (value: DetermineFollowOnIsAuthorisedInput): number => {
    return value.totalCostOfFix - value.labourCost + 0.5 * COST_OF_JOB_PER_HOUR;
};

enum FollowOnUnauthorisedReason {
    TOO_MANY_VISITS = 'Too many visits',
    TOO_MANY_PARTS = 'Too many parts requested',
    SEND_TO_MANUFACTURER = 'Send to manufacturer',
    LABOUR_CAN_BE_LOWER = 'Labour can be lower',
    LABOUR_TOO_HIGH = 'Labour requested is too high',
    TOTAL_COST_TOO_HIGH = 'Total cost of fix is too high',
    MANUAL_REJECTION = 'Manual rejection',
}

interface FollowOnAuthorisedResult {
    authorised: boolean;
    unauthorisedReason?: FollowOnUnauthorisedReason;
}

export const determineFollowOnIsAuthorised = (
    value: DetermineFollowOnIsAuthorisedInput,
    state: Partial<FormState>,
    accountData: Account | undefined
): FollowOnAuthorisedResult => {
    if (value.manuallyAuthorised === 'Yes') {
        return { authorised: true };
    }

    if (value.manuallyAuthorised === 'No') {
        return {
            authorised: false,
            unauthorisedReason: FollowOnUnauthorisedReason.MANUAL_REJECTION,
        };
    }

    if (isTooManyVisits(value.previousEngineerVisits)) {
        return {
            authorised: false,
            unauthorisedReason: FollowOnUnauthorisedReason.TOO_MANY_VISITS,
        };
    }

    if (
        canSendToManufacturer({
            partsRequested: value.partsRequested,
            boilerMake: value.boilerMake || '',
            issueArea: value.issueArea || '',
            totalCostOfFix: value.totalCostOfFix,
            maxTotalCostFix: MAX_TOTAL_COST_FIX,
        })
    ) {
        return {
            authorised: false,
            unauthorisedReason: FollowOnUnauthorisedReason.SEND_TO_MANUFACTURER,
        };
    }

    if (value.labourCost > value.maxLabourCost) {
        if (calculateMinimumTotalCost(value) > MAX_TOTAL_COST_FIX) {
            return {
                authorised: false,
                unauthorisedReason: FollowOnUnauthorisedReason.LABOUR_TOO_HIGH,
            };
        }

        if (state.warningLabourCostShown) {
            return {
                authorised: false,
                unauthorisedReason: FollowOnUnauthorisedReason.LABOUR_TOO_HIGH,
            };
        }

        return {
            authorised: false,
            unauthorisedReason: FollowOnUnauthorisedReason.LABOUR_CAN_BE_LOWER,
        };
    }

    // Within authorisation limit of engineer?
    if (
        (accountData?.relations?.engineer?.[0]?.authorisation_limit &&
            value.totalCostOfFix > accountData?.relations?.engineer?.[0]?.authorisation_limit) ||
        0
    ) {
        if (calculateMinimumTotalCost(value) > MAX_TOTAL_COST_FIX) {
            return {
                authorised: false,
                unauthorisedReason: FollowOnUnauthorisedReason.LABOUR_TOO_HIGH,
            };
        }

        if (state.warningLabourCostShown) {
            return {
                authorised: false,
                unauthorisedReason: FollowOnUnauthorisedReason.LABOUR_TOO_HIGH,
            };
        }

        return {
            authorised: false,
            unauthorisedReason: FollowOnUnauthorisedReason.LABOUR_CAN_BE_LOWER,
        };
    }

    if (value.numberOfParts > MAX_NUMBER_OF_PARTS) {
        return {
            authorised: false,
            unauthorisedReason: FollowOnUnauthorisedReason.TOO_MANY_PARTS,
        };
    }

    return { authorised: true };
};

export const determineNextFollowOnAuthorisationStep = (
    value: DetermineFollowOnIsAuthorisedInput,
    state: Partial<FormState>,
    accountData: Account | undefined
) => {
    const { authorised, unauthorisedReason } = determineFollowOnIsAuthorised(
        value,
        state,
        accountData
    );

    if (authorised) {
        captureSentryInfo('Job authorised', generateSentryInfoLog(value));
        return 'partsSource';
    }

    switch (unauthorisedReason) {
        case FollowOnUnauthorisedReason.LABOUR_CAN_BE_LOWER:
            captureSentryInfo(
                'Job not authorised and engineer prompted to reduce labour',
                generateSentryInfoLog(value)
            );

            return 'canLabourCostBeLowered';
        case FollowOnUnauthorisedReason.SEND_TO_MANUFACTURER:
            captureSentryInfo(
                'Send job to manufacturer, investigation required',
                generateSentryInfoLog(value)
            );
            return 'sendJobToManufacturerPhotos';
        case FollowOnUnauthorisedReason.MANUAL_REJECTION:
            captureSentryInfo(
                'Job not authorised',
                generateSentryInfoLog(value, unauthorisedReason)
            );
            return 'partsAvailablePhotosOfFault';
        case FollowOnUnauthorisedReason.LABOUR_TOO_HIGH:
        case FollowOnUnauthorisedReason.TOO_MANY_PARTS:
        case FollowOnUnauthorisedReason.TOO_MANY_VISITS:
        case FollowOnUnauthorisedReason.TOTAL_COST_TOO_HIGH:
        default:
            captureSentryInfo(
                'Job not authorised',
                generateSentryInfoLog(value, unauthorisedReason)
            );
            return 'costAuthorisation';
    }
};
