import { CancelX } from 'src/images';
import style from './Tooltip.module.scss';

interface Props {
    setShowToolTip: (boolean) => void;
}

const Tooltip = ({ setShowToolTip }: Props) => {
    return (
        <div className={style.container}>
            <button
                type="button"
                onClick={() => setShowToolTip(false)}
                className={style.closeButton}
            >
                <CancelX title="icon for closing modal" />
            </button>{' '}
            <p className={style.heading}>
                <span>To avoid payment delays, please upload photos showing:</span>
            </p>
            <ol>
                <li>Condition of affected appliance</li>
                <li>Faulty parts, before & after removal</li>
                <li>New parts, when fitted </li>
                <li>Additional work to do job (e.g. drain system)</li>
                <li>Receipts for parts fitted above £70</li>
            </ol>
        </div>
    );
};

export default Tooltip;
