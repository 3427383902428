import { RepairBlack, X, Edit } from 'src/images';
import { formatPounds, getNetCost, getVatCost } from 'src/utils/prices';
import Button from 'src/components/Button';
import style from './PartInformation.module.scss';

interface Props {
    name: string;
    quantity: number;
    estimatedCost: number | null | undefined;
    actualCost: number | null | undefined;
    isVatRegistered?: boolean;
    onRemove: () => void;
    onEdit: () => void;
}

const PartInformation = ({
    name,
    quantity,
    estimatedCost,
    actualCost,
    onRemove,
    onEdit,
    isVatRegistered = false,
}: Props) => {
    const partCost = estimatedCost || actualCost || 0;

    return (
        <article
            className={style.container}
            aria-label={`part-info-${name.toLowerCase().replace(' ', '-')}`}
        >
            <div className={style.rowContainer}>
                <div className={style.title}>
                    <RepairBlack />
                    <span className={style.paragraph}>{name}</span>
                </div>
                <Button
                    type="button"
                    ariaLabel={`remove-${name.toLowerCase().replace(' ', '-')}`}
                    onClick={onRemove}
                    customStyle={style.removeButton}
                >
                    <X />
                </Button>
            </div>
            <div className={style.partInfo}>
                <span>Net parts cost: </span>
                <span className={style.paragraph}>
                    £{formatPounds(isVatRegistered ? getNetCost(partCost) : partCost)}
                </span>
            </div>
            <div className={style.partInfo}>
                <span>VAT: </span>
                <span className={style.paragraph}>
                    {isVatRegistered
                        ? `£${formatPounds(getVatCost(partCost))} (20%)`
                        : '£0 - not VAT registered'}
                </span>
            </div>
            <div className={style.rowContainer}>
                <div className={style.partInfo}>
                    <span>Quantity: </span>
                    <span className={style.paragraph}>{quantity}</span>
                </div>
                <div>
                    <Button
                        type="button"
                        ariaLabel={`edit-${name.toLowerCase().replace(' ', '-')}`}
                        onClick={onEdit}
                        customStyle={style.removeButton}
                    >
                        <Edit />
                    </Button>
                </div>
            </div>
        </article>
    );
};

export default PartInformation;
