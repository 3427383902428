import { Navigate } from 'react-router-dom';
import ErrorBar from 'src/components/ErrorBar';
import { HOMETREE_PHONE_NUMBER } from 'src/constants';
import Loader from '../../../components/Loader';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { Role } from '../../../types/Role';
import { Routes as RoutePath } from '../../../types/Routes';
import { getUserRole } from '../../../utils/getUserRole';
import styles from './index.module.scss';

export const Landing = () => {
    const { isLoading, data } = useAuthenticatedUser();

    if (isLoading) {
        return <Loader />;
    }
    if (!data) {
        const defaultErrorMessage = `We're having trouble getting your account information. If this message persists please call ${HOMETREE_PHONE_NUMBER}`;
        return <ErrorBar message={defaultErrorMessage} style={styles.errorBar} />;
    }

    const role = getUserRole(data);
    switch (role) {
        case Role.CUSTOMER:
            return <Navigate to={RoutePath.DASHBOARD} replace />;
        case Role.ENGINEER:
            return <Navigate to={RoutePath.JOB_AGENDA} replace />;
        default:
            return <Navigate to={RoutePath.DASHBOARD} replace />;
    }
};
