import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { addHours } from 'date-fns';
import { Routes } from 'src/types/Routes';
import { Attention, VulnerableIcon, GasIcon } from 'src/images';
import Layout from 'src/components/Layout';
import Button from 'src/components/Button';
import toastConfig from 'src/utils/toastConfig';
import Carousel from 'src/components/Carousel';
import { useGetJobOffers } from 'src/features/jobOffers/hooks/useGetJobOffers';
import getCustomerAvailability from 'src/utils/getCustomerAvailaibility';
import { mixpanelTrackPage } from 'src/utils/mixpanel';
import { newVirtualPageView } from '../../../../utils/analytics';
import style from './index.module.scss';
import { acceptJobOffer } from '../../api/acceptJobOffer';
import { declineJobOffer } from '../../api/declineJobOffer';
import Calendar from '../Calendar';
import Loader from '../../../../components/Loader';
import ErrorBar from '../../../../components/ErrorBar';

const JobOfferDetails = () => {
    const {
        isError: isErrorLoadingOffers,
        isLoading: isOffersLoading,
        data: offersData,
    } = useGetJobOffers();
    const { jobOfferId } = useParams<{ jobOfferId: string }>();
    const jobOffer = offersData?.jobOffers.find(
        (offer) => offer.resource_job_offer_id === jobOfferId
    );
    const [isAccepting, setIsAccepting] = useState(false);
    const [isDeclining, setIsDeclining] = useState(false);
    const [acceptedDate, setAcceptedDate] = useState('');
    const navigate = useNavigate();

    const pageCount = 0;
    const location = useLocation();

    const customerAvailability = getCustomerAvailability(
        jobOffer?.estimated_start,
        jobOffer?.customer_availability
    );

    useEffect(() => {
        newVirtualPageView('offerDetail', location.pathname);
        mixpanelTrackPage();
        if (jobOffer && customerAvailability.length === 0) {
            setAcceptedDate(jobOffer.estimated_start);
        }
        // Temp fix for incident https://hometree.atlassian.net/browse/BNW-2716
        // when the customer chose the latest date as first option in the booking tool
        if (jobOffer && customerAvailability.length === 1) {
            setAcceptedDate(customerAvailability[0]);
        }
    }, [pageCount, location, jobOffer, customerAvailability]);

    const onAcceptButtonClick = async () => {
        if (jobOffer) {
            try {
                setIsAccepting(true);
                await acceptJobOffer(jobOffer.resource_job_offer_id, acceptedDate);
                toast.success(`Job "${jobOffer?.summary}" accepted successfully.`, toastConfig);
                setIsAccepting(false);
                navigate(Routes.JOB_AGENDA);
            } catch (e) {
                toast.error(`Job "${jobOffer?.summary}" could not be accepted.`, toastConfig);
                setIsAccepting(false);
            }
        }
    };

    const onDeclineButtonClick = async () => {
        if (jobOffer) {
            setIsDeclining(true);
            await declineJobOffer(jobOffer.resource_job_offer_id);
            toast.success(`Job "${jobOffer?.summary}" declined.`, toastConfig);
            setIsDeclining(false);
            navigate(Routes.JOB_AGENDA);
        }
    };

    if (isOffersLoading) {
        return <Loader />;
    }

    if (isErrorLoadingOffers || typeof offersData === 'undefined') {
        return (
            <ErrorBar message="An error occured whilst fetching your offers, please contact administration" />
        );
    }

    if (!jobOffer) {
        return (
            <Layout>
                <p className={style.jobOfferGoneText}>Sorry - this job has gone!</p>
                <p className={style.jobOfferGoneText}>Another engineer accepted it sooner.</p>
                <Button
                    onClick={() => navigate(Routes.JOB_OFFERS)}
                    customStyle={style.jobOfferGoneButton}
                >
                    View other offers
                </Button>
            </Layout>
        );
    }

    return (
        <Layout>
            <div className={style.jobOfferDetails}>
                <div>
                    <p className={style.jobDetailsText}>Job Details</p>
                    <p className={style.summary}>{jobOffer.summary}</p>
                    <div className={style.tags}>
                        <div className={style.tag}>
                            <span>
                                <GasIcon role="img" title="Gas Icon" />
                            </span>
                            <span>{jobOffer.job_type}</span>
                        </div>
                        {jobOffer.urgency && (
                            <div className={style.tag}>
                                <span>
                                    <Attention role="img" title="Warning icon" />
                                </span>
                                <span>{jobOffer.urgency}</span>
                            </div>
                        )}
                        {jobOffer.vulnerable_customer === 'Yes' && (
                            <div className={style.tag}>
                                <span>
                                    <VulnerableIcon role="img" title="Customer icon" />
                                </span>
                                <span>Vulnerable Customer</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {Array.isArray(customerAvailability) && customerAvailability.length > 1 ? (
                <div className={style.customerAvailabilityContainer}>
                    <p className={style.title}>Customer availability</p>
                    <p className={style.subtitle}>Please select a slot</p>
                    <div className={style.dates}>
                        <Carousel>
                            {customerAvailability.map((availability) => (
                                <div
                                    className={style.dateWrapper}
                                    role="button"
                                    tabIndex={0}
                                    onClick={() => setAcceptedDate(availability)}
                                    onKeyDown={() => () => setAcceptedDate(availability)}
                                    key={availability}
                                >
                                    <Calendar
                                        start={availability}
                                        end={addHours(new Date(availability), 4).toISOString()}
                                        isChecked={availability === acceptedDate}
                                    />
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
            ) : (
                <Calendar start={jobOffer.estimated_start} end={jobOffer.estimated_end} isChecked />
            )}
            <div className={style.actionsContainer}>
                <Button
                    onClick={onDeclineButtonClick}
                    customStyle={style.actionButton}
                    isDisabled={isDeclining}
                    variant="secondary"
                    data-testid="decline-button"
                >
                    {isDeclining ? 'Declining...' : 'Decline'}
                </Button>
                <Button
                    onClick={onAcceptButtonClick}
                    customStyle={style.actionButton}
                    isDisabled={isAccepting || !acceptedDate}
                    data-testid="accept-button"
                >
                    {isAccepting ? 'Accepting...' : 'Accept'}
                </Button>
            </div>
        </Layout>
    );
};

export default JobOfferDetails;
