import mixpanel, { Dict } from 'mixpanel-browser';

// mixpanel should only be on in prod, however use REACT_APP_MIXPANEL_ENABLED
// for limited local testing as to not affect the stats
const isMixpanelEnabled = () => {
    const envCheck = process.env.REACT_APP_ENVIRONMENT === 'prod';
    const mixPanelEnabled = process.env.REACT_APP_MIXPANEL_ENABLED === 'true';

    if (envCheck || mixPanelEnabled) {
        return true;
    }
    return false;
};

const mixpanelEnabled = isMixpanelEnabled();

export const initializeMixpanel = (): void => {
    if (!mixpanelEnabled) {
        return;
    }

    // add debug: true for local debugging to see in the console what is
    // being sent to mixpanel
    mixpanel.init('3933b10c52f65c811d829dfa8bc22be3', {
        track_pageview: true,
        persistence: 'localStorage',
    });
};

// always wrap mixpanel functions as we want to always make sure
// we only have it on in prod
export const mixpanelTrack = (eventName: string, eventProps?: Dict): void => {
    if (mixpanelEnabled) {
        mixpanel.track(eventName, eventProps);
    }
};

export const mixpanelTrackPage = (): void => {
    if (mixpanelEnabled) {
        mixpanel.track_pageview();
    }
};
