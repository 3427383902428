import { JOBS_PUBLIC_ENDPOINT } from 'src/constants';
import { getMandatoryEnvVar } from 'src/utils/env';
import { errorToJson } from 'src/utils/error';
import { post } from 'src/utils/http';
import { captureSentryError } from 'src/features/login/utils/captureSentryError';
import { generateAuthHeader } from '../../../utils/auth';
import { FormState } from '../types/Form';

export type CloseJobFormData = FormState & {
    jobId: string;
};

export type CloseJobResponse = {
    message: string;
};

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

export const closeJob = async (formData: CloseJobFormData): Promise<CloseJobResponse> => {
    const url = `${API_BASE_URL}/${JOBS_PUBLIC_ENDPOINT}/v1/jobs/close`;

    try {
        const response = await post<CloseJobFormData, CloseJobResponse>({
            url,
            body: formData,
            headers: {
                ...(await generateAuthHeader()),
            },
        });

        return response;
    } catch (error) {
        captureSentryError(error, {
            message: 'Error closing job',
            errorStringified: JSON.stringify(errorToJson(error)),
        });
        throw new Error('Failed to complete job');
    }
};
