import { ErrorMessage } from 'src/utils/copy';
import { format, add } from 'date-fns';
import style from './index.module.scss';
import { useGetJobAgenda } from '../../hooks/useGetJobAgenda';
import Loader from '../../../../components/Loader';
import ErrorBar from '../../../../components/ErrorBar';
import JobList from '../JobList';

const AgendaUpcoming = () => {
    const today = format(add(new Date(), { days: 1 }), 'yyyy-MM-dd');
    const weekFromNow = format(add(new Date(), { days: 30 }), 'yyyy-MM-dd');
    const { isError, isLoading, data } = useGetJobAgenda('jobAgendaUpcoming', today, weekFromNow);

    return (
        <section className={style.jobAgenda}>
            {isLoading && <Loader />}

            {isError && <ErrorBar message={ErrorMessage.ENGINEER} />}

            {!isLoading && !isError && data && (
                <div className={style.jobAgendaList} data-testid="job-offers-list">
                    <JobList jobs={data?.jobs} />
                </div>
            )}
        </section>
    );
};

export default AgendaUpcoming;
