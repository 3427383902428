import { useQuery } from 'react-query';
import { getAppliancesMakes } from 'src/features/newClaim/api/getApplianceMakes';

export const useApplianceMakesQuery = () => {
    const { isLoading, isError, error, data } = useQuery<any, Error, any>(
        ['appliancesMakes'],
        getAppliancesMakes
    );

    return {
        isLoading,
        isError,
        error,
        data: data?.map((appliance) => ({ id: appliance.id, name: appliance.makeName })),
    };
};
