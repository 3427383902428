export interface ExtendedError extends Error {
    response?: {
        data?: any;
        status?: number;
        headers?: any;
    };
    request?: any;
}

export const errorToJson = (e: ExtendedError) => {
    return {
        name: e.name,
        message: e.message,
        stack: e.stack,
        response: e.response?.data,
        status: e.response?.status,
        headers: e.response?.headers,
        request: e.request,
    };
};
