import { Auth } from '../api/amplify';
import { getAuthenticatedUser } from './authenticatedUser';

export const sendAuthenticationRequest = async (user, code: string) => {
    // returns success on incorrect answer until attempts run out which is why
    // we check if an autheticated user exists to see if the answer was correct
    try {
        await Auth.sendCustomChallengeAnswer(user, code);
    } catch (e) {
        throw new Error('Please double check the code we sent you and try again.');
    }
    try {
        const authUser = await getAuthenticatedUser();
        return authUser;
    } catch (e) {
        throw new Error('Please double check the code we sent you and try again.');
    }
};
