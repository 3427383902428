import styles from './index.module.scss';

interface TextInputProps {
    testId?: string;
    header?: string;
    placeholder: string;
    value: string;
    // eslint-disable-next-line no-unused-vars
    onChange: (e: string) => void;
    error?: string;
}

const TextInput = ({
    testId,
    header,
    placeholder,
    value,
    onChange,
    error,
}: TextInputProps): JSX.Element => (
    <div className={`${styles.inputContainer} ${!!error && styles.error}`}>
        {header && <label htmlFor={`input-${testId || 'unknow'}`}>{header}</label>}
        <input
            id={`input-${testId || 'unknow'}`}
            data-testid={testId || ''}
            type="text"
            placeholder={placeholder}
            value={value || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
        />
        {error && <div className={styles.errorMessage}>{error}</div>}
    </div>
);

export default TextInput;
