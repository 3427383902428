import { useState } from 'react';
import Input from 'src/components/Input';
import Button from 'src/components/Button';
import { Option } from '../../../types/Question';
import { FormState, BoilerGaugesValue } from '../../../types/Form';
import style from './index.module.scss';

export interface Props {
    title: string;
    options: Option[];
    onSubmit: (value: BoilerGaugesValue, nextQuestion?: string) => void;
    formState: Partial<FormState>;
    defaultValue?: BoilerGaugesValue;
}

const BoilerGaugesQuestion = ({ title, options, onSubmit, formState, defaultValue }: Props) => {
    const [boilerGauges, setBoilerGauges] = useState<BoilerGaugesValue>({
        gasRateKw: defaultValue?.gasRateKw || undefined,
        burnerPressureMb: defaultValue?.burnerPressureMb || undefined,
    });
    type ErrorType = { gasRateKw?: string; burnerPressureMb?: string };
    const [errors, setErrors] = useState<ErrorType>({});

    const onChange = (key) => (e) => {
        const currentValue = e.target.value;
        setBoilerGauges({ ...boilerGauges, [key]: currentValue });
    };

    const isValidForm = () => {
        const newErrors: ErrorType = {};
        if (boilerGauges.gasRateKw && Number.isNaN(+boilerGauges.gasRateKw)) {
            newErrors.gasRateKw = 'Gas rate must be a number';
        }
        if (boilerGauges.burnerPressureMb && Number.isNaN(+boilerGauges.burnerPressureMb)) {
            newErrors.burnerPressureMb = 'Burner pressure must be a number';
        }
        // TODO think of a regex that matches 5 numbers + 5 decimal places
        if (boilerGauges.gasRateKw && boilerGauges.gasRateKw.toString().length > 11) {
            newErrors.gasRateKw = 'Gas rate must be less than 11 characters';
        }
        if (boilerGauges.burnerPressureMb && boilerGauges.burnerPressureMb.toString().length > 11) {
            newErrors.burnerPressureMb = 'Burner pressure must be less than 11 characters';
        }
        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const validationError = (
        <div className={style.validationError} role="alert">
            <ul>
                {Object.entries(errors).map(([key, error]) => (
                    // eslint-disable-next-line react/no-danger
                    <li key={`each-error-${key}`} dangerouslySetInnerHTML={{ __html: error }} />
                ))}
            </ul>
        </div>
    );

    return (
        <>
            <span className={style.title}>{title}</span>
            <div className={style.inputAndError}>
                <div className={style.formControl}>
                    <label htmlFor="gasRateKwInput">
                        <span>Gas Rate(kW)</span>
                        <Input
                            inputType="number"
                            id="gasRateKwInput"
                            name="gasRateKw"
                            value={(boilerGauges.gasRateKw || '').toString()}
                            onChange={onChange('gasRateKw')}
                            placeholder="Optional"
                            autoFocus
                            ariaLabel="Gas Rate(kW)"
                        />
                    </label>
                </div>

                <div className={style.formControl}>
                    <label htmlFor="burnerPressureInput">
                        <span>Burner Pressure(mb)</span>
                        <Input
                            inputType="number"
                            id="burnerPressureInput"
                            name="burnerPressureMb"
                            value={(boilerGauges.burnerPressureMb || '').toString()}
                            onChange={onChange('burnerPressureMb')}
                            placeholder="Optional"
                        />
                    </label>
                </div>

                {errors && validationError}
            </div>

            {options.map(({ value, nextQuestion, nextQuestionSelector }) => {
                return (
                    <Button
                        customStyle={style.button}
                        key={value}
                        type="button"
                        onClick={() => {
                            const hasErrors = !isValidForm();
                            const submittedValue = {
                                ...(boilerGauges.gasRateKw && {
                                    gasRateKw: +boilerGauges.gasRateKw,
                                }),
                                ...(boilerGauges.burnerPressureMb && {
                                    burnerPressureMb: +boilerGauges.burnerPressureMb,
                                }),
                            };

                            if (!hasErrors) {
                                onSubmit(
                                    submittedValue,
                                    nextQuestionSelector
                                        ? nextQuestionSelector(submittedValue, formState)
                                        : nextQuestion
                                );
                            }
                        }}
                    >
                        {value}
                    </Button>
                );
            })}
        </>
    );
};

export default BoilerGaugesQuestion;
