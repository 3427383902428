import { JSONSchemaType } from 'ajv';
import { JobDetails, jobDetailsSchema } from 'src/types/Job';

export type JobDetailsErrorResponse = {
    message: string;
};

export type JobDetailsResponse = JobDetails | JobDetailsErrorResponse;

export const jobDetailsErrorResponseSchema: JSONSchemaType<JobDetailsErrorResponse> = {
    type: 'object',
    properties: {
        message: { type: 'string' },
    },
    required: ['message'],
};

export const jobDetailsResponseSchema: JSONSchemaType<JobDetailsResponse> = {
    type: 'object',
    anyOf: [jobDetailsSchema, jobDetailsErrorResponseSchema],
    additionalProperties: true,
};

export const isJobDetailsResponse = (input: any): input is JobDetails => {
    return (input as JobDetails).id !== undefined;
};

export const isJobDetailsErrorResponse = (input: any): input is JobDetailsErrorResponse => {
    return (input as JobDetailsErrorResponse).message !== undefined;
};
