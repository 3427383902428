import { CancelCircle } from 'src/images';
import Button from 'src/components/Button';
import style from './index.module.scss';

interface Props {
    setShowCantReschedule: (show: boolean) => void;
}

const RescheduleErrorAlert = ({ setShowCantReschedule }: Props) => (
    <div className={`${style.container} ${style.rescheduleAlertContainer}`}>
        <Button type="button" onClick={() => setShowCantReschedule(false)}>
            <CancelCircle />
        </Button>
        <p>You already rescheduled this visit.</p>
        <p>
            Call us on <a href="tel:+44 330 058 9485">+44 330 058 9485</a> to reschedule again.
        </p>
    </div>
);

export default RescheduleErrorAlert;
