import ReactModal from 'react-modal';
import { CancelX } from 'src/images';
import { CSSProperties } from 'react';
import styles from './index.module.scss';
import Button from '../Button';

export interface ModalProps {
    children: JSX.Element;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    handleOnClick?: () => void;
    handleOnClose?: () => void;
    contentLabel?: string;
    style?: CSSProperties;
    shouldCloseOnOverlayClick?: boolean;
    shouldCloseOnEsc?: boolean;
    preventScroll?: boolean;
    btnText?: string;
    aria?: { [key: string]: string };
    btnClass?: string;
}

// documentation for below: https://reactcommunity.org/react-modal/accessibility/
if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#root');

const Modal = ({
    children,
    isOpen,
    setIsOpen,
    handleOnClick,
    handleOnClose,
    style,
    contentLabel,
    shouldCloseOnOverlayClick = false,
    shouldCloseOnEsc = false,
    preventScroll = true,
    btnText = 'Confirm',
    aria,
    btnClass,
}: ModalProps) => {
    // we want to keep the default styles the library offers
    // so we add the additional styles to the style attribute instead of className
    // see documentation: https://reactcommunity.org/react-modal/styles/
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#e7dcfd',
            padding: '13px 14px 41px 41px',
            maxWidth: '646px',
            borderRadius: '20px',
            ...style,
        },
    };

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            style={customStyles}
            role="dialog"
            closeTimeoutMS={500}
            // contentLabel renders as aria-label
            contentLabel={contentLabel}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            shouldCloseOnEsc={shouldCloseOnEsc}
            preventScroll={preventScroll}
            overlayClassName={styles.overlay}
            // use aria as described here https://reactcommunity.org/react-modal/accessibility/#aria-attributes
            aria={aria}
        >
            <div className={styles.modalContainer}>
                <Button
                    customStyle={styles.cancelBtn}
                    type="button"
                    onClick={() => {
                        if (handleOnClose) handleOnClose();
                        setIsOpen(false);
                    }}
                >
                    <CancelX title="icon for closing modal" />
                </Button>
                {children}
                {handleOnClick ? (
                    <Button variant="primary" onClick={handleOnClick} customStyle={btnClass}>
                        {btnText}
                    </Button>
                ) : null}
            </div>
        </ReactModal>
    );
};

export default Modal;
