import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './style.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './App';
import reportWebVitals from './reportWebVitals';
import initializeSentry from './sentry';
import { initializeMixpanel } from './utils/mixpanel';
import initializeTagManager from './utils/tagManager';

initializeSentry();
initializeMixpanel();
initializeTagManager(process.env.REACT_APP_GTM_ID);

const queryClient = new QueryClient();

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <App />
            </QueryClientProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const authSubmit = document.getElementById('auth_submit');
if (authSubmit) {
    authSubmit.addEventListener('submit', (e) => {
        e.preventDefault();
    });

    authSubmit.addEventListener('click', (e) => {
        e.preventDefault();
    });
}
