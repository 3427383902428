import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'src/components/Button';
import { newAnalyticsEvent } from 'src/utils/analytics';
import {
    CheckCircleRainbow,
    CircleX,
    ErrorAlertIcon,
    InfoIcon,
    PictureIcon,
    Upload,
} from 'src/images';
import style from '../index.module.scss';
import { Option } from '../../../features/closeJob/types/Question';
import Tooltip from './Tooltip';

interface AddPhotosProps {
    title: string;
    fileInputEl: React.RefObject<HTMLInputElement>;
    handleFileInputChange: (e: any) => void;
    handleOnChooseFileClick: () => void;
    files: File[];
    isUploadingFiles: boolean;
    handleFileUpload: () => void;
    handleOnRemoveFileClick: (file: File) => void;
    isCloseJob: boolean;
    isFileTypeError: boolean;
}

export const AddPhotos = ({
    title,
    fileInputEl,
    handleFileInputChange,
    handleOnChooseFileClick,
    files,
    isUploadingFiles,
    handleFileUpload,
    handleOnRemoveFileClick,
    isCloseJob,
    isFileTypeError,
}: AddPhotosProps) => {
    const [showTooltip, setShowToolTip] = useState(false);
    const { jobId } = useParams();

    const handleTooltipClick = () => {
        newAnalyticsEvent({
            category: 'Photos',
            action: 'Opened - guide dialog',
            label: jobId,
            value: jobId || '',
        });
        setShowToolTip(true);
    };
    return (
        <>
            <div className={style.container}>
                <span className={style.title}>{title}</span>
                {isCloseJob && (
                    <button
                        type="button"
                        onClick={handleTooltipClick}
                        className={style.tooltipButton}
                        aria-label="tooltip-button"
                    >
                        <InfoIcon />
                    </button>
                )}
            </div>
            {showTooltip && isCloseJob ? (
                <Tooltip setShowToolTip={setShowToolTip} />
            ) : (
                <>
                    <input
                        type="file"
                        name="file"
                        style={{ display: 'none' }}
                        accept="image/*, application/pdf"
                        data-testid="choose-file-input"
                        ref={fileInputEl}
                        onChange={handleFileInputChange}
                        multiple
                    />
                    <div className={style.browseSection}>
                        <button
                            type="button"
                            onClick={handleOnChooseFileClick}
                            className={style.uploadButton}
                        >
                            <>
                                <Upload title="Arrow up for choosing pictures" role="img" />
                                <p>Choose File</p>
                            </>
                        </button>
                        {!isUploadingFiles && files.length > 0 && (
                            <div className={style.fileList}>
                                <ul>
                                    {files.map((file) => (
                                        <li key={`file-${file.name}`}>
                                            <div>
                                                <PictureIcon title="Picture icon" role="img" />
                                                <span>{file.name}</span>
                                            </div>
                                            <Button
                                                type="button"
                                                onClick={() => handleOnRemoveFileClick(file)}
                                                data-testid="remove-file-button"
                                            >
                                                <CircleX
                                                    title="icon for removing file"
                                                    role="img"
                                                />
                                            </Button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                    {isFileTypeError && (
                        <p className={style.errorText}>
                            Unfortunately, we don&apos;t support this image file type. Please,
                            retake the photo and attempt the upload again. If the problem persists
                            please call <a href="tel:0330 058 9485">0330 058 9485</a>
                        </p>
                    )}
                    {files.length > 0 ? (
                        <Button
                            customStyle={style.button}
                            onClick={() => handleFileUpload()}
                            isDisabled={isFileTypeError}
                        >
                            Upload
                        </Button>
                    ) : (
                        <Button
                            customStyle={style.button}
                            onClick={() => setShowToolTip(true)}
                            isDisabled={!isCloseJob && files.length === 0}
                        >
                            Next
                        </Button>
                    )}
                </>
            )}
        </>
    );
};

interface UploadingFilesProps {
    uploadingText: string;
    progress: number;
    files: File[];
}

export const UploadingFiles = ({ uploadingText, progress, files }: UploadingFilesProps) => (
    <>
        <h3 className={style.title}>Uploading...</h3>
        <p className={style.subtitle}>Just give us a moment to process your file(s).</p>
        <p className={style.percentageText}>{Math.round((progress / files.length) * 100)}%</p>
        <div className={style.progressContainer}>
            <div className={style.progressBar} />
        </div>
        {uploadingText && (
            <p className={style.uploadingText} data-testid="uploading-text">
                {uploadingText}
            </p>
        )}
    </>
);

interface UploadSuccessfulProps {
    files: File[];
    options?: Option[];
    fileUrls: string[];
    onSubmit?: (fileUrls: string[], nextQuestion?: string) => void;
}

export const UploadSuccessful = ({ files, options, fileUrls, onSubmit }: UploadSuccessfulProps) => (
    <>
        <p className={style.uploadSuccessfulSecion}>
            <CheckCircleRainbow title="Upload complete checkmark icon" role="img" />
            <span className={style.title}>Upload Successful!</span>
        </p>
        <p className={style.subtitle}>Your file has been uploaded.</p>
        {files.length > 0 &&
            options &&
            onSubmit &&
            options.map(({ value, nextQuestion }) => {
                return (
                    <Button
                        onClick={() => {
                            if (nextQuestion) {
                                onSubmit(fileUrls, nextQuestion);
                            }
                        }}
                        customStyle={style.button}
                        key={value}
                    >
                        {value}
                    </Button>
                );
            })}
    </>
);

interface ErrorStateProps {
    files: File[];
    handleFileUpload: () => void;
}

export const ErrorState = ({ files, handleFileUpload }: ErrorStateProps) => (
    <>
        <ErrorAlertIcon title="Alert icon" role="img" />
        <span className={style.title}>An error occured</span>
        <p className={style.subtitle}>Your file has not been uploaded.</p>
        {files.length > 0 && (
            <Button customStyle={style.button} onClick={() => handleFileUpload()}>
                Try again
            </Button>
        )}
    </>
);
