import { JSONSchemaType } from 'ajv';
import { PandoraErrorResponse, pandoraErrorResponseSchema } from 'src/types/pandora';

export enum JobStatus {
    COMPLETE = 'Complete',
    CANCELLED = 'Cancelled',
    PENDING_ALLOCATION = 'Pending Allocation',
    PENDING_DISPATCH = 'Pending Dispatch',
    IN_PROGRESS = 'In Progress',
    READY = 'Ready',
    EN_ROUTE = 'En Route',
    QUEUED = 'Queued',
    DISPATCHED = 'Dispatched',
}

export enum ClaimStatus {
    SCHEDULE_INITIAL_OR_REATTEND = 'Schedule Initial Visit or Re-attend',
    ENGINEER_TO_SCHEDULE_FOLLOW_ON = 'Engineer to Schedule Follow-On',
    INVESTIGATION_REQUIRED = 'Investigation Required',
    CONFIRM_ENGINEER_AND_CUSTOMER_AVAILABILITY = 'Confirm Engineer and Customer Availability',
    ENGINEER_TO_ACCEPT_JOB = 'Engineer to Accept Job',
    SENT_TO_SME = 'Sent to SME',
    UPCOMING = 'Upcoming',
    LATE = 'Late',
    MISSED = 'Missed',
    CHECK_IF_JOB_IS_COMPLETE = 'Check if Job is Complete',
    CHECK_SME_HAS_COMPETED_JOB = 'Check SME has Completed Job',
    CLOSED = 'Closed',
    UNKNOWN = 'Unknown',
    ORDER_PARTS = 'Order Parts',
    HOMETREE_NOT_FIXING = 'Hometree Not Fixing',
    CHECK_PMS_HAS_COMPLETED_CLAIM = 'Check PMS has completed claim',
    SPECIAL_PARTS_REQUIRED = 'Special Parts Required',
    PENDING_INSURER_APPROVAL = 'Pending Insurer Approval',
}

export enum AbortReason {
    CANCELLED_BY_CUSTOMER = 'Cancelled by customer',
    APPOINTMENT_MISSED = 'Appointment missed',
    CUSTOMER_NO_SHOW = 'Customer no show',
    SENT_TO_PMS = 'Sent to PMS',
    UNABLE_TO_COMPLETE_JOB_ONSITE = 'Unable to complete job onsite',
    CANNOT_ATTEND_JOB = 'Cannot attend job',
    ENGINEER_NO_SHOW = 'Engineer no show',
    SENT_TO_YR = 'Sent to Your Repair',
    SENT_TO_YR_DEV = 'Sent to your Repair', // lowercase typo in SK dev
    TEST_JOB = 'Test job',
    REPUDIATED_CLAIM = 'Repudiated Claim',
    REPUDIATED_FNOL = 'Repudiated FNOL',
    SENT_TO_SME = 'Sent to SME',
    REJECTED_CLAIM = 'Rejected Claim',
    REJECTED_CLAIM_PROD = 'Rejected claim', // lowercase typo in SK prod
    REJECTED_FNOL = 'Rejected FNOL',
    CUSTOMER_NOT_HOME = 'Customer not home',
    ENGINEER_ILLNESS = 'Engineer illness',
    PERSONAL_EMERGENCY = 'Personal emergency',
    WORK_EMERGENCY = 'Work emergency',
    PARTS_NOT_READY = 'Parts not ready',
    VAN_BREAKDOWN = 'Van breakdown',
    FPR_REQUIRED = 'FPR required',
    DUPLICATE = 'Duplicate',
    CUSTOMER_UNREACHABLE = 'Customer unreachable',
    REALLOCATION_PROD = 'Reallocation',
    REALLOCATION = 'Re-allocation',
    OTHER = 'Other',
    REJECTED_BY_INSURER = 'Rejected by insurer',
}

export interface Job {
    sk_job_status: JobStatus;
    sk_abort_reason?: AbortReason | null;
    claim_status_id: number;
    claim_status_name: ClaimStatus;
}

export type GetPandoraJobResponse = Job;

export type GetContractResponse = GetPandoraJobResponse | PandoraErrorResponse;

export const jobSchema: JSONSchemaType<Job> = {
    type: 'object',
    properties: {
        sk_job_status: {
            type: 'string',
            enum: Object.values(JobStatus) as readonly JobStatus[],
        },
        sk_abort_reason: {
            type: 'string',
            enum: [...Object.values(AbortReason), null] as readonly AbortReason[],
            nullable: true,
        },
        claim_status_id: { type: 'number' },
        claim_status_name: {
            type: 'string',
            enum: Object.values(ClaimStatus) as readonly ClaimStatus[],
        },
    },
    required: ['claim_status_id', 'claim_status_name', 'sk_job_status'],
};

export const getJobResponseSchema: JSONSchemaType<GetContractResponse> = {
    type: 'object',
    anyOf: [jobSchema, pandoraErrorResponseSchema],
    additionalProperties: true,
};

export const isGetContractResponse = (input: any): input is GetPandoraJobResponse => {
    return (input as GetPandoraJobResponse).sk_job_status !== undefined;
};
