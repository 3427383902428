import { ACCOUNT_ENDPOINT } from 'src/constants';
import { getMandatoryEnvVar } from 'src/utils/env';
import { post } from 'src/utils/http';

export type NoEmailAuthentication = {
    code: string;
    postcode: string;
    surname: string;
};

export type NoEmailAuthenticationResponse = {
    message: string;
};

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

export const noEmailAuthentication = async (formData: NoEmailAuthentication): Promise<boolean> => {
    const url = `${API_BASE_URL}/${ACCOUNT_ENDPOINT}/authentication-code/verify`;

    try {
        await post<NoEmailAuthentication, NoEmailAuthenticationResponse>({
            url,
            body: formData,
        });

        return true;
    } catch (error) {
        return false;
    }
};
