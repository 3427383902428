import format from 'date-fns/format';
import style from './index.module.scss';

interface DateHeaderProps {
    date: Date;
}

const DateHeader = ({ date }: DateHeaderProps) => {
    return (
        <div className={style.dateHeader}>
            <span>{format(date, 'dd LLLL')}</span>
            <span> </span>
            <span>{format(date, 'yyy')}</span>
        </div>
    );
};

export default DateHeader;
