import { useState } from 'react';
import Input from 'src/components/Input';
import { JobDetails } from 'src/types/Job';
import { newAnalyticsEvent } from 'src/utils/analytics';
import { calculateActualPartsCostLimit } from 'src/utils/jobs';
import TextArea from 'src/components/TextArea';
import Button from 'src/components/Button';
import { Option } from '../../../types/Question';
import { FormState, ActualCostOfPartsValue } from '../../../types/Form';
import style from './index.module.scss';

export interface Props {
    title: string;
    options: Option[];
    onSubmit: (value: ActualCostOfPartsValue, nextQuestion?: string) => void;
    formState: Partial<FormState>;
    jobDetails: JobDetails;
    defaultValue?: ActualCostOfPartsValue;
}

const ActualCostOfPartsQuestion = ({
    title,
    options,
    onSubmit,
    formState,
    jobDetails,
    defaultValue,
}: Props) => {
    const [value, setValue] = useState<ActualCostOfPartsValue>({
        actualGrossPartsCost: defaultValue?.actualGrossPartsCost || '0',
        actualNetPartsCost: defaultValue?.actualNetPartsCost || 0,
        actualPartsDescription: defaultValue?.actualPartsDescription || '',
    });

    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const PARTS_COST_MARGIN = 15;

    const isValidForm = () => {
        const newErrors: { [key: string]: string } = {};

        const estimatedPartsCost = calculateActualPartsCostLimit(
            jobDetails.sk_estimated_e_parts_net_amount,
            formState.estimatedPartsCost,
            formState.partsSource === 'canFitPartsNow'
        );

        if (!value.actualPartsDescription && Number(value?.actualGrossPartsCost) > 0) {
            newErrors.actualPartsDescription = 'Parts description is a required field.';
        }

        if (value.actualPartsDescription && value.actualPartsDescription.length > 255) {
            newErrors.actualPartsDescription =
                'Parts description must be less than 256 characters.';
        }

        if (!value.actualGrossPartsCost) {
            newErrors.actualGrossPartsCost = 'Gross amount is a required field.';
        }

        if (value.actualGrossPartsCost && value.actualGrossPartsCost.length > 11) {
            // TODO think of an actual regex for 8 numbers + 2 decimal places
            newErrors.actualGrossPartsCost = 'Gross amount must be less than 11 characters.';
        }

        if ((value.actualNetPartsCost || 0) > estimatedPartsCost + PARTS_COST_MARGIN) {
            newAnalyticsEvent({
                category: 'Authorisation',
                action: 'Declined - parts cost',
                label: jobDetails.sk_job_id,
                value: JSON.stringify({
                    estimatedNetPartsCost: estimatedPartsCost,
                    actualNetPartsCost: value.actualNetPartsCost,
                }),
            });
            newErrors.actualNetPartsCost =
                'The amount entered is higher than the one we have authorised. Please call us on <a href="tel:03300589485">0330 058 9485</a> or message us via WhatsApp to resolve this.';
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const onChange = (key) => (e) => {
        const currentValue = e.target.value;

        if (currentValue) {
            setErrors((prev) => {
                const newErrors = { ...prev };
                delete newErrors[key];
                return newErrors;
            });
        }

        if (key === 'actualGrossPartsCost') {
            const formattedValue = currentValue.substring(1);

            if (Number.isNaN(+formattedValue)) {
                setValue({
                    ...value,
                    actualGrossPartsCost: '0',
                    actualNetPartsCost: 0,
                });
            } else {
                setValue({
                    ...value,
                    actualGrossPartsCost: formattedValue,

                    // Calculate net amount if engineer is VAT registered.
                    actualNetPartsCost: jobDetails.engineer_vat_number
                        ? Math.round((+formattedValue / 1.2) * 100) / 100
                        : +formattedValue,
                });
            }
        }

        if (key === 'actualPartsDescription') {
            setValue({
                ...value,
                actualPartsDescription: currentValue,
            });
        }
    };

    const validationError = (
        <div className={style.validationError} role="alert">
            <ul>
                {Object.entries(errors).map(([key, error]) => (
                    // eslint-disable-next-line react/no-danger
                    <li key={`each-error-${key}`} dangerouslySetInnerHTML={{ __html: error }} />
                ))}
            </ul>
        </div>
    );

    return (
        <>
            <span className={style.title}>{title}</span>
            <div className={style.inputAndError}>
                <div>
                    <label htmlFor="actualPartsDescription">
                        <span>Parts description</span>
                        <TextArea
                            name="actualPartsDescription"
                            id="actualPartsDescription"
                            value={value.actualPartsDescription || ''}
                            onChange={onChange('actualPartsDescription')}
                            onFocus={() =>
                                onChange('actualPartsDescription')({
                                    target: { value: value.actualPartsDescription || '' },
                                })
                            }
                            autoFocus
                        />
                    </label>
                </div>
                <div className={style.costText}>
                    <span>Net parts cost:</span>
                    <span>&pound;{value.actualNetPartsCost}</span>
                </div>

                <div className={style.costText}>
                    <span>VAT:</span>
                    <span>{jobDetails.engineer_vat_number ? '20%' : '0%'}</span>
                </div>

                <div className={style.formControl}>
                    <label htmlFor="grossPartsCostInput">
                        <span>Gross parts cost:</span>
                        <Input
                            inputType="currency"
                            id="grossPartsCostInput"
                            name="actualGrossPartsCost"
                            value={(value.actualGrossPartsCost || '').toString()}
                            onChange={onChange('actualGrossPartsCost')}
                            onFocus={() =>
                                onChange('actualGrossPartsCost')({ target: { value: '' } })
                            }
                            aria-label={title}
                        />
                    </label>
                </div>
            </div>

            <div className={style.subText}>This will be reflected in your invoice</div>
            {!!Object.keys(errors).length && validationError}

            {options.map(({ value: optionValue, nextQuestion, nextQuestionSelector }) => {
                return (
                    <Button
                        customStyle={style.button}
                        key={optionValue}
                        type="button"
                        onClick={() => {
                            const hasErrors = !isValidForm();

                            if (!hasErrors) {
                                onSubmit(
                                    value,
                                    nextQuestionSelector
                                        ? nextQuestionSelector(value, formState)
                                        : nextQuestion
                                );
                            }
                        }}
                    >
                        {optionValue}
                    </Button>
                );
            })}
        </>
    );
};

export default ActualCostOfPartsQuestion;
