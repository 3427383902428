import { useState } from 'react';
import Button from 'src/components/Button';

const LocationDemo = () => {
    const [coordinates, setCoordinates] = useState({ latitude: 0, longitude: 0 });
    const [noCoordinates, setNoCoordinates] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleButtonClick = () => {
        setLoading(true);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setCoordinates({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                });
                setNoCoordinates(false);
                setLoading(false);
            });
        } else {
            setNoCoordinates(true);
            setLoading(false);
        }
    };
    return (
        <>
            <Button onClick={handleButtonClick} loading={loading} isDisabled={loading}>
                Get location
            </Button>
            {coordinates.longitude && coordinates.latitude ? (
                <>
                    <p>Longitude: {coordinates.longitude}</p>
                    <p>Latitude: {coordinates.latitude}</p>
                </>
            ) : (
                <></>
            )}
            {noCoordinates && (
                <p>
                    Can&apos;t get location. Please give your browser permission to access your
                    location
                </p>
            )}
        </>
    );
};

export default LocationDemo;
