import { useQuery } from 'react-query';
import { getAccount, mapGetAccountResponseToAccount } from '../features/login/api/account';

export const useAccountQuery = () => {
    const { isLoading, isError, error, data } = useQuery<any, Error, any>(['account'], getAccount);

    return {
        isLoading,
        isError,
        error,
        data: mapGetAccountResponseToAccount(data),
    };
};
