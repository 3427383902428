import { format, sub } from 'date-fns';
import { useEffect, useMemo, useRef } from 'react';
import Loader from 'src/components/Loader';
import Spinner from 'src/components/Spinner';
import { JobDetails } from 'src/types/Job';
import { ErrorMessage } from 'src/utils/copy';
import ErrorBar from '../../../../components/ErrorBar';
import { useInfiniteGetJobAgenda } from '../../hooks/useInfiniteGetJobAgenda';
import JobList from './JobList';
import style from './index.module.scss';

const JOBS_PER_PAGE = 20;

const AgendaPrevious = () => {
    const jobListRef = useRef<HTMLDivElement>(null);
    const today = format(new Date(), 'yyyy-MM-dd');
    const monthsFromNow = format(sub(new Date(), { days: 90 }), 'yyyy-MM-dd');

    const status = JSON.stringify(['Complete']);
    const { data, isError, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
        useInfiniteGetJobAgenda('jobAgendaPrevious', monthsFromNow, JOBS_PER_PAGE, today, status);

    useEffect(() => {
        const handleScroll = (e) => {
            const currentTarget = e.currentTarget as Window;

            if (jobListRef.current && currentTarget) {
                const bottomPos = jobListRef.current.getBoundingClientRect().bottom;
                const offset = window.innerWidth < 1200 ? 200 : 0;
                if (bottomPos < window.innerHeight - offset && hasNextPage && !isFetchingNextPage) {
                    fetchNextPage();
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [jobListRef, hasNextPage, isFetchingNextPage, fetchNextPage]);

    const mergedJobs = useMemo<JobDetails[] | undefined>(() => {
        return data?.pages.reduce((acc, page) => [...acc, ...page.jobs], []);
    }, [data]);

    return (
        <section className={style.jobAgenda}>
            {isError && <ErrorBar message={ErrorMessage.ENGINEER} />}

            {!isError && data && (
                <>
                    <h3>All previous jobs</h3>

                    <div>
                        <div
                            className={style.jobAgendaList}
                            data-testid="job-list"
                            ref={jobListRef}
                        >
                            <JobList jobs={mergedJobs || []} />
                        </div>
                        {!hasNextPage && data?.pages.length > 1 && (
                            <p className={style.noMoreJobs}>No more jobs</p>
                        )}
                    </div>
                </>
            )}

            {isFetching && !isError && data && (
                <div className={style.spinnerContainer}>
                    <Spinner customStyle={style.customSpinner} />
                </div>
            )}

            {isFetching && !isError && !data && <Loader />}
        </section>
    );
};

export default AgendaPrevious;
