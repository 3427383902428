import styles from './index.module.scss';

const Loader = () => {
    return (
        <div>
            <div className={styles.loadingSpinner} data-testid="loading-spinner">
                <div className={`${styles.dot} ${styles.dotOne}`} />
                <div className={`${styles.dot} ${styles.dotTwo}`} />
                <div className={`${styles.dot} ${styles.dotThree}`} />
            </div>
        </div>
    );
};

export default Loader;
