import { FormState } from '../../types/Form';
import { Config } from '../../types/Question';
import style from './index.module.scss';

export interface Props {
    name: string;
    title: string;
    formState: Partial<FormState>;
    setState: (name: string, value: string | number | boolean) => void;
    config: Config;
    selected: string;
    error: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DropdownQuestion = ({ name, title, formState, setState, config, selected, error }: Props) => {
    const dropdownOptions = config?.options || [];

    const onChange = (e) => {
        const currentValue = e.target.value;

        setState(name, currentValue);
    };

    const onChangeOther = (e) => {
        const currentValue = e.target.value;

        setState(`${name}Other`, currentValue);
    };

    const validationError = (
        <div className="fnolform-error" data-testid="validation-error" role="alert">
            {error}
        </div>
    );

    return (
        <>
            <legend className={style.title}>{title}</legend>

            <div className={style.inputAndError}>
                <div className={style.dropdownContainer}>
                    <select
                        name={name}
                        id={`dropdown-${name}`}
                        onChange={onChange}
                        value={selected}
                        data-testid={`dropdown-${name}`}
                    >
                        {config?.placeholder && (
                            <option value="" data-testid={`option-${name}-placeholder`} disabled>
                                {config.placeholder}
                            </option>
                        )}
                        {dropdownOptions.map((option) => (
                            <option
                                key={`option-${name}-${option.value}`}
                                value={option.value}
                                data-testid={`option-${name}-${option.value}`}
                            >
                                {option.copy || option.value}
                            </option>
                        ))}
                    </select>
                </div>

                {selected === 'other' && (
                    <div className={style.otherInputContainer}>
                        <legend className={style.title}>{config.otherTitle || 'Specify:'}</legend>
                        <input
                            className={style.otherInput}
                            type="text"
                            name={`${name}-other`}
                            id={`${name}-other`}
                            placeholder="Please specify"
                            data-testid={`input-${name}-other`}
                            onChange={onChangeOther}
                        />
                    </div>
                )}

                {error && validationError}
            </div>
        </>
    );
};

export default DropdownQuestion;
