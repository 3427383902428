interface AnalyticsEvent {
    category: string;
    action: string;
    value: string;
    label?: string;
}

// eslint-disable-next-line import/prefer-default-export
export const newAnalyticsEvent = ({
    category,
    action,
    value,
    label = undefined,
}: AnalyticsEvent) => {
    if (!window.dataLayer) {
        // eslint-disable-next-line no-console
        console.error('Tag manager not initialized');
        return;
    }

    window.dataLayer.push({
        event: 'EventsWeb',
        ...(category && { category }),
        ...(action && { action }),
        ...(value && { value }),
        ...(label && { label }),
    });
};

export const newVirtualPageView = (title, url) => {
    if (!window.dataLayer) {
        // eslint-disable-next-line no-console
        console.error('Tag manager not initialized');
        return;
    }

    window.dataLayer.push({
        event: 'VirtualPageview',
        virtualPageTitle: title,
        virtualPageURL: url,
    });
};
