import { AreaOfIssue } from 'src/types/Job';
import { FormState } from '../../types/Form';
import { Config } from '../../types/Question';
import styles from './index.module.scss';

export interface Props {
    name: string;
    title: string;
    helper?: string;
    formState: Partial<FormState>;
    setState: (name: string, value: string | number | boolean) => void;
    value: string;
    error: string;
    config: Config;
}

const TextAreaQuestion = ({
    name,
    title,
    helper,
    formState: _formState,
    setState,
    value,
    error,
    config,
}: Props) => {
    const onChange = (e) => {
        const currentValue = e.target.value;

        setState(name, currentValue || '');
    };

    const validationError = (
        <div
            className={`fnolform-error ${styles.fnolError}`}
            data-testid="validation-error"
            role="alert"
        >
            {error}
        </div>
    );

    return (
        <>
            {(_formState.jobType === AreaOfIssue.BOILER ||
                _formState.jobType === AreaOfIssue.HEATING) &&
                config.extraCopy}
            <div className={styles.textareaFormControl}>
                <label htmlFor={`textarea-${name}`} className={styles.title}>
                    {title}
                </label>
                {config.otherTitle && <p>{config.otherTitle}</p>}
                <span className={styles.helper}>{helper}</span>
                <textarea
                    id={`textarea-${name}`}
                    className={styles.textarea}
                    value={value}
                    onChange={onChange}
                    placeholder={config.placeholder || ''}
                    maxLength={config.text?.fieldLength}
                />
                {error && validationError}
            </div>
        </>
    );
};

export default TextAreaQuestion;
