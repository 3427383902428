import { useState } from 'react';
import { JobDetails } from 'src/types/Job';
import { newAnalyticsEvent } from 'src/utils/analytics';
import { calculateActualPartsCostLimit } from 'src/utils/jobs';
import { formatPounds } from 'src/utils/prices';
import { captureSentryInfo } from 'src/features/login/utils/captureSentryError';
import Button from 'src/components/Button';
import { Option } from '../../../types/Question';
import { FormState, ActualCostOfPartsValue, Part } from '../../../types/Form';
import style from './index.module.scss';
import PartsAdded from '../PartsNeeded/PartsAdded';
import PartAddModal from '../PartsNeeded/PartAddModal';
import PartEditModal from '../PartsNeeded/PartEditModal';

export interface Props {
    title: string;
    options: Option[];
    onSubmit: (value: ActualCostOfPartsValue, nextQuestion?: string) => void;
    formState: Partial<FormState>;
    jobDetails: JobDetails;
    defaultValue?: Part[];
}

const NewActualCostOfPartsQuestion = ({
    title,
    options,
    onSubmit,
    formState,
    jobDetails,
    defaultValue,
}: Props) => {
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editPart, setEditPart] = useState<Part | undefined>(undefined);
    const [parts, setParts] = useState<Part[]>(
        (defaultValue &&
            defaultValue.length > 0 &&
            defaultValue.map(({ actualCost, estimatedCost, name, quantity }) => ({
                estimatedCost,
                quantity,
                name,
                actuallyUsed: true,
                actualCost: actualCost || estimatedCost,
            }))) ||
            []
    );

    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const PARTS_COST_MARGIN = 15;

    const isValidForm = (value) => {
        const newErrors: { [key: string]: string } = {};

        const estimatedPartsCost = calculateActualPartsCostLimit(
            jobDetails.sk_estimated_e_parts_net_amount,
            formState.estimatedPartsCost,
            formState.partsSource === 'canFitPartsNow'
        );

        if (!value.actualPartsDescription && Number(value?.actualGrossPartsCost) > 0) {
            newErrors.actualPartsDescription = 'Parts description is a required field.';
        }

        if (value.actualPartsDescription && value.actualPartsDescription.length > 255) {
            newErrors.actualPartsDescription =
                'Parts description must be less than 256 characters.';
        }

        if (!value.actualGrossPartsCost) {
            newErrors.actualGrossPartsCost = 'Gross amount is a required field.';
        }

        if (value.actualGrossPartsCost && value.actualGrossPartsCost.length > 11) {
            // TODO think of an actual regex for 8 numbers + 2 decimal places
            captureSentryInfo('Gross amount must be less than 11 characters.', {
                actualGrossPartCost: value.actualGrossPartsCost,
                actualGrossPartCostLength: value.actualGrossPartsCost.length,
                jobId: jobDetails.sk_job_id,
                parts: JSON.stringify(parts),
            });
            newErrors.actualGrossPartsCost = 'Gross amount must be less than 11 characters.';
        }

        if ((value.actualNetPartsCost || 0) > estimatedPartsCost + PARTS_COST_MARGIN) {
            newAnalyticsEvent({
                category: 'Authorisation',
                action: 'Declined - parts cost',
                label: jobDetails.sk_job_id,
                value: JSON.stringify({
                    estimatedNetPartsCost: estimatedPartsCost,
                    actualNetPartsCost: value.actualNetPartsCost,
                }),
            });
            newErrors.actualNetPartsCost =
                'The total cost of parts added is higher than what we have authorised. Please call us on <a href="tel:03300589485">0330 058 9485</a> or message us via WhatsApp to resolve this.';
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const validationError = (
        <div className={style.validationError} role="alert">
            <ul>
                {Object.entries(errors).map(([key, error]) => (
                    // eslint-disable-next-line react/no-danger
                    <li key={`each-error-${key}`} dangerouslySetInnerHTML={{ __html: error }} />
                ))}
            </ul>
        </div>
    );

    const onEdit = (part: Part) => () => {
        setEditPart({ ...part });
        setShowEditModal(true);
    };

    if (showEditModal) {
        return (
            <PartEditModal
                setShowModal={setShowEditModal}
                setParts={setParts}
                parts={parts}
                initialPartData={editPart}
                isActualCost
            />
        );
    }

    if (showModal) {
        return (
            <PartAddModal
                setShowModal={setShowModal}
                setParts={setParts}
                parts={parts}
                isActualCost
            />
        );
    }

    return (
        <>
            <span className={style.title}>{title}</span>
            {parts && parts.filter((part) => part.actuallyUsed).length > 0 && (
                <PartsAdded
                    parts={parts}
                    setParts={setParts}
                    isVatRegistered={!!jobDetails.engineer_vat_number}
                    isActualCost
                    onEdit={onEdit}
                />
            )}
            <Button type="button" customStyle={style.addPart} onClick={() => setShowModal(true)}>
                <p>+ Add part to fit</p>
            </Button>
            {!!Object.keys(errors).length && validationError}
            {options.map(({ value: optionValue, nextQuestion, nextQuestionSelector }) => {
                const totalCost = formatPounds(
                    parts.reduce((accumulator, item) => accumulator + (item.actualCost || 0), 0)
                );

                const submittedValue = {
                    actualGrossPartsCost: totalCost.toString(),
                    actualNetPartsCost: jobDetails.engineer_vat_number
                        ? Math.round((+totalCost / 1.2) * 100) / 100
                        : +totalCost,
                    actualPartsDescription: parts
                        .filter((part) => part.actuallyUsed)
                        .map(({ name, quantity }) => `${quantity}x ${name}`)
                        .join(', '),
                    partsUsed: parts,
                };

                return (
                    <Button
                        customStyle={style.button}
                        key={optionValue}
                        type="button"
                        onClick={() => {
                            const hasErrors = !isValidForm(submittedValue);

                            if (!hasErrors) {
                                onSubmit(
                                    submittedValue,
                                    nextQuestionSelector
                                        ? nextQuestionSelector(submittedValue, formState)
                                        : nextQuestion
                                );
                            }
                        }}
                    >
                        {optionValue}
                    </Button>
                );
            })}
        </>
    );
};

export default NewActualCostOfPartsQuestion;
