import { useParams, useNavigate } from 'react-router-dom';
import Loader from 'src/components/Loader';
import { ExpandArrow } from 'src/images';
import JobCardMain from 'src/features/jobAgenda/components/JobCardMain';
import JobCardHeader from 'src/features/jobAgenda/components/JobCardHeader';
import Layout from 'src/components/Layout';
import ErrorBar from 'src/components/ErrorBar';
import style from './index.module.scss';
import { Routes as RoutePath } from '../../../types/Routes';
import JobDetails from '../components/JobDetails';
import { useJobDetails } from '../hooks/useJobDetails';

const JobDetailsRoute = () => {
    const navigate = useNavigate();
    const { jobId } = useParams<{ jobId: string }>();
    const { data: job, isLoading, isError, error } = useJobDetails(jobId);

    if (isLoading) {
        return <Loader />;
    }

    const goBackButton = (
        <button
            type="button"
            className={style.backButton}
            aria-label="Go back to previous page"
            onClick={() =>
                navigate({
                    pathname: RoutePath.JOB_AGENDA,
                    search: '?tab=previousAgenda',
                })
            }
        >
            <ExpandArrow />
        </button>
    );

    if (!job || isError) {
        // eslint-disable-next-line no-console
        console.error(`Error fetching job details. Error: ${error?.message}`);
        return (
            <Layout>
                <div>
                    {goBackButton}
                    <ErrorBar message="An error occured whilst fetching this job's details, please contact administration" />
                </div>
            </Layout>
        );
    }

    const hasActualTimes = !!job.sk_actual_start && !!job.sk_actual_end;
    const start = hasActualTimes ? job.sk_actual_start : job.sk_estimated_start;
    const end = hasActualTimes ? job.sk_actual_end : job.sk_estimated_end;

    if (!start || !end) {
        // eslint-disable-next-line no-console
        console.error(`Error fetching job details due to invalid job times.`);
        return (
            <Layout>
                <div>
                    {goBackButton}
                    <ErrorBar message="An error occured whilst fetching this job's details due to invalid job times. Please contact administration" />
                </div>
            </Layout>
        );
    }

    const startDate = new Date(start);
    const endDate = new Date(end);

    return (
        <Layout>
            <div>
                {goBackButton}

                <div
                    className={style.jobDetailsContainer}
                    data-testid="job-details-container-component"
                >
                    <div className={style.glance}>
                        <div className={style.header}>
                            <JobCardHeader postcode={job.postcode} jobName={job.sk_job_name} />
                        </div>
                        <JobCardMain start={startDate} end={endDate} status={job.sk_job_status} />

                        <span className={style.bottomTriangle} />
                    </div>
                    <div className={style.details}>
                        <JobDetails details={job} />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default JobDetailsRoute;
