const getCustomerAvailability = (estimatedStartDate, customerAvailability) => {
    if (!customerAvailability) {
        return [];
    }
    const customerAvailabilityArray = JSON.parse(
        customerAvailability.replaceAll('&quot;', '"')
    ).flat();

    if (customerAvailabilityArray.length <= 0) {
        return [];
    }

    const datesGreaterThanStartDate = customerAvailabilityArray.filter((dates) => {
        return dates >= estimatedStartDate;
    });

    let availaibilityArray = datesGreaterThanStartDate;

    if (!availaibilityArray.includes(estimatedStartDate)) {
        availaibilityArray = [estimatedStartDate].concat(datesGreaterThanStartDate);
    }

    return availaibilityArray.slice(0, 4);
};

export default getCustomerAvailability;
