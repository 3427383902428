import { useState } from 'react';
import { FormState } from 'src/features/closeJob/types/Form';
import Button from 'src/components/Button';
import { Config, Option } from '../../../types/Question';
import style from './index.module.scss';

export interface Props {
    title: string;
    options: Option[];
    onSubmit: (value: string | string[], nextQuestion?: string) => void;
    formState: Partial<FormState>;
    config: Config;
    defaultValue?: string[];
}

const CheckboxQuestion = ({ title, options, onSubmit, formState, config, defaultValue }: Props) => {
    const checkboxOptions = config?.options || [];
    const [selectedOptions, setSelectedOptions] = useState<string[]>(defaultValue || []);
    const [errors, setErrors] = useState(false);
    const isValidForm = () => selectedOptions.length > 0;

    const onChange = (e) => {
        const currentValue = e.target.value;
        const isChecked = selectedOptions.includes(currentValue);

        if (currentValue) {
            setErrors(false);
        }

        // Single option should remove all other options.
        if (config.singleOption === currentValue) {
            setSelectedOptions(isChecked ? [] : [currentValue]);
            return;
        }

        if (isChecked) {
            setSelectedOptions((prevState) => [
                ...prevState.filter(
                    (option) => option !== config.singleOption && option !== currentValue
                ),
            ]);
        } else {
            setSelectedOptions((prevState) => [
                ...prevState.filter((option) => option !== config.singleOption),
                currentValue,
            ]);
        }
    };

    const validationError = (
        <div className={style.validationError} role="alert">
            Field is required
        </div>
    );

    return (
        <>
            <span className={style.title}>{title}</span>
            <div className={style.inputAndError}>
                {checkboxOptions.map((option, index) => (
                    <div className={style.checkboxWrapper} key={option.value}>
                        <input
                            type="checkbox"
                            name="text"
                            className={`${style.textAreaSingle}`}
                            onChange={onChange}
                            id={`option-${index}`}
                            value={option.value}
                            checked={selectedOptions.includes(option.value)}
                        />
                        <label htmlFor={`option-${index}`}>{option.copy || option.value}</label>
                    </div>
                ))}
                {errors ? validationError : <></>}
            </div>

            {options.map(({ value, nextQuestion, nextQuestionSelector }) => {
                return (
                    <Button
                        customStyle={style.button}
                        key={value}
                        type="button"
                        onClick={() => {
                            const hasErrors = !isValidForm();

                            if (hasErrors) {
                                setErrors(true);
                            } else {
                                onSubmit(
                                    selectedOptions,
                                    nextQuestionSelector
                                        ? nextQuestionSelector(selectedOptions, formState)
                                        : nextQuestion
                                );
                            }
                        }}
                    >
                        {value}
                    </Button>
                );
            })}
        </>
    );
};

export default CheckboxQuestion;
