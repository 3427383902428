import {
    getAuthenticatedUser,
    getTokenFromAuthenticatedUser,
    getAttributesFromAuthenticatedUser,
} from 'src/features/login';
import { getMandatoryEnvVar } from 'src/utils/env';
import { ACCOUNT_ENDPOINT } from 'src/constants';
import { isPandoraErrorResponse } from 'src/types/pandora';
import { Account } from './getAccount.types';
import { UpdateAccountType } from './updateAccount.types';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

export const updateAccount = async (data: UpdateAccountType): Promise<Account> => {
    try {
        const user = await getAuthenticatedUser();
        const token = getTokenFromAuthenticatedUser(user);
        const attributes = getAttributesFromAuthenticatedUser(user);
        const accountId = attributes['custom:account_id'];

        const response = await fetch(`${API_BASE_URL}/${ACCOUNT_ENDPOINT}/accounts/${accountId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });
        const accountResponse = await response.json();

        if (isPandoraErrorResponse(accountResponse)) {
            throw new Error(accountResponse.message);
        }

        return accountResponse;
    } catch (err) {
        throw new Error(`Error in job details: ${err}`);
    }
};
