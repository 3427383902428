import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Layout from 'src/components/Layout';
import { mixpanelTrackPage } from 'src/utils/mixpanel';
import ErrorBar from '../../../../components/ErrorBar';
import Loader from '../../../../components/Loader';
import { newVirtualPageView } from '../../../../utils/analytics';
import { useGetJobOffers } from '../../hooks/useGetJobOffers';
import JobOfferCard from '../JobOfferCard';
import style from './index.module.scss';

const JobOffers = () => {
    const pageCount = 0;
    const location = useLocation();

    const {
        isError: isErrorLoadingOffers,
        isLoading: isOffersLoading,
        data: offersData,
    } = useGetJobOffers();

    const renderJobOffers = (jobOffers) => {
        return jobOffers.map((jobOffer) => (
            <div
                key={jobOffer.resource_job_offer_id}
                data-testid={`job-offer-row-${jobOffer.resource_job_offer_id}`}
            >
                <JobOfferCard jobOffer={jobOffer} />
                <br />
            </div>
        ));
    };

    useEffect(() => {
        newVirtualPageView('offers', location.pathname);
        mixpanelTrackPage();
    }, [pageCount, location]);

    if (isOffersLoading) {
        return <Loader />;
    }

    if (isErrorLoadingOffers || typeof offersData === 'undefined') {
        return (
            <ErrorBar message="An error occured whilst fetching your offers, please contact administration" />
        );
    }

    return (
        <Layout>
            <section className={style.jobOffers}>
                <div className={style.jobOffersList}>
                    {offersData.jobOffers.length > 0 ? (
                        <>
                            <p className={style.jobOffersTitle}>Offers that are still available.</p>
                            <div data-testid="job-offers-list">
                                {renderJobOffers(offersData.jobOffers)}
                            </div>
                        </>
                    ) : (
                        <>
                            <h4 style={{ padding: '1rem' }}>
                                No job offers available at this moment.
                            </h4>
                        </>
                    )}
                </div>
            </section>
        </Layout>
    );
};

export default JobOffers;
