/* eslint-disable no-console */
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { createPaymentMethod } from 'src/api/payment';
import { getMandatoryEnvVar } from 'src/utils/env';
import { formatPrice } from 'src/utils/prices';
// eslint-disable-next-line import/no-named-as-default
import PaymentElementWrapper from './PaymentElementWrapper';
import { Option, QuestionStage, QuestionStageValues } from '../../types/Question';
import StageLoader from '../StageLoader';

const publicStripeKey = getMandatoryEnvVar('REACT_APP_PUBLIC_STRIPE_KEY');
const stripePromise = loadStripe(publicStripeKey, {
    locale: 'en-GB',
});

type Props = {
    title: string;
    contribution: number;
    contractId: number;
    onOptionClick: (option: Option) => () => void;
    currentStage: QuestionStage;
    setState: (name: string, value: string | number | boolean) => void;
    currentStageState: QuestionStageValues;
    otherTitle?: string;
};

const Payment = ({
    title,
    contribution,
    contractId,
    onOptionClick,
    currentStage,
    setState,
    currentStageState,
    otherTitle,
}: Props) => {
    const [stripeError, setStripeError] = useState('');
    const [clientSecret, setClientSecret] = useState('');

    useEffect(() => {
        if (!contractId || !contribution) {
            return;
        }

        if (clientSecret !== '') return;

        createPaymentMethod(contractId, contribution, setStripeError).then((res) => {
            console.log(res);
            setClientSecret(res);
        });
    }, [clientSecret, contractId, contribution]);

    const options = {
        clientSecret,
    };

    if (!clientSecret) {
        return (
            <>
                <div>
                    <StageLoader />
                </div>
            </>
        );
    }

    if (!contribution || contribution === 0) {
        return (
            <>
                <h3>Error</h3>
                <p>No need to pay any {!otherTitle ? 'contribution' : 'excess'} fee</p>
            </>
        );
    }

    return (
        <>
            <Elements options={options} stripe={stripePromise}>
                <h2>
                    {otherTitle ?? title}
                    <span>*</span>
                </h2>
                <h4>
                    {!otherTitle ? 'Contribution' : 'Excess'}: {formatPrice(contribution)}
                </h4>
                <PaymentElementWrapper
                    clientSecret={clientSecret}
                    stripeError={stripeError}
                    setStripeError={setStripeError}
                    onOptionClick={onOptionClick}
                    currentStage={currentStage}
                    setState={setState}
                    currentStageState={currentStageState}
                />
            </Elements>
        </>
    );
};

export default Payment;
