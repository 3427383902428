import { FormEvent, useState } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { mixpanelTrack } from 'src/utils/mixpanel';
import { LoginError } from '../../../../types/LoginErrors';
import { SubmitButton } from '../SubmitButton';
import { Auth } from '../../api/amplify';
import styles from './index.module.scss';
import TextInput from '../textInput';

interface Props {
    onSuccess: (user: CognitoUser) => void;
    onChange: (value: string) => void;
    email: string;
}

const EmailForm = ({ onSuccess, onChange, email }: Props) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const amplifyAuthenticate = async () => {
        setIsSubmitting(true);
        try {
            const user: CognitoUser = await Auth.signIn(email);
            setErrorMessage('');
            onSuccess(user);
            mixpanelTrack('portal-login-email-success');
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('Failed to sign in using Amplify', error);
            mixpanelTrack('portal-login-email-fail');
            setErrorMessage(LoginError.UNKNOWN_EMAIL);
        } finally {
            setIsSubmitting(false);
        }
    };

    const onFormSubmit = async (e: FormEvent<HTMLElement>) => {
        e.preventDefault();

        const emailRegex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!email.match(emailRegex)) {
            setErrorMessage(LoginError.INVALID_EMAIL);
        } else {
            amplifyAuthenticate();
        }
    };

    return (
        <form className={`container ${styles.loginForm}`} onSubmit={onFormSubmit}>
            <TextInput
                onChange={(e) => {
                    onChange(e);
                }}
                placeholder="Email address"
                value={email}
                error={errorMessage}
            />

            <SubmitButton disabled={isSubmitting} />
        </form>
    );
};

export default EmailForm;
