import { Reattend } from '../types/Form';

export const determinePaymentNeeded = (
    contribution: number | undefined = 0,
    reattend: Reattend
) => {
    if (reattend === Reattend.YES || !(contribution > 0)) {
        return 'submitClaim';
    }

    return 'paymentDetails';
};
