import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useAuthenticatedUser } from 'src/hooks/useAuthenticatedUser';
import Button from 'src/components/Button';
import { Auth } from '../../login/api/amplify';
import { Routes } from '../../../types/Routes';

const NavbarLogout = () => {
    const navigate = useNavigate();

    const queryClient = useQueryClient();
    const { refetch } = useAuthenticatedUser();
    const onClick = async () => {
        sessionStorage.removeItem('marketingConsent');
        await Auth.signOut();
        queryClient.invalidateQueries({ queryKey: ['user'] });
        await refetch();
        navigate(Routes.AUTH);
    };

    return (
        <Button variant="logout" onClick={onClick}>
            Logout
        </Button>
    );
};

export default NavbarLogout;
