import { Logout } from 'src/images';
import Spinner from '../Spinner';
import style from './index.module.scss';

export interface Props {
    children: JSX.Element | JSX.Element[] | string;
    variant?: 'primary' | 'secondary' | 'tertiary' | 'arrow' | 'text' | 'logout';
    isDisabled?: boolean;
    onClick?: (e?: React.MouseEvent<HTMLButtonElement> | undefined) => void;
    customStyle?: string;
    loading?: boolean;
    id?: string;
    ariaLabel?: string;
    type?: 'button' | 'submit';
}

const Button = ({
    children,
    variant = 'primary',
    isDisabled = false,
    onClick,
    customStyle = '',
    loading = false,
    id,
    ariaLabel,
    type,
}: Props) => {
    return (
        <button
            className={`${style.button} ${style[`button-${variant}`]} ${customStyle}`}
            type={type === 'submit' ? 'submit' : 'button'}
            disabled={isDisabled}
            onClick={onClick}
            data-variant={variant}
            id={id}
            aria-label={ariaLabel}
        >
            {variant !== 'arrow' && children}
            {loading ? <Spinner customStyle={style.spinner} /> : null}
            {variant === 'logout' ? <Logout /> : null}
        </button>
    );
};

export default Button;
