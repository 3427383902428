import { Phone } from 'src/images';
import { useAuthenticatedUser } from 'src/hooks/useAuthenticatedUser';
import { useEffect, useRef, useState } from 'react';
import { HOMETREE_ENGINEER_PHONE_NUMBER } from 'src/constants';
import { useLocation } from 'react-router-dom';
import { getMandatoryEnvVar } from 'src/utils/env';
import { ReactComponent as HometreeLogo } from '../../../../images/logo-rebrand.svg';
import styles from './index.module.scss';

interface HeaderProps {
    version?: string;
}

const Header = ({ version }: HeaderProps): JSX.Element => {
    const errorBannerEnabled = getMandatoryEnvVar('REACT_APP_ENGINEER_ERROR_BANNER_ENABLED');
    const errorBannerText = getMandatoryEnvVar('REACT_APP_ENGINEER_ERROR_BANNER_TEXT');
    const { data } = useAuthenticatedUser();
    const isEngineer = useRef(false);
    const { pathname } = useLocation();
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        isEngineer.current = data?.authenticatedUser?.attributes['custom:role'] === 'engineer';
        if (data?.authenticatedUser?.attributes['custom:role'] === 'engineer') {
            setShowBanner(true);
        } else {
            setShowBanner(false);
        }
    }, [data, pathname]);

    return (
        <div className={styles.navbar} data-version={version}>
            {' '}
            {showBanner && errorBannerEnabled === 'true' && (
                <div className={styles.banner}>
                    <span
                        className={styles.bannerMessage}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: errorBannerText }}
                    />
                </div>
            )}
            <div className={styles.content}>
                <a href="/">
                    <div className={styles.logo}>
                        <HometreeLogo />
                    </div>
                </a>

                {isEngineer.current && (
                    <>
                        <span className={styles.hideMobile}>
                            Need help? Call{' '}
                            <a href={`tel:+44${HOMETREE_ENGINEER_PHONE_NUMBER}`}>
                                {HOMETREE_ENGINEER_PHONE_NUMBER}
                            </a>
                        </span>
                        <span className={styles.hideDesktop}>
                            <a href={`tel:+44${HOMETREE_ENGINEER_PHONE_NUMBER}`}>
                                <Phone />
                            </a>
                        </span>
                    </>
                )}
            </div>
        </div>
    );
};

export default Header;
