import { CustomerData } from 'src/types/CustomerData';
import { ProductEnum } from 'src/types/ProductEnum';
import { HAS_SERVICE_ANSWER } from 'src/utils/jobs';
import { getMandatoryEnvVar } from 'src/utils/env';
import { FormState } from '../types/Form';
import { QuestionType, QuestionStage } from '../types/Question';

export const plumbingQuestions = (_customerData: CustomerData): QuestionStage[] => {
    const smartCoverEnabled = getMandatoryEnvVar('REACT_APP_SMART_COVER_ENABLED') === 'true';
    return [
        {
            handle: 'plumbingGroup1',
            questions: [
                {
                    name: 'problemType',
                    description: 'Do you have any of the following?',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            {
                                copy: 'Loss of all toilet and sink facilities',
                                value: 'Loss of all toilet and sink facilities',
                            },
                            {
                                copy: 'A serious water leak that requires the water to be turned off at the mains.',
                                value: 'A serious water leak that requires the water to be turned off at the mains.',
                            },
                            {
                                copy: 'I am not affected by any of the above',
                                value: 'I am not affected by any of the above',
                            },
                        ],
                    },
                },
                {
                    name: 'plumbingIssue',
                    description: 'Does your issue relate to:',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            { copy: 'Toilets', value: 'Toilets' },
                            { copy: 'Taps', value: 'Taps' },
                            { copy: 'Showers', value: 'Showers' },
                            {
                                copy: 'Pipework inside your property',
                                value: 'Pipework inside your property',
                            },
                            { copy: 'Storage water tank', value: 'Storage water tank' },
                            {
                                copy: 'Flexible pipes connected to appliances',
                                value: 'Flexible pipes connected to appliances',
                            },
                        ],
                    },
                },
            ],
            onSubmit: (values) => {
                const formState = {
                    problemType: values.problemType,
                    plumbingIssue: values.plumbingIssue,
                } as Partial<FormState>;

                if (values.plumbingIssue === 'Showers' && !smartCoverEnabled) {
                    formState.rejectProduct = 'showers';
                }

                if (values.plumbingIssue === 'Flexible pipes connected to appliances') {
                    formState.rejectProduct = 'flexible pipes connected to appliances';
                }

                return formState;
            },
            options: [
                {
                    copy: 'Next',
                    nextQuestion: (values) => {
                        const enabledTapsService =
                            _customerData.includedProducts.includes(ProductEnum.TAPS) ||
                            _customerData?.packageDetails?.tapsService === true;

                        const enabledShowersService =
                            _customerData.includedProducts.includes(ProductEnum.SHOWERS) ||
                            _customerData?.packageDetails?.showersService === true;

                        if (values.plumbingIssue === 'Flexible pipes connected to appliances') {
                            return 'optionNotCovered';
                        }

                        if (values.plumbingIssue === 'Taps' && !enabledTapsService) {
                            return 'policyNotCovered';
                        }

                        if (
                            values.plumbingIssue === 'Showers' &&
                            !enabledShowersService &&
                            smartCoverEnabled
                        ) {
                            return 'policyNotCovered';
                        }

                        if (values.plumbingIssue === 'Showers' && !smartCoverEnabled) {
                            return 'optionNotCovered';
                        }

                        if (
                            values.problemType === HAS_SERVICE_ANSWER &&
                            _customerData.packageDetails &&
                            _customerData.packageDetails.onlyLossOfService === true &&
                            _customerData.packageDetails.requiresInsurerApproval === true
                        ) {
                            return 'lossOfServiceNotCoveredNoExtraCopy';
                        }

                        if (
                            values.problemType === HAS_SERVICE_ANSWER &&
                            _customerData.packageDetails &&
                            _customerData.packageDetails.onlyLossOfService === true
                        ) {
                            return 'lossOfServiceNotCovered';
                        }
                        if (values.plumbingIssue === 'Toilets') {
                            return 'toiletIssue';
                        }

                        if (values.plumbingIssue === 'Pipework inside your property') {
                            return 'pipeworkInsideProperty';
                        }

                        return 'vulnerability';
                    },
                },
            ],
        },
        {
            handle: 'pipeworkInsideProperty',
            questions: [
                {
                    name: 'plumbingToiletVisibleLeak',
                    description: 'Is the leaking pipework visible and accessible?',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            { copy: 'Yes', value: 'Yes' },
                            { copy: 'No', value: 'No' },
                        ],
                    },
                },
            ],
            onSubmit: (values) => {
                const formState = {
                    plumbingToiletVisibleLeak: values.plumbingToiletVisibleLeak,
                } as Partial<FormState>;

                if (values.plumbingToiletVisibleLeak === 'No') {
                    formState.rejectProduct = 'leaks that are not visible';
                }

                return formState;
            },
            options: [
                {
                    copy: 'Next',
                    nextQuestion: (values) => {
                        if (
                            values.plumbingToiletVisibleLeak === 'No' &&
                            _customerData?.packageDetails?.onlyLossOfService === true &&
                            _customerData?.packageDetails?.requiresInsurerApproval === true
                        ) {
                            return 'optionNotCovered';
                        }

                        return 'vulnerability';
                    },
                },
            ],
        },
        {
            handle: 'toiletIssue',
            questions: [
                {
                    name: 'plumbingToiletType',
                    description: 'What type of toilet is it?',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            { copy: 'Standard toilet', value: 'Standard toilet' },
                            {
                                copy: 'Saniflow toilet (powered by electric)',
                                value: 'Saniflow toilet (powered by electric)',
                            },
                        ],
                    },
                },
                {
                    name: 'plumbingToiletVisibleLeak',
                    description: 'Is there a visible leak?',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            { copy: 'Yes', value: 'Yes' },
                            { copy: 'No', value: 'No' },
                        ],
                    },
                },
                {
                    name: 'plumbingToiletFlushing',
                    description: 'Is the flushing working?',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            { copy: 'Yes', value: 'Yes' },
                            { copy: 'No', value: 'No' },
                        ],
                    },
                },
                {
                    name: 'plumbingToiletBlockage',
                    description: 'Is there a blockage?',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            { copy: 'Yes', value: 'Yes' },
                            { copy: 'No', value: 'No' },
                        ],
                    },
                },
            ],
            onSubmit: (values) => {
                const formState = {
                    plumbingToiletType: values.plumbingToiletType,
                    plumbingToiletVisibleLeak: values.plumbingToiletVisibleLeak,
                    plumbingToiletFlushing: values.plumbingToiletFlushing,
                    plumbingToiletBlockage: values.plumbingToiletBlockage,
                } as Partial<FormState>;

                if (values.plumbingToiletType === 'Saniflow toilet (powered by electric)') {
                    formState.rejectProduct = 'Saniflow toilet';
                }
                if (values.plumbingToiletBlockage === 'Yes') {
                    formState.jobType = ProductEnum.DRAINAGE;
                }

                return formState;
            },
            options: [
                {
                    copy: 'Next',
                    nextQuestion: (values) => {
                        if (values.plumbingToiletType === 'Saniflow toilet (powered by electric)') {
                            return 'optionNotCovered';
                        }

                        return 'vulnerability';
                    },
                },
            ],
        },
    ];
};
