import { useState } from 'react';
import Input from 'src/components/Input';
import Dropdown from 'src/components/Dropdown';
import Button from 'src/components/Button';
import { Option } from '../../../types/Question';
import { FormState, BoilerDetailsValue } from '../../../types/Form';
import style from './index.module.scss';

const boilerMakes = [
    'Worcester Bosch',
    'Vaillant',
    'Ideal',
    'Baxi',
    'Potterton',
    'Alpha',
    'Vokera',
    'Glow-worm',
    'Ferroli',
    'Viessmann',
    'Intergas',
    'Ravenheat',
    'Biasi',
    'Atag',
    'Keston',
    'Main',
    'Ariston',
    'Remeha',
    'Saunier Duval',
];

export interface Props {
    title: string;
    options: Option[];
    onSubmit: (value: BoilerDetailsValue, nextQuestion?: string) => void;
    formState: Partial<FormState>;
    defaultValue?: BoilerDetailsValue;
}

const BoilerDetailsQuestions = ({ title, options, onSubmit, formState, defaultValue }: Props) => {
    const [boilerDetails, setBoilerDetails] = useState<BoilerDetailsValue>({
        boilerMake: defaultValue?.boilerMake || boilerMakes[0].toLowerCase(),
        boilerModel: defaultValue?.boilerModel || '',
        boilerGcNumber: defaultValue?.boilerGcNumber || '',
    });
    const [boilerMakeOther, setBoilerMakeOther] = useState('');
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const isValidForm = () => {
        const newErrors: { [key: string]: string } = {};

        if (
            (boilerDetails.boilerMake && boilerDetails.boilerMake.length > 256) ||
            (boilerDetails.boilerModel && boilerDetails.boilerModel.length > 256)
        ) {
            newErrors.detailLength = 'Boiler make/model must be less than 256 characters.';
        }

        if (
            boilerDetails.boilerGcNumber &&
            !boilerDetails.boilerGcNumber.replace(/[^0-9]/g, '').match(/^[0-9]{7}$/)
        ) {
            newErrors.detailLength = 'Boiler GC number must contain 7 digits.';
        }

        if (
            !boilerDetails.boilerMake ||
            !boilerDetails.boilerGcNumber ||
            !boilerDetails.boilerModel ||
            (boilerDetails.boilerMake === 'other' && !boilerMakeOther)
        ) {
            newErrors.missingDetails = 'All boiler details must be filled in.';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const onChange = (key) => (e) => {
        const currentValue = e.target.value;
        const currentFieldName = e.target.name;

        if (currentValue) {
            setErrors((prev) => {
                const newErrors = { ...prev };
                delete newErrors[currentFieldName];
                return newErrors;
            });
        }
        let gcNumberClean = '';
        if (key === 'boilerGcNumber') {
            gcNumberClean = currentValue.replace(/[^0-9]/g, '');
        }

        setBoilerDetails({ ...boilerDetails, [key]: gcNumberClean || currentValue });
    };

    const validationError = (
        <div className={style.validationError} role="alert">
            <ul>
                {Object.entries(errors).map(([key, error]) => (
                    <li key={`each-error-${key}`}>{error}</li>
                ))}
            </ul>
        </div>
    );

    return (
        <>
            <h3 className={style.title}>{title}</h3>
            <div className={style.inputAndError}>
                <div className={style.formControl}>
                    <label htmlFor="boilerMake">
                        <span>Make:</span>
                        <Dropdown
                            id="boilerMake"
                            name="boilerMake"
                            currentValue={boilerDetails.boilerMake?.toLowerCase() || ''}
                            onChange={onChange('boilerMake')}
                            options={boilerMakes
                                .map((make) => {
                                    return { value: make.toLowerCase(), copy: make };
                                })
                                .concat([{ value: 'other', copy: 'Other (please specify)' }])}
                        />
                    </label>
                </div>

                {boilerDetails.boilerMake === 'other' && (
                    <div className={style.formControl}>
                        <label htmlFor="boilerMakeOther">
                            <span>Make (Other):</span>
                            <Input
                                inputType="text"
                                id="boilerMakeOther"
                                name="boilerMakeOther"
                                value={boilerMakeOther}
                                onChange={(e) => setBoilerMakeOther(e.target.value)}
                            />
                        </label>
                    </div>
                )}

                <div className={style.formControl}>
                    <label htmlFor="boilerModel">
                        <span>Model:</span>
                        <Input
                            inputType="text"
                            id="boilerModel"
                            name="boilerModel"
                            value={boilerDetails.boilerModel || ''}
                            onChange={onChange('boilerModel')}
                        />
                    </label>
                </div>

                <div className={style.formControl}>
                    <label htmlFor="boilerGcNumber">
                        <span>GC Number:</span>
                        <Input
                            inputType="number"
                            id="boilerGcNumber"
                            name="boilerGcNumber"
                            value={(boilerDetails.boilerGcNumber || '').toString()}
                            onChange={onChange('boilerGcNumber')}
                        />
                    </label>
                </div>

                {Object.keys(errors).length > 0 && validationError}
            </div>

            {options.map(({ value, nextQuestion, nextQuestionSelector }) => {
                return (
                    <Button
                        customStyle={style.button}
                        key={value}
                        type="button"
                        onClick={() => {
                            const hasErrors = !isValidForm();

                            if (!hasErrors) {
                                if (boilerDetails.boilerMake === 'other') {
                                    boilerDetails.boilerMake = boilerMakeOther;
                                }
                                onSubmit(
                                    boilerDetails,
                                    nextQuestionSelector
                                        ? nextQuestionSelector(boilerDetails, formState)
                                        : nextQuestion
                                );
                            }
                        }}
                    >
                        {value}
                    </Button>
                );
            })}
        </>
    );
};

export default BoilerDetailsQuestions;
