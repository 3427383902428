import { CalendarGradient } from 'src/images';
import { useNavigate, useParams } from 'react-router-dom';
import { Routes } from 'src/types/Routes';
import { useQueryClient } from 'react-query';
import { add, format, parseISO } from 'date-fns';
import { FormState } from 'src/features/closeJob/types/Form';
import CalendarDate from 'src/components/Calendar/DateRadio';
import Button from 'src/components/Button';
import { getWeekDay } from 'src/utils/dates';
import style from './index.module.scss';

interface FollowOnScheduledProps {
    title?: string;
    extraCopy?: string;
    formState: Partial<FormState>;
}

const estimateDaySlot = (estimatedStart: Date): string => {
    switch (true) {
        case estimatedStart.getHours() >= 16:
            return 'Evening';
        case estimatedStart.getHours() >= 12:
            return 'Afternoon';
        default:
            return 'Morning';
    }
};

const FollowOnScheduled = ({
    title = 'Next Visit Scheduled',
    extraCopy = undefined,
    formState,
}: FollowOnScheduledProps) => {
    const navigate = useNavigate();
    const { jobId } = useParams();
    const queryClient = useQueryClient();

    const visitDateTime = formState.followOnDetails
        ? parseISO(`${formState.followOnDetails.visitDate} ${formState.followOnDetails.timeSlot}`)
        : null;

    const estimatedStartTime = visitDateTime && format(visitDateTime, 'h:mmaa');
    const estimatedEndTime =
        visitDateTime &&
        format(
            add(visitDateTime, {
                hours: 4,
            }),
            'h:mmaa'
        );
    const estimatedDaySlot = visitDateTime ? estimateDaySlot(visitDateTime) : '';
    const estimatedDate = visitDateTime ? format(visitDateTime, 'MMMM yyyy').split(' ') : [];

    return (
        <>
            <CalendarGradient className={style.icon} />

            <h2 className={style.title}>{title}</h2>

            {visitDateTime && (
                <div className={style.followOnDetails}>
                    <div>
                        <div>
                            {estimatedDate[0]} <span>{estimatedDate[1]}</span>
                        </div>
                        <div>
                            <div className={style.calendar}>
                                <div className={style.container}>
                                    <CalendarDate
                                        dateWeek={getWeekDay(visitDateTime)}
                                        dateNum={format(visitDateTime, 'dd')}
                                        isChecked
                                    />
                                </div>
                            </div>
                            <div>
                                <div className={`${style.timeOfDay} ${style[estimatedDaySlot]}`}>
                                    {estimatedDaySlot}
                                </div>
                                <div
                                    className={style.timeSlot}
                                >{`${estimatedStartTime} - ${estimatedEndTime}`}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {extraCopy && <div className={style.extraCopy}>{extraCopy}</div>}
            <Button
                onClick={() => {
                    queryClient.invalidateQueries(['jobDetails']);
                    navigate(Routes.JOB_AGENDA, { state: { hiddenJob: jobId } });
                }}
            >
                Back to agenda
            </Button>
        </>
    );
};

export default FollowOnScheduled;
