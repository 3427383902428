import { CheckCircleRainbow } from 'src/images';
import styles from './index.module.scss';

interface CompleteProps {
    title?: string;
    extraCopy?: JSX.Element;
}
const Complete = ({ title = 'Completed!', extraCopy = undefined }: CompleteProps) => {
    return (
        <div className={styles.completeContainer}>
            <CheckCircleRainbow className={styles.icon} />
            <span className={styles.title}>{title}</span>
            {extraCopy && <div className={styles.extraCopy}>{extraCopy}</div>}
        </div>
    );
};

export default Complete;
