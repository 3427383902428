import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BubbleHeading from 'src/features/login/components/bubbleHeading';
import Layout from 'src/components/Layout';
import ErrorBar from '../ErrorBar';
import FormValidator, {
    formatPostcode,
    handleEmailValidation,
    handlePostcodeValidation,
} from './validation';

import { newAnalyticsEvent, newVirtualPageView } from '../../utils/analytics';
import { getUserModeId } from '../../utils/userMode';
import { useCustomer } from '../../contexts/Customer';
import { sendAuthRequest } from '../../api/authentication';
import { LoginError } from '../../types/LoginErrors';
import styles from './index.module.scss';
import { Routes } from '../../types/Routes';
import Button from '../Button';

type Props = {
    value?: { paramUserMode?: string; paramClaimMode?: string };
};

const AuthForm = ({ value = {} }: Props) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [email, setEmail] = useState('');
    const [postcode, setPostcode] = useState('');
    const [error, setError] = useState('');
    const { paramUserMode, paramClaimMode } = value;
    const { dispatch } = useCustomer();
    const navigate = useNavigate();

    useEffect(() => {
        newVirtualPageView('Authentication', '/vpv/authentication');
        newAnalyticsEvent({
            category: 'Authentication',
            action: 'Landed',
            value: getUserModeId(paramUserMode),
        });
    }, [paramUserMode]);

    const registerNotAuthenticated = () => {
        newAnalyticsEvent({
            category: 'Authentication',
            action: 'Not authenticated',
            label: postcode,
            value: getUserModeId(paramUserMode),
        });
    };

    const sendAuth = async () => {
        newAnalyticsEvent({
            category: 'Authentication',
            action: 'Clicked find me',
            label: postcode,
            value: getUserModeId(paramUserMode),
        });

        // Revalidate inputs before submitting
        const emailValid = FormValidator.validateEmail(email);
        const postcodeValid = FormValidator.validatePostcode(postcode);

        if (!(emailValid && postcodeValid) || email === '' || postcode === '') {
            return;
        }

        setError('');
        setIsSubmitting(true);

        try {
            // TODO fix with CustomerData type once implemented
            const customerData = (await sendAuthRequest({ email, postcode })) as any;

            dispatch({ type: 'setCustomer', payload: { ...customerData, email, postcode } });
            setIsSubmitting(false);

            const claimRoute =
                paramClaimMode === 'new' ? Routes.CLAIM_NEW_IN_PROGRESS : Routes.CLAIM_NEW;

            navigate(claimRoute, {
                state: { email, postcode },
            });
        } catch (err) {
            registerNotAuthenticated();
            // eslint-disable-next-line no-console
            console.error(err);
            setError(err.message);
            setIsSubmitting(false);
        }
    };

    return (
        <Layout>
            <form className={styles.loginSection}>
                <BubbleHeading heading={`Let's find you!`} subheading="Just pop in your details" />
                <div className={styles.form}>
                    <input
                        name="email"
                        type="email"
                        className=""
                        value={email}
                        placeholder="Email address"
                        onChange={(e) => {
                            if (error === LoginError.INVALID_EMAIL) {
                                setError('');
                            }
                            setEmail(e.target.value);
                        }}
                        onBlur={() => handleEmailValidation(email, error, setError)}
                    />
                    <input
                        name="postcode"
                        type="text"
                        className=""
                        value={postcode}
                        placeholder="Postcode"
                        onChange={(e) => {
                            if (error === LoginError.INVALID_POSTCODE) {
                                setError('');
                            }
                            setPostcode(e.target.value);
                        }}
                        onBlur={() => {
                            const formattedPostcode = formatPostcode(postcode);
                            setPostcode(formattedPostcode);
                            handlePostcodeValidation(formattedPostcode, error, setError);
                        }}
                    />
                </div>
                {error && <ErrorBar message={error} style={styles.authFormErrorBar} />}

                <div className={styles.buttonContainer}>
                    <Button
                        id="auth_submit"
                        type="button"
                        isDisabled={isSubmitting}
                        onClick={() => sendAuth()}
                    >
                        {isSubmitting ? 'Finding...' : 'Find me'}
                    </Button>

                    <a href="https://www.hometree.co.uk/products/boiler-cover-insurance/">
                        Not a customer yet?
                    </a>
                </div>
            </form>
        </Layout>
    );
};

export default AuthForm;
