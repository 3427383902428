import Button from 'src/components/Button';

interface Props {
    disabled?: boolean;
    setParentState?: () => void;
}

export const SubmitButton = ({ disabled = false, setParentState }: Props) => (
    <Button isDisabled={disabled} onClick={setParentState} type="submit">
        Submit
    </Button>
);
