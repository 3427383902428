import { HOMETREE_PHONE_NUMBER, INTEGRATION_ENDPOINT } from 'src/constants';
import { captureSentryError } from 'src/features/login/utils/captureSentryError';
import { getMandatoryEnvVar } from 'src/utils/env';
import { CreateClaimRequestBody, CreateClaimResponse } from '../types/CreateClaimRequestBody';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

export const createClaim = async (data: CreateClaimRequestBody) => {
    try {
        const response = await fetch(`${API_BASE_URL}/${INTEGRATION_ENDPOINT}/claim/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        const responseBody = (await response.json()) as CreateClaimResponse;

        if (!response.ok) {
            await captureSentryError(new Error(responseBody.message || 'No error provided'), {
                ...responseBody,
                message: `Error occured during creation of claim`,
                customerFacingId: data.customerFacingId || '',
            });

            return new Error(
                `Something went wrong. Please try again later or call us on ${HOMETREE_PHONE_NUMBER}.`
            );
        }

        return responseBody;
    } catch (err) {
        await captureSentryError(err, {
            message: `Error occured during creation of claim`,
            customerFacingId: data.customerFacingId || '',
        });

        return new Error(
            `Something went wrong. Please try again later or call us on ${HOMETREE_PHONE_NUMBER}.`
        );
    }
};
