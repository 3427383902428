import { distance } from 'fastest-levenshtein';
import { Plus, RepairBlack, Search, X } from 'src/images';
import { useState } from 'react';
import Button from 'src/components/Button';
import style from './PartNeededModal.module.scss';

interface Props {
    value: string;
    onChange: (value: string) => void;
    onClear: () => void;
    options: { value: string; copy: string }[];
    placeholder?: string;
}

const getBestMatch = (input: string, comparison: string): number =>
    Math.min(
        ...comparison
            .split(' ')
            .map((searchStr) =>
                Math.min(
                    ...input
                        .split(' ')
                        .map((str) => distance(str.toLowerCase(), searchStr.toLowerCase()))
                )
            )
    );

const SearchableDropdown = ({ onChange, onClear, value, options, placeholder = '' }: Props) => {
    const [showOptions, setShowOptions] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    return (
        <div className={style.dropdown}>
            <div className={style.inputContainer}>
                <input
                    list="options"
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                    }}
                    onFocus={() => setShowOptions(true)}
                    className={style.input}
                    aria-label="parts-search"
                    value={value || searchTerm}
                    autoComplete="off"
                    pattern={`^(${options
                        .map(({ value: optionValue }) => optionValue)
                        .join('|')})$`}
                    placeholder={placeholder}
                />
                {showOptions ? (
                    <Button
                        customStyle={style.searchButton}
                        type="button"
                        onClick={() => {
                            onClear();
                            setSearchTerm('');
                            setShowOptions(false);
                        }}
                    >
                        <X className={style.inputIcon} />
                    </Button>
                ) : (
                    <Search className={style.inputIcon} />
                )}
            </div>

            {showOptions && (
                <div className={style.optionsContainer}>
                    {options
                        .sort(
                            (a, b) =>
                                getBestMatch(a.copy, searchTerm) - getBestMatch(b.copy, searchTerm)
                        )
                        .slice(0, 20)
                        .map((option) => (
                            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                            <div
                                data-testid="part-options"
                                className={style.option}
                                key={option.value}
                                onClick={() => {
                                    onChange(option.value);
                                    setShowOptions(false);
                                }}
                            >
                                <RepairBlack />
                                <span>{option.copy}</span>
                                <Plus />
                            </div>
                        ))}
                </div>
            )}
        </div>
    );
};

export default SearchableDropdown;
