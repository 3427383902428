import Ajv from 'ajv';
import { APPLIANCES_ENDPOINT } from 'src/constants';
import { getAuthenticatedUser, getTokenFromAuthenticatedUser } from 'src/features/login';
import { isPandoraErrorResponse } from 'src/types/pandora';
import { getMandatoryEnvVar } from 'src/utils/env';
import { getAppliancesMakesResponseSchema } from './getApplianceMakes.types';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

export const getAppliancesMakes = async (): Promise<any> => {
    try {
        const user = await getAuthenticatedUser();
        const token = getTokenFromAuthenticatedUser(user);

        const response = await fetch(`${API_BASE_URL}/${APPLIANCES_ENDPOINT}/v1/appliances/makes`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
        const makesResponse = await response.json();
        const ajv = new Ajv({ coerceTypes: true });
        const validator = ajv.compile(getAppliancesMakesResponseSchema);
        const valid = validator(makesResponse);

        if (!valid) {
            throw new Error(
                `Error in validating accountResponse: Error in ${JSON.stringify(
                    validator.errors?.[0].schemaPath
                )} - ${JSON.stringify(validator.errors?.[0].params)}`
            );
        }

        if (isPandoraErrorResponse(makesResponse)) {
            throw new Error(makesResponse.message);
        }

        return makesResponse?.applianceMakes;
    } catch (err) {
        throw new Error(`Error in job details: ${err}`);
    }
};
