import { useMemo, useState } from 'react';
import Dropdown, { Option as DropdownOption } from 'src/components/Dropdown';
import { EstimatedCostOfPartsValue, FormState, Part } from 'src/features/closeJob/types/Form';
import { JobDetails } from 'src/types/Job';
import { maxLabourAuthorised } from 'src/utils/maxLabourAuthorised';
import { useGetJobParts } from 'src/features/closeJob/hooks/useGetJobParts';
import { DetermineFollowOnIsAuthorisedInput } from 'src/utils/determineFollowOnIsAuthorised';
import { captureSentryInfo } from 'src/features/login/utils/captureSentryError';
import { labourHoursToCost, totalEstimatedCostOfParts } from 'src/utils/jobs';
import Button from 'src/components/Button';
import { Option } from '../../../types/Question';

import style from './index.module.scss';
import PartsAdded from './PartsAdded';
import PartEditModal from './PartEditModal';
import PartAddModal from './PartAddModal';

export interface Props {
    title: string;
    options: Option[];
    onSubmit: (value: EstimatedCostOfPartsValue, nextQuestion?: string) => void;
    formState: Partial<FormState>;
    jobDetails: JobDetails;
}

const placeholder: DropdownOption = { copy: '', value: 0 };

const PartsNeeded = ({ title, options, onSubmit, formState, jobDetails }: Props) => {
    const [estimatedHoursNeeded, setEstimatedHoursNeeded] = useState(0);
    const [partsRequested, setPartsRequested] = useState<Part[]>(formState.partsRequested || []);
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editPart, setEditPart] = useState<Part | undefined>(undefined);

    const { isError, isLoading, data: jobParts } = useGetJobParts();

    const isVatRegistered = useMemo(() => !!jobDetails.engineer_vat_number, [jobDetails]);

    const onEdit = (part: Part) => () => {
        setEditPart({ ...part });
        setShowEditModal(true);
    };

    if (showEditModal) {
        return (
            <PartEditModal
                setShowModal={setShowEditModal}
                setParts={setPartsRequested}
                parts={partsRequested}
                initialPartData={editPart}
            />
        );
    }

    if (showModal) {
        return (
            <PartAddModal
                setShowModal={setShowModal}
                setParts={setPartsRequested}
                parts={partsRequested}
            />
        );
    }

    return (
        <>
            <span className={style.title}>{title}</span>
            {partsRequested && partsRequested.length > 0 && (
                <PartsAdded
                    parts={partsRequested}
                    setParts={setPartsRequested}
                    isVatRegistered={isVatRegistered}
                    onEdit={onEdit}
                />
            )}
            <Button type="button" customStyle={style.addPart} onClick={() => setShowModal(true)}>
                <p>+ Add part to fit</p>
            </Button>
            <label htmlFor="estimatedHoursNeededInput" className={style.hoursNeededLabel}>
                <span>Time needed for follow on</span>
                <Dropdown
                    id="estimatedHoursNeededInput"
                    options={[
                        placeholder,
                        { value: 0.5, copy: '0.5 Hour' },
                        { value: 1, copy: '1 Hour' },
                        { value: 1.5, copy: '1.5 Hours' },
                        { value: 2, copy: '2 Hours' },
                        { value: 2.5, copy: '2.5 Hours' },
                        { value: 3, copy: '3 Hours' },
                        { value: 3.5, copy: '3.5 Hours' },
                        { value: 4, copy: '4 Hours' },
                        { value: 4.5, copy: '4.5 Hours' },
                        { value: 5, copy: '5 Hours' },
                    ]}
                    currentValue={estimatedHoursNeeded || ''}
                    onChange={(e) => setEstimatedHoursNeeded(+e.target.value)}
                    name="estimatedHoursNeeded"
                />
            </label>
            {options.map(({ value, nextQuestion, nextQuestionSelector }) => {
                if (!isLoading && !isError && !(jobParts instanceof Error) && jobParts) {
                    return (
                        <Button
                            isDisabled={
                                !partsRequested ||
                                partsRequested.length === 0 ||
                                !estimatedHoursNeeded
                            }
                            customStyle={style.mainButton}
                            key={value}
                            type="button"
                            onClick={() => {
                                const manuallyAuthorised = jobDetails.sk_authorise_follow_on;
                                const previousEngineerVisits = Number(
                                    jobDetails.previous_engineer_visits
                                );
                                const numberOfParts = partsRequested.length;
                                const labourCost = labourHoursToCost(estimatedHoursNeeded);
                                const totalCostOfParts = totalEstimatedCostOfParts(partsRequested);
                                const maxLabourCost = labourHoursToCost(
                                    maxLabourAuthorised({
                                        partsRequested,
                                        warningLabourCostShown: formState.warningLabourCostShown,
                                    })
                                );
                                const totalCostOfFix = labourCost + totalCostOfParts;
                                const { boilerMake, issueArea } = formState;
                                const nextQuestionInput: DetermineFollowOnIsAuthorisedInput = {
                                    numberOfParts,
                                    labourCost,
                                    totalCostOfFix,
                                    boilerMake,
                                    maxLabourCost,
                                    previousEngineerVisits,
                                    partsRequested,
                                    issueArea,
                                    manuallyAuthorised,
                                };
                                const submittedValue = {
                                    estimatedHoursNeeded,
                                    estimatedPartsCost: Math.ceil(totalCostOfParts * 100) / 100,
                                    partsDescription: partsRequested
                                        .map(({ name, quantity }) => `${quantity}x ${name}`)
                                        .join(', '),
                                    partsRequested,
                                    maxLabourCost,
                                };
                                captureSentryInfo('New job authorise request', {
                                    params: {
                                        partsRequested: JSON.stringify(partsRequested),
                                        maxLabourCostAuthorised: maxLabourCost,
                                    },
                                });
                                onSubmit(
                                    submittedValue,
                                    nextQuestionSelector
                                        ? nextQuestionSelector(nextQuestionInput, formState)
                                        : nextQuestion
                                );
                            }}
                        >
                            {value}
                        </Button>
                    );
                }

                return <>Loading...</>;
            })}
        </>
    );
};

export default PartsNeeded;
