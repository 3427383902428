import { useQuery } from 'react-query';
import { getContractById } from '../features/newClaim/api/getContract';
import { Contract } from '../features/newClaim/types/Contract';

export const useGetContractQuery = (contractId: number | undefined, isLoadingAccount: boolean) => {
    const { isLoading, isError, error, data } = useQuery<Contract, Error>(
        ['contract', contractId],
        getContractById,
        { enabled: !isLoadingAccount }
    );

    return {
        isLoading,
        isError,
        error,
        data,
    };
};
