import { getMandatoryEnvVar } from 'src/utils/env';
import { CustomerData } from 'src/types/CustomerData';
import { HOMETREE_PHONE_NUMBER, INTEGRATION_ENDPOINT } from '../constants';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

const GENERIC_ERROR_MESSAGE = `Login error, please check your login details and try again. If this message persists please call ${HOMETREE_PHONE_NUMBER}`;

const errorMessageMap = {
    4: `It seems like your payments are not up to date, so we will not be able to process your claim. You will need to give us a call on ${HOMETREE_PHONE_NUMBER} and reset your payments before we can book your claim.`,
    5: `Sorry, we were not able to find you in our records. Please try again and if the message persists, please give us a call on ${HOMETREE_PHONE_NUMBER} and we will be able to help you.`,
    6: `You are in your cooling-off period, so we will not be able to process your claim at this time. Please try again after 14 days have passed since your start date.`,
    9: `Sorry, we are not able to book your claim online. Please give us a call on ${HOMETREE_PHONE_NUMBER} and we will be able to help you.`,
};

export const sendAuthRequest = async (body: Record<string, unknown>) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
    };
    const response = await fetch(
        `${API_BASE_URL}/${INTEGRATION_ENDPOINT}/contract/getCustomer`,
        requestOptions
    );
    const { data } = await response.json();

    if ('error' in data) {
        const { code } = data.error;
        throw new Error(errorMessageMap[code] || GENERIC_ERROR_MESSAGE);
    }

    if (data.count > 1) {
        throw new Error(
            `It seems that you have multiple properties at that postcode. Please give us a call on ${HOMETREE_PHONE_NUMBER} so we can make sure we send an engineer to the right one.`
        );
    }

    return data as CustomerData;
};
