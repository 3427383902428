import { CustomerData } from 'src/types/CustomerData';
import { QuestionStage, QuestionType } from '../types/Question';
import { FormState } from '../types/Form';

export const windowsQuestions = (_customerData: CustomerData): QuestionStage[] => {
    return [
        {
            handle: 'windowsGroup1',
            questions: [
                {
                    name: 'affectedWindowInMainHouse',
                    description:
                        'Is the affected window part of the main house? (not a separate building or structure)',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            { copy: 'Yes', value: 'Yes' },
                            { copy: 'No', value: 'No' },
                        ],
                    },
                },
                {
                    name: 'externalWindow',
                    description: 'Is the window external?',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            { copy: 'Yes', value: 'Yes' },
                            { copy: 'No', value: 'No' },
                        ],
                    },
                },
                {
                    name: 'insecureProperty',
                    description: 'Is your property insecure?',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            { copy: 'Yes', value: 'Yes' },
                            { copy: 'No', value: 'No' },
                        ],
                    },
                },
            ],
            onSubmit: (values) => {
                const formState = {
                    affectedWindowInMainHouse: values.affectedWindowInMainHouse,
                    externalWindow: values.externalWindow,
                    insecureProperty: values.insecureProperty,
                } as Partial<FormState>;

                if (values.affectedWindowInMainHouse === 'No') {
                    formState.rejectProduct = 'a window that is not part of the main house';
                }

                if (values.externalWindow === 'No') {
                    formState.rejectProduct = 'internal windows';
                }

                return formState;
            },
            options: [
                {
                    copy: 'Next',
                    nextQuestion: (values) => {
                        if (
                            values.affectedWindowInMainHouse === 'No' ||
                            values.externalWindow === 'No'
                        ) {
                            return 'optionNotCovered';
                        }

                        return 'vulnerability';
                    },
                },
            ],
        },
    ];
};
