import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Button from 'src/components/Button';
import Layout from 'src/components/Layout';
import NavbarLogout from 'src/features/logout/components/NavBarLogout';
import { Account, Document } from 'src/images';
import { Routes } from 'src/types/Routes';
import { newVirtualPageView } from 'src/utils/analytics';
import { mixpanelTrackPage } from 'src/utils/mixpanel';

import styles from './index.module.scss';

const EngineerAccount = () => {
    const pageCount = 0;
    const location = useLocation();

    const onFeedbackButtonClick = () => {
        window.open('https://zcm8o7t0fr2.typeform.com/to/wFsejFAK', '_blank');
    };

    useEffect(() => {
        newVirtualPageView('engineer-account', location.pathname);
        mixpanelTrackPage();
    }, [pageCount, location]);

    return (
        <Layout>
            <article className={styles.container}>
                <section className={styles.account_header}>
                    <h3>Account & Settings</h3>
                    <NavbarLogout />
                </section>
                <section className={styles.gridContainer}>
                    <div className={styles.navButton}>
                        <Link to={Routes.ENGINEER_ACCOUNT_PROFILE}>
                            <Account />
                            Profile
                        </Link>
                    </div>
                    <div className={styles.navButton}>
                        <Link to={Routes.ENGINEER_ACCOUNT_INVOICES}>
                            <Document />
                            Invoices
                        </Link>
                    </div>
                </section>
                <section className={styles.button_container}>
                    <Button customStyle={styles.feedback_button} onClick={onFeedbackButtonClick}>
                        Give us Feedback!
                    </Button>
                </section>
            </article>
        </Layout>
    );
};

export default EngineerAccount;
