import { QueryFunction } from 'react-query';
import _parseISO from 'date-fns/parseISO';
import { getMandatoryEnvVar } from 'src/utils/env';
import { ACCOUNT_ENDPOINT } from 'src/constants';
import {
    getAuthenticatedUser,
    getTokenFromAuthenticatedUser,
    getAttributesFromAuthenticatedUser,
} from '../utils/authenticatedUser';
import { Account } from './account.types';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');
const apiGetAccountQueryParams = '?expand=properties,contracts&payment_status=true';

const CONTRACT_STATUS_ACTIVE = 4;

const removeInactiveContracts = (contracts: any) =>
    contracts.filter(({ status }) => status === CONTRACT_STATUS_ACTIVE);

const mapGetAccountResponseToAccount = (data: any): Account | null => {
    const accountData = data?.data[0];

    if (!accountData) return null;

    const accountContracts = accountData.relations?.contracts || [];
    const accountProperties = accountData.relations?.properties || [];

    return {
        id: accountData.id,
        active: Boolean(accountData.active),
        firstName: accountData.first_name,
        lastName: accountData.last_name,
        middleName: accountData.middle_name,
        email: accountData.email,
        phone1: {
            prefix: accountData.phone1_prefix,
            number: accountData.phone1,
        },
        phone2: {
            prefix: accountData.phone2_prefix,
            number: accountData.phone2,
        },
        customerFacingId: accountData.customer_facing_id,
        contracts: removeInactiveContracts(accountContracts).map((contract) => {
            const property = accountProperties.find(({ id }) => contract.property_id === id) || {};

            return {
                id: contract.id,
                status: contract.status,
                packageId: contract.package_id,
                startDate: _parseISO(contract.start_date),
                endDate: _parseISO(contract.end_date),
                property: {
                    id: property.id,
                    town: property.town,
                    active: Boolean(property.active),
                    country: property.country,
                    postcode: property.postcode,
                    address: {
                        line1: property.address_line_1,
                        line2: property.address_line_2,
                        line3: property.address_line_3,
                    },
                },
                billingType: contract.billing_type,
                contribution: contract.contribution,
                pricebookId: contract.pricebook_id,
                paymentAmount: contract.payment_amount,
                contractCustomerFacingId: contract.customer_facing_id,
                paymentDetails: {
                    mandateId: contract.go_cardless_mandate_id,
                    customerId: contract.go_cardless_customer_id,
                    subscriptionId: contract.go_cardless_subscription_id,
                },
                isRenewal: contract.is_renewal,
            };
        }),
    };
};

type GetAccountQueryKey = [string];

const getAccount: QueryFunction<any, GetAccountQueryKey> = async () => {
    const user = await getAuthenticatedUser();
    const token = getTokenFromAuthenticatedUser(user);
    const attributes = getAttributesFromAuthenticatedUser(user);
    const accountId = attributes['custom:account_id'];

    const getAccountUrl = `${API_BASE_URL}/${ACCOUNT_ENDPOINT}/accounts/${accountId}${apiGetAccountQueryParams}`;

    try {
        const response = await fetch(getAccountUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });

        // TODO add type validator
        const responseJson = await response.json();
        const { data } = responseJson;

        if ('error' in data) {
            const { message } = data.error;
            throw new Error(message);
        }

        // eslint-disable-next-line @typescript-eslint/return-await
        return responseJson;
    } catch (err) {
        const errorMessage = `Failed to get account - ${err.message}`;
        // eslint-disable-next-line no-console
        console.error(errorMessage, err);
        throw new Error(errorMessage);
    }
};

export { getAccount, mapGetAccountResponseToAccount };
