import TagManager from 'react-gtm-module';

const initializeTagManager = (tagManagerId: string) => {
    // If already initialized, do nothing
    if (window.dataLayer) return;

    const tagManagerArgs = {
        gtmId: tagManagerId,
    };

    TagManager.initialize(tagManagerArgs);
};

export default initializeTagManager;
