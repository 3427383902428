import { QueryFunction, useQuery } from 'react-query';
import { CustomerData } from 'src/types/CustomerData';
import { sendAuthRequest } from '../api/authentication';

export const EMAIL_AND_POSTCODE_REQUIRED = 'Email and postcode required';

type GetCustomerQueryKey = [string, { email: string; postcode: string }];

const getCustomer: QueryFunction<CustomerData, GetCustomerQueryKey> = async ({ queryKey }) => {
    const [, { email, postcode }] = queryKey;

    if (!email || !postcode) {
        throw new Error(EMAIL_AND_POSTCODE_REQUIRED);
    }

    // Not really an auth request, function gets customer details
    return sendAuthRequest({
        email,
        postcode,
    });
};

export const useCustomerQuery = (email, postcode) =>
    useQuery(['customer', { email, postcode }], getCustomer);
