import { useState } from 'react';
import Button from 'src/components/Button';
import { Option } from '../../../types/Question';
import style from './index.module.scss';

export interface Props {
    title: string;
    options: Option[];
    onSubmit: (value: string, nextQuestion?: string) => void;
    isOptional?: boolean;
}

const CURRENCY_REGEX = /^[0-9]{1,4}(?:\.[0-9]{1,2})?$/;

const CurrencyQuestion = ({ title, options, onSubmit, isOptional = false }: Props) => {
    const [inputValue, setInputValue] = useState('');
    const [errors, setErrors] = useState(false);
    const validate = () => !!inputValue && CURRENCY_REGEX.test(inputValue);

    const onChange = (e) => {
        const currentValue = e.target.value;
        if (currentValue) {
            setErrors(false);
        }
        // Remove £ symbol from the value
        const valueNoPoundSign = currentValue.substring(1);

        setInputValue(valueNoPoundSign);
    };

    const validationError = (
        <div className={style.validationError} role="alert">
            Field is required
        </div>
    );

    return (
        <div className={style.container}>
            <h3 className={style.title}>{title}</h3>
            <div className={style.inputAndError}>
                <input
                    type="text"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={`£${inputValue}`}
                    onChange={onChange}
                    className={`${errors ? style.errorBorder : ''}`}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                    aria-label={title}
                />
                {errors ? validationError : <></>}
            </div>

            {options.map(({ value, nextQuestion }) => {
                return (
                    <Button
                        key={value}
                        onClick={() => {
                            if (!isOptional && !validate()) {
                                setErrors(true);
                            } else {
                                onSubmit(inputValue, nextQuestion);
                            }
                        }}
                    >
                        <>{value}</>
                    </Button>
                );
            })}
        </div>
    );
};

export default CurrencyQuestion;
