import { JOBS_PUBLIC_ENDPOINT } from 'src/constants';
import { getAuthenticatedUser, getTokenFromAuthenticatedUser } from 'src/features/login';
import { captureSentryError } from 'src/features/login/utils/captureSentryError';
import { getMandatoryEnvVar } from 'src/utils/env';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

const startJobError = new Error('Failed to start job');

export const startJob = async (jobId: string): Promise<{}> => {
    const user = await getAuthenticatedUser();
    const token = getTokenFromAuthenticatedUser(user);

    const startJobUrl = `${API_BASE_URL}/${JOBS_PUBLIC_ENDPOINT}/v1/engineer-jobs/start/${jobId}`;

    const requestOptions = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({}),
    };

    try {
        const response = await fetch(startJobUrl, requestOptions);
        const data = await response.json();

        if (!response.ok) {
            const error = (data && data.message) || response.status;
            captureSentryError(startJobError, { error, jobId, responseStatus: response.status });
            throw startJobError;
        }

        return data;
    } catch (error) {
        captureSentryError(startJobError, { error, jobId });
        throw startJobError;
    }
};
