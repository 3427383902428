import isPast from 'date-fns/isPast';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/Button';
import Expando from 'src/features/expando';
import { JobDetails } from 'src/features/jobDetails';
import { CheckGrey, MapPinGrey, PowerGrey } from 'src/images';
import { JobDetails as JobDetailsResponse } from 'src/types/Job';
import { getNavigationUrl } from 'src/utils/address';
import { abbreviateJobSummary } from 'src/utils/jobs';
import toastConfig from 'src/utils/toastConfig';
import { Routes as RoutePath } from '../../../../types/Routes';
import JobCardHeader from '../JobCardHeader';
import JobCardMain from '../JobCardMain';
import { Modal } from '../Modal';
import CancelForm from './CancelForm';
import RescheduleErrorAlert from './RescheduleErrorAlert';
import style from './index.module.scss';

const largeWidth = 1200;

export type Props = {
    job: JobDetailsResponse;
    onStartTravel: () => void;
    onStart: () => void;
    onClose: () => void;
    onReschedule: () => void;
};

const JobCard = ({ job, onStartTravel, onStart, onClose, onReschedule }: Props) => {
    const [updating, setUpdating] = useState(false);
    const [showCloseJob, setShowCloseJob] = useState(job.sk_job_status === 'In Progress');
    const [showStartJob, setShowStartJob] = useState(job.sk_job_status === 'En Route');
    const [showCancelForm, setShowCancelForm] = useState(false);
    const [isJobCardHiding, setIsJobCardHiding] = useState(false);
    const [isNavModalOpen, setIsNavModalOpen] = useState(false);
    const [showCantReschedule, setShowCantReschedule] = useState(false);

    const navigate = useNavigate();

    const isMissedJob = isPast(new Date(job.end || Date.now()));

    if (!job.start || !job.end) {
        // eslint-disable-next-line no-console
        console.error(`Job missing start or end: jobId - ${job.sk_job_id}`);
        return <></>;
    }
    const startDate = new Date(job.start);
    const endDate = new Date(job.end);

    const showModal = () => {
        setIsNavModalOpen(true);
    };

    const hideModal = () => {
        setIsNavModalOpen(false);
    };

    const startNav = () => {
        const navAddress = getNavigationUrl(job.address_line_1, job.postcode);
        window.open(navAddress, '_blank');
        hideModal();
    };

    const onStartTravelButtonClick = async () => {
        setUpdating(true);
        await onStartTravel();

        // eslint-disable-next-line no-restricted-globals
        if (window.screen.availWidth < largeWidth) {
            showModal();
        }
        // Allow time for webhook to update the status
        setTimeout(() => {
            setUpdating(false);
            setShowStartJob(true);

            toast.success(
                `Travel has started for job "${abbreviateJobSummary(job.summary)}"`,
                toastConfig
            );
        }, 2000);
    };

    const onStartJobButtonClick = async () => {
        setUpdating(true);
        await onStart();

        // Allow time for webhook to update the status
        setTimeout(() => {
            setUpdating(false);
            setShowStartJob(false);
            setShowCloseJob(true);

            toast.success(`Started job "${abbreviateJobSummary(job.summary)}"`, toastConfig);
        }, 2000);

        await new Promise((resolve) => setTimeout(resolve, 3000));

        navigate(RoutePath.CLOSE_JOB.replace(':jobId', job.sk_job_id));
    };

    const handleReschedule = () => {
        if (job.number_of_cancelled_jobs_in_claim >= 1) {
            setShowCantReschedule(true);
            return;
        }
        onReschedule();
    };

    const detailActionButtons = (
        <>
            <Button
                customStyle={style.detailActionButtons}
                onClick={handleReschedule}
                variant="primary"
                isDisabled={updating}
            >
                <span>I need to reschedule</span>
            </Button>
            <Button
                customStyle={style.detailActionButtons}
                onClick={() => {
                    setShowCancelForm(!showCancelForm);
                }}
                variant="secondary"
                isDisabled={updating}
            >
                <span>Cancel visit</span>
            </Button>
        </>
    );

    const calculateActionButtons = () => (
        <>
            {job.sk_job_status === 'Ready' && !showStartJob && !showCloseJob && (
                <Button
                    customStyle={`${style.actionButton}`}
                    onClick={onStartTravelButtonClick}
                    isDisabled={updating}
                >
                    {updating ? (
                        'Updating...'
                    ) : (
                        <>
                            <MapPinGrey />
                            <span>En Route</span>
                        </>
                    )}
                </Button>
            )}

            {(job.sk_job_status === 'En Route' || showStartJob) && !showCloseJob && (
                <Button
                    customStyle={`${style.actionButton}`}
                    onClick={onStartJobButtonClick}
                    isDisabled={updating}
                >
                    {updating ? (
                        'Updating...'
                    ) : (
                        <>
                            <PowerGrey />
                            <span>Start Job</span>
                        </>
                    )}
                </Button>
            )}

            {(job.sk_job_status === 'In Progress' || showCloseJob) && (
                <Button customStyle={style.actionButton} onClick={onClose}>
                    <CheckGrey />
                    <span>Complete Job</span>
                </Button>
            )}
        </>
    );

    if (isJobCardHiding) {
        return <></>;
    }

    return (
        <>
            {isNavModalOpen ? (
                <div className={style.modal}>
                    <Modal hide={hideModal}>
                        <p>Start travel with default navigation app?</p>
                        <div className={style.modalButtons}>
                            <Button
                                variant="secondary"
                                onClick={hideModal}
                                customStyle={style.skipButton}
                            >
                                Skip
                            </Button>
                            <Button onClick={startNav}>Go</Button>
                        </div>
                    </Modal>
                </div>
            ) : (
                <></>
            )}
            <Expando
                header={<JobCardHeader postcode={job.postcode} jobName={job.sk_job_name} />}
                main={
                    <JobCardMain
                        start={startDate}
                        end={endDate}
                        status={isMissedJob ? 'Action Required' : job.sk_job_status}
                        actionButtons={calculateActionButtons()}
                    />
                }
                triggerSetExpanded={showCancelForm}
                theme={isMissedJob ? 'purple' : 'grey'}
            >
                {!showCancelForm && !showCantReschedule && (
                    <JobDetails details={job} actionButtons={detailActionButtons} />
                )}
                {showCancelForm && (
                    <CancelForm
                        job={job}
                        setShowCancelForm={setShowCancelForm}
                        setIsJobCardHiding={setIsJobCardHiding}
                    />
                )}
                {showCantReschedule && (
                    <RescheduleErrorAlert setShowCantReschedule={setShowCantReschedule} />
                )}
            </Expando>
        </>
    );
};

export default JobCard;
