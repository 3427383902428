import { CheckCircleRainbow } from 'src/images';
import { useNavigate, useParams } from 'react-router-dom';
import { Routes } from 'src/types/Routes';
import { useQueryClient } from 'react-query';
import Button from 'src/components/Button';
import style from './index.module.scss';

interface CompleteProps {
    title?: string;
    extraCopy?: string;
}
const Complete = ({ title = 'Completed!', extraCopy = undefined }: CompleteProps) => {
    const navigate = useNavigate();
    const { jobId } = useParams();
    const queryClient = useQueryClient();
    return (
        <>
            <CheckCircleRainbow className={style.icon} />
            <span className={style.title}>{title}</span>
            {extraCopy && <div className={style.extraCopy}>{extraCopy}</div>}
            <Button
                onClick={() => {
                    queryClient.invalidateQueries(['jobDetails']);
                    navigate(Routes.JOB_AGENDA, { state: { hiddenJob: jobId } });
                }}
            >
                Back to agenda
            </Button>
        </>
    );
};

export default Complete;
