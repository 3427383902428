import { CustomerData } from 'src/types/CustomerData';
import { FormState } from '../types/Form';
import { QuestionType, QuestionStage } from '../types/Question';

export const electricQuestions = (_customerData: CustomerData): QuestionStage[] => {
    return [
        {
            handle: 'electricGroup1',
            questions: [
                {
                    name: 'electricFailure',
                    description:
                        'Do you have total or partial power loss that is impacting your safety or comfort in the home?',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            { copy: 'Yes', value: 'Yes' },
                            { copy: 'No', value: 'No' },
                        ],
                    },
                },
                {
                    name: 'electricIssue',
                    description: 'Does your issue relate to:',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            {
                                copy: 'Indoor sockets, lighting or switches',
                                value: 'Indoor sockets, lighting or switches',
                            },
                            {
                                copy: 'Outdoor lighting',
                                value: 'Outdoor lighting',
                            },
                            {
                                copy: 'Doorbells, smoke alarms or up to 15cm extractors, connected to the main wiring',
                                value: 'Doorbells, smoke alarms or up to 15cm extractors, connected to the main wiring',
                            },
                            {
                                copy: 'Electric showers',
                                value: 'Electric showers',
                            },
                            {
                                copy: 'Electrics or underfloor heating systems',
                                value: 'Electrics or underfloor heating systems',
                            },
                        ],
                    },
                },
            ],
            onSubmit: (values) => {
                const formState = {
                    electricFailure: values.electricFailure,
                    electricIssue: values.electricIssue,
                } as Partial<FormState>;

                if (values.electricIssue === 'Electric showers') {
                    formState.rejectProduct = 'electric showers';
                }

                if (values.electricIssue === 'Electrics or underfloor heating systems') {
                    formState.rejectProduct = 'electrics or underfloor heating systems';
                }

                return formState;
            },
            options: [
                {
                    copy: 'Next',
                    nextQuestion: (values) => {
                        if (
                            values.electricFailure === 'No' &&
                            _customerData.packageDetails &&
                            _customerData.packageDetails.onlyLossOfService === true &&
                            _customerData.packageDetails.requiresInsurerApproval === true &&
                            values.electricIssue !== 'Electric showers' &&
                            values.electricIssue !== 'Electrics or underfloor heating systems'
                        ) {
                            return 'lossOfServiceNotCoveredNoExtraCopy';
                        }

                        if (
                            values.electricFailure === 'No' &&
                            _customerData.packageDetails &&
                            _customerData.packageDetails.onlyLossOfService === true &&
                            values.electricIssue !== 'Electric showers' &&
                            values.electricIssue !== 'Electrics or underfloor heating systems'
                        ) {
                            return 'lossOfServiceNotCovered';
                        }

                        if (values.electricIssue === 'Outdoor lighting') {
                            return 'electricGroup2';
                        }

                        if (
                            values.electricIssue === 'Indoor sockets, lighting or switches' ||
                            values.electricIssue ===
                                'Doorbells, smoke alarms or up to 15cm extractors, connected to the main wiring'
                        ) {
                            return 'vulnerability';
                        }

                        return 'optionNotCovered';
                    },
                },
            ],
        },
        {
            handle: 'electricGroup2',
            questions: [
                {
                    name: 'electricOutdoorAbove3m',
                    description:
                        'Is the lighting fixed to your home and less than 3 meters above the ground?',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            { copy: 'Yes', value: 'Yes' },
                            { copy: 'No', value: 'No' },
                        ],
                    },
                },
            ],
            onSubmit: (values) => {
                const formState = {
                    electricOutdoorAbove3m: values.electricOutdoorAbove3m,
                } as Partial<FormState>;
                if (values.electricOutdoorAbove3m === 'No') {
                    formState.rejectProduct = 'electrics more than 3 meters above the ground';
                }

                return formState;
            },
            options: [
                {
                    copy: 'Next',
                    nextQuestion: (values) => {
                        if (values.electricOutdoorAbove3m === 'No') {
                            return 'optionNotCovered';
                        }

                        return 'vulnerability';
                    },
                },
            ],
        },
    ];
};
