import { decodeString } from 'src/utils/decodeString';
import {
    Attention,
    DrainsIcon,
    GasIcon,
    MapPin,
    Phone,
    PlumbingIcon,
    VulnerableIcon,
} from 'src/images';
import { JobDetails as JobDetailsResponse, JobType } from 'src/types/Job';
import { getNavigationUrl } from 'src/utils/address';
import style from './index.module.scss';

interface Props {
    details: JobDetailsResponse;
    showJobId?: boolean;
    actionButtons?: React.ReactNode;
}

const getJobIcon = (jobType: JobType) => {
    switch (jobType) {
        case 'Gas Job':
            return <GasIcon />;
        case 'Plumbing':
            return <PlumbingIcon />;
        case 'Drains':
            return <DrainsIcon />;
        default:
            // TODO add missing job type icons when available
            return <Attention />;
    }
};

const JobDetails = ({ details, showJobId = true, actionButtons }: Props) => {
    if (!details.phone1_prefix && !details.phone2_prefix && !details.phone1 && !details.phone2) {
        // eslint-disable-next-line no-console
        console.error(
            `Missing phone number for job id: ${details.sk_job_id}. Check the job details in the DB.`,
            details
        );
    }
    const navAddress = getNavigationUrl(details.address_line_1, details.postcode);

    return (
        <>
            <section className={style.jobDetails}>
                <span className={style.title}>JOB DETAILS</span>
                <div className={style.container}>
                    <div className={style.overview}>
                        <span className={style.summary}>{decodeString(details.summary)}</span>
                        <span className={style.flags}>
                            <div>
                                {getJobIcon(details.job_type)} {details.job_type}
                            </div>

                            {details.sk_urgency && (
                                <div>
                                    <Attention />
                                    <span>{details.sk_urgency}</span>
                                </div>
                            )}

                            {details.sk_vulnerable_customer && (
                                <div>
                                    <VulnerableIcon />
                                    <span>Vulnerable</span>
                                </div>
                            )}
                        </span>
                    </div>

                    <div className={style.separator} />

                    <div className={style.customer}>
                        {showJobId && (
                            <>
                                <div className={style.idMap}>
                                    <div>
                                        <span>ID</span> {details.sk_job_name}
                                    </div>

                                    <div className={style.mapMobile}>
                                        <a href={navAddress}>
                                            <MapPin />
                                        </a>
                                        <span>Map</span>
                                    </div>
                                </div>
                            </>
                        )}

                        <div className={style.customerInformation}>
                            <p>
                                {details.first_name} {details.last_name}
                            </p>
                            <p>
                                {decodeString(
                                    `${details.address_line_1}, ${details.town} ${details.postcode}`
                                )}
                            </p>
                        </div>

                        {details.phone1 && (
                            <div className={style.telephone}>
                                <a href={`tel:+${details.phone1_prefix}${details.phone1}}`}>
                                    <Phone />
                                    <span>
                                        +{details.phone1_prefix}&nbsp;{details.phone1}
                                    </span>
                                </a>
                            </div>
                        )}

                        {details.phone2 && (
                            <div className={style.telephone2}>
                                <a href={`tel:+${details.phone2_prefix}${details.phone2}}`}>
                                    <Phone />
                                    <span>
                                        +{details.phone2_prefix}&nbsp;{details.phone2}
                                    </span>
                                </a>
                            </div>
                        )}
                    </div>
                </div>

                {actionButtons && <div className={style.actionButtons}>{actionButtons}</div>}
            </section>
        </>
    );
};

export default JobDetails;
