import Layout from 'src/components/Layout';
import Attention from '../../../images/attention.svg';
import styles from './index.module.scss';

interface Props {
    content?: string;
}

export const NotImplemented = ({ content = '' }: Props) => {
    return (
        <Layout>
            <section className={styles.notImplementedPage}>
                <img src={Attention} alt="Page not implemented" />
                <h2>It seems like you have arrived at a place that does not exist yet.</h2>
                <p>{content}</p>
            </section>
        </Layout>
    );
};
