import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import ErrorBar from 'src/components/ErrorBar';
import Layout from 'src/components/Layout';
import Loader from 'src/components/Loader';
import { HOMETREE_PHONE_NUMBER } from 'src/constants';
import { useAccountQuery } from 'src/hooks/useAccountQuery';
import { Routes } from 'src/types/Routes';
import { newAnalyticsEvent, newVirtualPageView } from 'src/utils/analytics';
import { mixpanelTrackPage } from 'src/utils/mixpanel';
import { getUserModeId } from 'src/utils/userMode';

import { AreaOfIssue } from 'src/types/Job';
import { getMandatoryEnvVar } from 'src/utils/env';
import { useGetContractQuery } from '../../../../hooks/useGetContractQuery';
import { createClaim } from '../../api/createClaim';
import { FnolTrackingRequestBody, trackFnolData } from '../../api/fnolTracking';
import { questionStages } from '../../config/questions';
import { jobTypeMap } from '../../data/jobTypeMap';
import { END_STAGE_HANDLES, INITIAL_STAGE_HANDLE } from '../../data/stageConfig';
import { CreateClaimRequestBody } from '../../types/CreateClaimRequestBody';
import { FormState, Reattend } from '../../types/Form';
import { buildCustomerData } from '../../utils/buildCustomerData';
import { getMostRecentReattendJobName } from '../../utils/jobs';
import Form from '../Form';
import styles from './index.module.scss';

export const CONTRACT_ID_REQUIRED = 'Contract ID required';

interface Props {
    userMode?: any;
    onUpdate: (claimState: any) => void;
}

const NewClaim = ({ userMode, onUpdate }: Props) => {
    const pageCount = 0;
    const location = useLocation();
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [newClaimError, setNewClaimError] = useState('');
    const [fnolTrackingId, setFnolTrackingId] = useState<number | null>(null);
    const [formState, setFormState] = useState<Partial<FormState>>({});
    const [reattend, setReattend] = useState<Reattend>(Reattend.NO);
    const [relatedJob, setRelatedJob] = useState<string | null>(null);
    const enabledAppliances = getMandatoryEnvVar('REACT_APP_APPLIANCES_ENABLED');

    const { contractId } = (location.state as any) || {};

    const [searchParams] = useSearchParams();
    const journey = searchParams.get('journey');

    const {
        data: accountData,
        isLoading: isAccountLoading,
        isError: isAccountError,
        error: accountError,
    } = useAccountQuery();

    const {
        data: contractData,
        isLoading: isContractLoading,
        isError: isContractError,
        error: contractError,
    } = useGetContractQuery(contractId, isAccountLoading);

    useEffect(() => {
        // Wait until data is retrieved for customer
        if (contractData?.contractCustomerFacingId) {
            newVirtualPageView('FNOL', '/vpv/fnol');
            newAnalyticsEvent({
                category: 'FNOL',
                action: 'Landed',
                label: contractData.contractCustomerFacingId,
                value: getUserModeId(userMode),
            });
        }
        mixpanelTrackPage();
    }, [pageCount, userMode, contractData]);

    useEffect(() => {
        // Wait until data is retrieved for customer
        if (contractData?.jobs) {
            const appliancesTypes =
                formState.jobType === AreaOfIssue.APPLIANCES && jobTypeMap[formState.jobType];
            const jobTypeMapped = jobTypeMap[formState.jobType || ''];

            const mostRecentReattendJobName = getMostRecentReattendJobName(
                contractData?.jobs || [],
                jobTypeMapped,
                appliancesTypes
            );

            setRelatedJob(mostRecentReattendJobName);
            setReattend(mostRecentReattendJobName ? Reattend.YES : Reattend.NO);
        }
    }, [formState, contractData]);

    if (isAccountLoading || isContractLoading) {
        return <Loader />;
    }

    if (isContractError || !contractData) {
        // if error is missing contract id, something has gone wrong within the flow
        // of the app and we should redirect to properties page
        const errorMessage = contractError?.message || 'Contract data not retrieved';

        if (errorMessage === CONTRACT_ID_REQUIRED) {
            // Landed on this page skipping a step in the flow. We should look to tighten this up more
            // but for now should be enough to navigate back to start of the flow (properties route)
            // eslint-disable-next-line no-console
            console.error(errorMessage);
            navigate(journey ? `${Routes.PROPERTIES}?journey=${journey}` : Routes.PROPERTIES);
        }

        const defaultErrorMessage = `We're having trouble getting your contract information. If this message persists please call ${HOMETREE_PHONE_NUMBER}`;
        return <ErrorBar message={defaultErrorMessage} style={styles.claimFormErrorBar} />;
    }

    if (isAccountError || !accountData) {
        const errorMessage = accountError?.message || 'Account data not retrieved';
        // eslint-disable-next-line no-console
        console.error(errorMessage);
        const defaultErrorMessage = `We're having trouble getting your account information. If this message persists please call ${HOMETREE_PHONE_NUMBER}`;
        return <ErrorBar message={defaultErrorMessage} style={styles.claimFormErrorBar} />;
    }

    if (contractData.canRaiseClaim === null) {
        if (!contractData.paymentsUpToDate) {
            const errorMessage = `It seems like your payments are not up to date, so we will not be able to process your claim. You will need to give us a call on ${HOMETREE_PHONE_NUMBER} and reset your payments before we can book your claim.`;

            return <ErrorBar message={errorMessage} style={styles.claimFormErrorBar} />;
        }
    } else if (contractData.canRaiseClaim === false) {
        const errorMessage = `You cannot currently raise a claim. You will need to give us a call on ${HOMETREE_PHONE_NUMBER} before we can book your claim.`;

        return <ErrorBar message={errorMessage} style={styles.claimFormErrorBar} />;
    }

    if (newClaimError) {
        return <ErrorBar message={newClaimError} style={styles.claimFormErrorBar} />;
    }

    const customerData = buildCustomerData(accountData, contractData);

    if (customerData.claimLimitReached) {
        setNewClaimError(
            `Unfortunately, you are above or at risk of being above your annual claim limit mandated by your T&Cs. Please reach out to us by calling ${HOMETREE_PHONE_NUMBER} between 8:00-19:00 so we can support you on your next steps with raising your claim`
        );
    }

    const trackFnolDataWrapper = async (data: FnolTrackingRequestBody) => {
        const newFnolTrackingId = await trackFnolData({
            id: fnolTrackingId || undefined,
            ...data,
        });

        if (newFnolTrackingId instanceof Error) return;

        if (fnolTrackingId !== newFnolTrackingId) {
            setFnolTrackingId(newFnolTrackingId);
        }
    };

    const onSubmit = async (submitFormState: Partial<FormState>) => {
        setIsSubmitting(true);

        const { appliances, packageDetails, includedProducts, jobs, ...rest } = customerData;

        const body: CreateClaimRequestBody = {
            ...submitFormState,
            ...rest,
            reattend,
            relatedJob,
            userMode,
            parking: 'Yes',
            jobType: jobTypeMap[formState.jobType || ''],
            ...(reattend === Reattend.YES && { visitType: 'Reattend' }),
        };

        newAnalyticsEvent({
            category: 'FNOL',
            action: 'Clicked create job',
            label: customerData.customerFacingId,
            value: getUserModeId(userMode),
        });

        const createClaimResult = await createClaim(body);

        if (createClaimResult instanceof Error) {
            setNewClaimError(
                `It seems like something went wrong. Please refresh and try again. If this message persists please call ${HOMETREE_PHONE_NUMBER}`
            );
            return;
        }

        onUpdate({
            ...body,

            // TODO: This is how confirmation expects the data, simplify once we get rid of the old newClaim page
            skeduloJobDetails: {
                jobs: {
                    edges: [
                        {
                            node: {
                                Name: createClaimResult.jobName,
                            },
                        },
                    ],
                },
            },
        });

        await trackFnolDataWrapper({
            contractId: contractData.id,
            skJobUid: createClaimResult.jobUid,
        });

        // Navigate to confirmation page
        navigate(Routes.CLAIM_CONFIRMATION);

        setIsSubmitting(false);
    };

    return (
        <Layout>
            <Form
                questionStages={questionStages(customerData, formState, reattend)}
                initialStageHandle={
                    journey === 'new' && enabledAppliances === 'true'
                        ? 'problemAreaNew'
                        : INITIAL_STAGE_HANDLE
                }
                endStageHandles={END_STAGE_HANDLES}
                formSubmit={onSubmit}
                isFormSubmitting={isSubmitting}
                customerData={customerData}
                userMode={userMode}
                trackFnolData={trackFnolDataWrapper}
                formState={formState}
                setFormState={setFormState}
            />
        </Layout>
    );
};

export default NewClaim;
