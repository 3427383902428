import { MapPin } from 'src/images';
import style from './index.module.scss';

interface Props {
    postcode: string;
    jobName: string;
}

const JobCardHeader = ({ postcode, jobName }: Props) => {
    return (
        <div className={style.header}>
            <div className={style.postcodeContainer}>
                <MapPin />
                <span className={style.postcode}>{postcode}</span>
            </div>
            <div className={style.jobName}>{jobName}</div>
        </div>
    );
};

export default JobCardHeader;
