import { FormikErrors } from 'formik';
import React from 'react';
import DateRadio from 'src/components/Calendar/DateRadio';
import { JobUrgency } from 'src/types/JobUrgency';
import { isDayBookable } from '../../utils/dateForm';
import { dateToValue } from './formatting';
import style from './index.module.scss';

type DateOption = {
    available: boolean;
    dayOfMonth: number;
    dayName: string;
    dateValue: string;
};

const generateDates = (urgency: JobUrgency = JobUrgency.NORMAL): DateOption[] => {
    const weekArray = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const daysShown = urgency === JobUrgency.NORMAL ? 30 : 7;

    return [...Array(daysShown).keys()]
        .map((numberOfDays) => numberOfDays + 1) // Start showing dates from tomorrow
        .map((numberOfDays) => {
            const date = new Date(Date.now());
            date.setDate(date.getDate() + numberOfDays);
            return date;
        })
        .map((date) => ({
            available: isDayBookable(date, urgency),
            dayOfMonth: date.getDate(),
            dayName: weekArray[date.getDay()],
            dateValue: dateToValue(date),
        }));
};

type Props = {
    errors: FormikErrors<any>;
    name: string;
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    selectedDate?: string;
    subtitle?: string;
    title: string | React.ReactNode | Element;
    urgency?: JobUrgency;
};

const DateForm = ({ errors, name, onChange, selectedDate, subtitle, title, urgency }: Props) => {
    return (
        <div id={name} className={style.dateformContainer}>
            {title && (
                <h3>
                    {title} <span className={style.required}>*</span>
                </h3>
            )}
            {subtitle ? <h3>{subtitle}</h3> : ''}
            <div className={style.dateform}>
                {generateDates(urgency).map((dateOption) => {
                    const isChecked = selectedDate === dateOption.dateValue;
                    return (
                        <DateRadio
                            key={`${name}-${dateOption.dateValue}`}
                            name={name}
                            value={dateOption.dateValue}
                            disabled={!dateOption.available}
                            onChange={onChange}
                            isChecked={isChecked}
                            dateWeek={dateOption.dayName}
                            dateNum={dateOption.dayOfMonth}
                        />
                    );
                })}
            </div>
            {errors[name] ? <div className={style.errors}>{errors[name]}</div> : false}
        </div>
    );
};

export { DateForm as default, generateDates };
