import { JOBS_PUBLIC_ENDPOINT } from 'src/constants';
import { getMandatoryEnvVar } from 'src/utils/env';
import {
    getAuthenticatedUser,
    getTokenFromAuthenticatedUser,
} from '../../login/utils/authenticatedUser';
import { RescheduleJobRequestBody } from '../types/RescheduleJobRequest';

const API_BASE_URL = getMandatoryEnvVar('REACT_APP_API_BASE_URL');

export const postRescheduleJob = async (data: RescheduleJobRequestBody) => {
    const user = await getAuthenticatedUser();
    const token = getTokenFromAuthenticatedUser(user);

    try {
        const response = await fetch(
            `${API_BASE_URL}/${JOBS_PUBLIC_ENDPOINT}/v1/job/${data.skPreviousJobUid}/reschedule`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(data),
            }
        );

        return await response.json();
    } catch (err) {
        throw new Error(`Failed to reschedule job - ${err.message}`);
    }
};
