import Button from 'src/components/Button';
import { Shield } from 'src/images';
import style from './index.module.scss';
import { Option } from '../../../types/Question';

export interface Props {
    title?: string;
    extraCopy?: string;
    options: Option[];
    onSubmit: (value: string, nextQuestion?: string) => void;
}
const Authorised = ({ title = 'Authorised!', extraCopy = undefined, options, onSubmit }: Props) => {
    return (
        <div className={style.container}>
            <Shield className={style.shield} />
            <h2 className={style.title}>{title}</h2>
            {extraCopy && <div className={style.extraCopy}>{extraCopy}</div>}
            {options.map(({ value, nextQuestion }) => {
                return (
                    <Button
                        key={value}
                        onClick={() => {
                            onSubmit('', nextQuestion);
                        }}
                    >
                        <>{value}</>
                    </Button>
                );
            })}
        </div>
    );
};

export default Authorised;
