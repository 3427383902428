import differenceInHours from 'date-fns/differenceInHours';
import { DAY_IN_MILLIS } from '../constants';

export const getDate = (date: Date) => {
    const monthArray = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    return `${date.getDate()} ${monthArray[date.getMonth()]} ${date.getFullYear()}`;
};

export const getWeekDay = (date: Date) => {
    const weekDaysArray = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    return weekDaysArray[date.getDay()];
};

export function diffDateDays(date1, date2) {
    const diffTime = Math.abs(date2 - date1);
    return Math.ceil(diffTime / DAY_IN_MILLIS);
}

export const getDifferenceInHours = (date1: Date, date2: Date) =>
    Math.abs(differenceInHours(date1, date2, { roundingMethod: 'ceil' }));
