import { Spinner } from '@HometreeEngineering/component-library';
import { useState } from 'react';
import Button from 'src/components/Button';
import Dropdown from 'src/components/Dropdown';
import Input from 'src/components/Input';
import { useApplianceMakesQuery } from 'src/hooks/useApplianceMakesQuery';
import { ApplianceDetailsValue, FormState } from '../../../types/Form';
import { Option } from '../../../types/Question';
import style from './index.module.scss';

export interface Props {
    title: string;
    options: Option[];
    onSubmit: (value: ApplianceDetailsValue, nextQuestion?: string) => void;
    formState: Partial<FormState>;
    defaultValue?: ApplianceDetailsValue;
}

const ApplianceDetailsQuestions = ({
    title,
    options,
    onSubmit,
    formState,
    defaultValue,
}: Props) => {
    const [applianceDetails, setApplianceDetails] = useState<ApplianceDetailsValue>({
        applianceMake: defaultValue?.applianceMake,
        applianceModel: defaultValue?.applianceModel || '',
        applianceSerialNumber: defaultValue?.applianceSerialNumber || '',
    });

    const [applianceMakeOther, setapplianceMakeOther] = useState('');
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const {
        data: applianceMakes,
        isLoading: isLoadingApplianceMakes,
        isError: isApplianceMakesError,
    } = useApplianceMakesQuery();

    const isValidForm = () => {
        const newErrors: { [key: string]: string } = {};

        if (
            (applianceDetails.applianceMake && applianceDetails.applianceMake.length > 255) ||
            (applianceDetails.applianceModel && applianceDetails.applianceModel.length > 255)
        ) {
            newErrors.detailLength = 'Appliance make/model must be less than 256 characters.';
        }

        if (
            !applianceDetails.applianceMake ||
            !applianceDetails.applianceSerialNumber ||
            !applianceDetails.applianceModel ||
            (applianceDetails.applianceMake === 'other' && !applianceMakeOther)
        ) {
            newErrors.missingDetails = 'All appliance details must be filled in.';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const onChange = (key) => (e) => {
        const currentValue = e.target.value;
        const currentFieldName = e.target.name;

        if (currentValue) {
            setErrors((prev) => {
                const newErrors = { ...prev };
                delete newErrors[currentFieldName];
                return newErrors;
            });
        }

        setApplianceDetails({ ...applianceDetails, [key]: currentValue || currentValue });
    };

    const validationError = (
        <div className={style.validationError} role="alert">
            <ul>
                {Object.entries(errors).map(([key, error]) => (
                    <li key={`each-error-${key}`}>{error}</li>
                ))}
            </ul>
        </div>
    );

    if (isLoadingApplianceMakes) {
        return <Spinner />;
    }

    if (isApplianceMakesError) {
        return (
            <div className={style.validationError} role="alert">
                <p>Cannot load appliance makes</p>
            </div>
        );
    }

    return (
        <>
            <h3 className={style.title}>{title}</h3>
            <div className={style.inputAndError}>
                <div className={style.formControl}>
                    <label htmlFor="applianceMake">
                        <span>Make:</span>
                        <Dropdown
                            id="applianceMake"
                            name="applianceMake"
                            currentValue={applianceDetails.applianceMake?.toLowerCase() || ''}
                            onChange={onChange('applianceMake')}
                            options={applianceMakes
                                .map(({ name }) => {
                                    return { value: name.toLowerCase(), copy: name };
                                })
                                .concat([{ value: 'other', copy: 'Other (please specify)' }])}
                        />
                    </label>
                </div>

                {applianceDetails.applianceMake === 'other' && (
                    <div className={style.formControl}>
                        <label htmlFor="applianceMakeOther">
                            <span>Make (Other):</span>
                            <Input
                                inputType="text"
                                id="applianceMakeOther"
                                name="applianceMakeOther"
                                value={applianceMakeOther}
                                onChange={(e) => setapplianceMakeOther(e.target.value)}
                            />
                        </label>
                    </div>
                )}

                <div className={style.formControl}>
                    <label htmlFor="applianceModel">
                        <span>Model:</span>
                        <Input
                            inputType="text"
                            id="applianceModel"
                            name="applianceModel"
                            value={applianceDetails.applianceModel || ''}
                            onChange={onChange('applianceModel')}
                        />
                    </label>
                </div>

                <div className={style.formControl}>
                    <label htmlFor="applianceSerialNumber">
                        <span>Serial Numberr:</span>
                        <Input
                            inputType="text"
                            id="applianceSerialNumber"
                            name="applianceSerialNumber"
                            value={applianceDetails.applianceSerialNumber || ''}
                            onChange={onChange('applianceSerialNumber')}
                        />
                    </label>
                </div>

                {Object.keys(errors).length > 0 && validationError}
            </div>

            {options.map(({ value, nextQuestion, nextQuestionSelector }) => {
                return (
                    <Button
                        customStyle={style.button}
                        key={value}
                        type="button"
                        onClick={() => {
                            const hasErrors = !isValidForm();

                            if (!hasErrors) {
                                if (applianceDetails.applianceMake === 'other') {
                                    applianceDetails.applianceMake = applianceMakeOther;
                                }
                                onSubmit(
                                    applianceDetails,
                                    nextQuestionSelector
                                        ? nextQuestionSelector(applianceDetails, formState)
                                        : nextQuestion
                                );
                            }
                        }}
                    >
                        {value}
                    </Button>
                );
            })}
        </>
    );
};

export default ApplianceDetailsQuestions;
