import { parseISO } from 'date-fns';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import ErrorBar from 'src/components/ErrorBar';
import Layout from 'src/components/Layout';
import Loader from 'src/components/Loader';
import { JobDetails, useJobDetails } from 'src/features/jobDetails';
import { captureSentryError } from 'src/features/login/utils/captureSentryError';

import { useGetAccount } from 'src/features/account/engineer/profile/hooks/useGetAccount';
import { CloseJobFormData, closeJob } from '../api/closeJob';
import { postFollowOn } from '../api/postFollowOn';
import { questions } from '../domain';
import { FormState } from '../types/Form';

import Form from './Form';
import style from './index.module.scss';

const CloseJob = () => {
    const { jobId } = useParams();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submittingError, setSubmittingError] = useState<string | null>(null);
    const [enableRefetch, setEnableRefetch] = useState(true);
    const {
        isError: isJobDetailsError,
        isLoading,
        data: jobDetailsData,
        error: jobDetailsError,
    } = useJobDetails(jobId, { enabled: enableRefetch });
    const [error, setError] = useState('');

    const { data: accountData } = useGetAccount();

    if (!jobId) {
        // eslint-disable-next-line no-console
        console.error('Error retrieving job details: No jobId in url');
        return <ErrorBar message="Error retrieving job details: No jobId in url" />;
    }

    const clearJobLocalStorage = () => {
        localStorage.removeItem(jobId);
        localStorage.removeItem(`${jobId}+currentQuestion`);
    };

    const onSubmit = async (finalState: Partial<FormState>) => {
        // map Partial<FormState> to CloseJobFormData
        const data: CloseJobFormData = {
            jobId,
            portalCloseJobSource: true,
            previousEngineerVisits: jobDetailsData?.previous_engineer_visits,
            ...finalState,
        };
        try {
            setIsSubmitting(true);
            setEnableRefetch(false);
            await closeJob({ ...data });
            clearJobLocalStorage();

            if (finalState.followOnDetails && jobDetailsData) {
                await postFollowOn({
                    skPreviousJobUid: jobDetailsData.sk_job_id,
                    skEngineerUid: jobDetailsData.sk_resource_id,
                    additionalHoursNeeded: finalState.estimatedHoursNeeded || 0,
                    partsDescription: finalState.partsDescription || '',
                    partsNetAmount: finalState.estimatedPartsCost || 0,
                    visitDateTime: parseISO(
                        `${finalState.followOnDetails.visitDate} ${finalState.followOnDetails.timeSlot}`
                    ),
                    skipClaimStatusCheck: true,
                    partsRequested: finalState.partsRequested,
                });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            setError(`Could not close job ${jobId}: ${e.message}`);
            setSubmittingError(e.message);
            await captureSentryError(e, {
                message: 'There was an error closing this job',
                jobId: jobDetailsData?.sk_job_id || 'Unable to retrieve job id',
                engineerUid: jobDetailsData?.sk_resource_id || 'Unable to retrieve resource id',
            });
        }

        setIsSubmitting(false);
    };

    if (isLoading) {
        return <Loader />;
    }

    if (!jobDetailsData) {
        // eslint-disable-next-line no-console
        console.error(`Error retrieving job details for jobId: ${jobId}`);
        return <ErrorBar message="Error retrieving job details" />;
    }

    if (isJobDetailsError) {
        // eslint-disable-next-line no-console
        console.error('Error retrieving job details', jobDetailsError);
        return (
            <div>
                Error retrieving job details for job UID {jobId}: {jobDetailsError.message}
            </div>
        );
    }

    if (error) {
        return <ErrorBar message={error} />;
    }

    if (jobDetailsData.sk_job_status !== 'In Progress') {
        return (
            <ErrorBar message="This job is not in status 'In Progress' and cannot be closed now." />
        );
    }

    if (submittingError) {
        // eslint-disable-next-line no-console
        console.error('Error retrieving job details', jobDetailsError);
        return (
            <div>
                Error closing job for job UID {jobId}: {submittingError}
            </div>
        );
    }

    return (
        <Layout>
            <div className={style.container}>
                <div className={style.jobDetails}>
                    <JobDetails details={jobDetailsData} />
                </div>
                <Form
                    questions={questions(jobDetailsData, accountData)}
                    initialQuestionKey="issueResolved"
                    endQuestionKeys={[
                        'complete',
                        'investigationRequired',
                        'nextVisitScheduled',
                        'rejectionNotice',
                    ]}
                    formSubmit={onSubmit}
                    isFormSubmitting={isSubmitting}
                    jobDetails={jobDetailsData}
                />
            </div>
        </Layout>
    );
};

export default CloseJob;
