import { JSONSchemaType } from 'ajv';
import { PandoraErrorResponse, pandoraErrorResponseSchema } from 'src/types/pandora';

export type InvoicesResponse = InvoicesSuccessResponse | PandoraErrorResponse;

export interface InvoicesSuccessResponse {
    invoices: Invoice[];
}

export interface Invoice {
    billingInvoiceDate: string;
    invoiceNumber: string;
    totalNetPence: number;
    s3Key?: string | null;
}

export const invoiceSchema: JSONSchemaType<Invoice> = {
    type: 'object',
    properties: {
        billingInvoiceDate: { type: 'string' },
        invoiceNumber: { type: 'string' },
        totalNetPence: { type: 'number' },
        s3Key: { type: 'string', nullable: true },
    },
    required: ['billingInvoiceDate', 'invoiceNumber', 'totalNetPence'],
};

export const getInvoicesSuccessResponseSchema: JSONSchemaType<InvoicesSuccessResponse> = {
    type: 'object',
    properties: {
        invoices: {
            type: 'array',
            items: invoiceSchema,
        },
    },
    required: ['invoices'],
};

export const getInvoicesResponseSchema: JSONSchemaType<InvoicesResponse> = {
    type: 'object',
    anyOf: [getInvoicesSuccessResponseSchema, pandoraErrorResponseSchema],
};
