import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Button from 'src/components/Button';
import Checkbox from 'src/components/checkbox';
import { newAnalyticsEvent } from 'src/utils/analytics';
import toastConfig from 'src/utils/toastConfig';
import { Account } from '../../api/getAccount.types';
import { useUpdateAccount } from '../../hooks/updateAccount';
import styles from './index.module.scss';

const MarketingPreferences = ({ account }: { account: Account }) => {
    const [checkboxChecked, setCheckboxChecked] = useState({
        email: !!account.marketing_email,
        text: !!account.marketing_sms,
        phone: !!account.marketing_phone,
    });
    const marketingTypes = ['email', 'phone', 'text'];

    const { mutate, isSuccess, isError } = useUpdateAccount();

    useEffect(() => {
        if (isSuccess) {
            toast.success(`Successfully updated Marketing Preferences`, toastConfig);
        } else if (isError) {
            toast.error(
                `We were unable to save your marketing preferences. Please try again later or contact us.`,
                toastConfig
            );
        }
    }, [isSuccess, isError]);

    const handleClick = () => {
        const marketingData = {
            marketing_email: !!checkboxChecked.email,
            marketing_sms: !!checkboxChecked.text,
            marketing_phone: !!checkboxChecked.phone,
        };
        mutate(marketingData);

        const selectedValues = marketingTypes.filter((type) => checkboxChecked[type]);
        newAnalyticsEvent({
            category: 'Marketing_Consent',
            action: selectedValues.length ? 'account-consent-given' : 'account-consent-removed',
            label: `${account.id}`,
            value: selectedValues.toString(),
        });
    };

    return (
        <div className={styles.marketingContainer}>
            <h4>Keeping in touch</h4>
            <div className={styles.description}>
                <p>
                    Occasionally, we&apos;d like to keep you informed about the latest offers and
                    products from Hometree Group, along with updates about the Hometree referral
                    program.
                </p>
                <p>
                    Please let us know how you would like us to keep in touch below. You can update
                    these preferences here in the customer portal at any time.
                </p>
            </div>
            <div className={styles.marketingOptions}>
                <div className={styles.marketingCheckboxContainer}>
                    {marketingTypes.map((type) => (
                        <div key={type} className={styles.checkboxes}>
                            <Checkbox
                                id={`enable-${type}`}
                                onChange={(e) => {
                                    setCheckboxChecked({
                                        ...checkboxChecked,
                                        [type]: e.target.checked,
                                    });
                                }}
                                name={`enable-${type}`}
                                checked={checkboxChecked[type]}
                            >
                                <label htmlFor={`enable-${type}`}>
                                    {type.charAt(0).toUpperCase()}
                                    {type.slice(1)}
                                </label>
                            </Checkbox>
                        </div>
                    ))}
                </div>
                <Button variant="primary" onClick={handleClick}>
                    Confirm
                </Button>
            </div>
        </div>
    );
};

export default MarketingPreferences;
